export const GenerativeAICourseData = {
  dummy: false,
  bannerRecord: {
    courseID: 73,
    name: "Generative AI course",
    bannerType: null,
    robotImage: "image/course/gen-ai-tools-banner.png",
    courseDetailsImage: "image/course/gen-ai-tools-banner.png",
    introURL: "",
    bannerFileName: "image/course/gen-ai-course-banner-web.jpg",
    hoursCount: "72",
    classCount: "24"
  },
  toolsRecord: [
    {
      toolID: 3,
      Tool: {
        toolID: 3,
        title: "ChatGPT",
        smallImage: "image/toolsImage/chatgpt-1734957170701.png",
        link: null,
        largeImage: "image/toolsImage/chatgpt-1734957170701.png"
      }
    },
    {
      toolID: 3,
      Tool: {
        toolID: 3,
        title: "Gemini",
        smallImage: "/image/toolsImage/gemini-1734957148379.png",
        link: null,
        largeImage: "/image/toolsImage/gemini-1734957148379.png"
      }
    },
    {
      toolID: 11,
      Tool: {
        toolID: 11,
        title: "OpenAI",
        smallImage: "image/toolsImage/openai-1734957133254.png",
        link: null,
        largeImage: "image/toolsImage/openai-1734957133254.png"
      }
    },
    {
      toolID: 15,
      Tool: {
        toolID: 15,
        title: "Cohere",
        smallImage: "image/toolsImage/cohere-1734957111480.png",
        link: null,
        largeImage: "image/toolsImage/cohere-1734957111480.png"
      }
    },
    {
      toolID: 18,
      Tool: {
        toolID: 18,
        title: "Microsoft Azure",
        smallImage: "image/toolsImage/azure-1734957095704.png",
        link: null,
        largeImage: "image/toolsImage/azure-1734957095704.png"
      }
    },
    {
      toolID: 7,
      Tool: {
        toolID: 7,
        title: "Amazon Web Services",
        smallImage:
          "image/toolsImage/Amazon_Web_Services-Logo-1734937217929.png",
        link: null,
        largeImage:
          "image/toolsImage/Amazon_Web_Services-Logo-1734937217929.png"
      }
    },
    {
      toolID: 1,
      Tool: {
        toolID: 1,
        title: "Microsoft Copilot",
        smallImage: "image/toolsImage/mscopilot-1734957066901.png",
        link: null,
        largeImage: "image/toolsImage/mscopilot-1734957066901.png"
      }
    },
    {
      toolID: 17,
      Tool: {
        toolID: 17,
        title: "GitHub Copilot",
        smallImage: "image/toolsImage/github-1734957055903.png",
        link: null,
        largeImage: "image/toolsImage/github-1734957055903.png"
      }
    },
    {
      toolID: 39,
      Tool: {
        toolID: 39,
        title: "Jasper",
        smallImage: "image/toolsImage/jasper-1734957044305.png",
        link: null,
        largeImage: "image/toolsImage/jasper-1734957044305.png"
      }
    },
    {
      toolID: 39,
      Tool: {
        toolID: 39,
        title: "Synthesia",
        smallImage: "image/toolsImage/synthesia-1734957033447.png",
        link: null,
        largeImage: "image/toolsImage/synthesia-1734957033447.png"
      }
    },
    {
      toolID: 39,
      Tool: {
        toolID: 39,
        title: "Streamlit",
        smallImage: "image/toolsImage/streamlit-1734956985096.png",
        link: null,
        largeImage: "image/toolsImage/streamlit-1734956985096.png"
      }
    },
    {
      toolID: 39,
      Tool: {
        toolID: 39,
        title: "DataRobot",
        smallImage: "image/toolsImage/datarobot-1734956974003.png",
        link: null,
        largeImage: "image/toolsImage/datarobot-1734956974003.png"
      }
    }
  ],
  sidebarTitles: {
    courseSidebarID: 11,
    courseID: 73,
    overview: "Course Overview",
    curriculum: "Gen AI Course Syllabus",
    whoShouldDo: "Learning Benefits with AnalytixLabs",
    jobRoles: "Job Roles and Skills",
    keySkills: "",
    capstoneProject: "AI engineering Capstone Projects",
    deliveryFormat:
      "Artificial Intelligence online course - Learning Methodologies",
    certification: "Certification Details",
    feeDetails: "Course Fees and Admission Process",
    careerSupport: "Career Support & Guidance",
    successStories: "Career stories by AnalytixLabs",
    demoAndBrochure: "Demo & Brochure",
    upcomingBatches: "Upcoming Batches",
    FAQAndBlog: "FAQs & Blogs",
    createdAt: "2021-07-16T11:58:29.000Z",
    updatedAt: "2022-05-20T05:16:49.000Z",
    careerPath: "Personalized Career Pathways",
    learningBenefits: "Learning Benefits"
  },
  upcomingBatchResult: [
    {
      courseBatchID: 925,
      LMSBatchID: null,
      courseID: 73,
      instructorID: null,
      learningModeID: 2,
      name: "Python for Data Science- Gurgaon '24",
      description: null,
      seat: 4,
      cityID: 4,
      startDate: "2024-05-05T00:00:00.000Z",
      batchTime: "10:00 AM - 1:00 PM",
      isInteractive: true,
      showOnWebsite: 1,
      sortOrder: 0,
      isActive: true,
      isDeleted: false,
      createdBy: 345,
      updatedBy: null,
      modifiedDate: "2024-04-20T09:25:03.000Z",
      modifiedUser: 345,
      createdAt: "2023-03-15T09:12:44.000Z",
      updatedAt: "2024-04-20T09:25:03.000Z",
      City: {
        cityID: 4,
        name: "Gurgaon"
      }
    },
    {
      courseBatchID: 924,
      LMSBatchID: null,
      courseID: 73,
      instructorID: null,
      learningModeID: 2,
      name: "Python for Data Science- Bangalore '24",
      description: null,
      seat: 15,
      cityID: 1,
      startDate: "2024-05-19T00:00:00.000Z",
      batchTime: "2:00 PM - 5:00 PM",
      isInteractive: true,
      showOnWebsite: 1,
      sortOrder: 0,
      isActive: true,
      isDeleted: false,
      createdBy: 345,
      updatedBy: null,
      modifiedDate: "2024-04-16T06:52:48.000Z",
      modifiedUser: 345,
      createdAt: "2023-03-15T09:12:06.000Z",
      updatedAt: "2024-04-16T06:52:48.000Z",
      City: {
        cityID: 1,
        name: "Bangalore"
      }
    }
  ],
  courseIncludedRecord: [
    {
      courseID: 63,
      name: "Data Science using Python",
      slug: "data-science-using-python",
      hoursCount: "570",
      classCount: "43",
      courseType: 1,
      bannerFileName:
        "image/course/iitg-web-banner-1713875251999-1714199901805.webp",
      robotImage: "image/course/thumbnail-1616501891.svg",
      courseDetailsImage:
        "image/course/iitg-web-icon-logo-1714134463298-1714199689299.png",
      CourseTags: [
        {
          tagID: 4,
          Tag: {
            name: "DS360"
          }
        },
        {
          tagID: 1,
          Tag: {
            name: "BA360"
          }
        }
      ],
      learningMode: [
        {
          learningModeID: 1,
          name: "Classroom & Bootcamps",
          image: "image/learningMode/img-1.png",
          description:
            "Experiential learning with in-person mentorship!\r\n\r\n",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        },
        {
          learningModeID: 2,
          name: "Classroom with ILT Blended",
          image: "image/learningMode/img-2.png",
          description:
            "Learn from the best with unrestricted support, wherever you are!\r\n\r\n",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        },
        {
          learningModeID: 3,
          name: "Blended eLearning",
          image: "image/learningMode/img-3.png",
          description: "Learn at your pace with on-demand lectures & Support!",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        }
      ],
      amountINR: 35000,
      discountType: 1,
      discountAmount: 0,
      upcomingBatch: {
        courseID: 63,
        learningModeID: 2,
        startDate: "2024-05-05T00:00:00.000Z"
      }
    },
    {
      courseID: 65,
      name: "Machine Learning using Python",
      slug: "machine-learning-course-certification-training",
      hoursCount: "570",
      classCount: "43",
      courseType: 2,
      bannerFileName:
        "image/course/iitg-web-banner-1713875251999-1714199901805-1714562347744.webp",
      robotImage: "image/course/website-banners-creatives-alabs-07 copy.svg",
      courseDetailsImage:
        "image/course/iitg-web-icon-logo-1714134463298-1714199689299-1714562347747.png",
      CourseTags: [
        {
          tagID: 2,
          Tag: {
            name: "ABDS"
          }
        },
        {
          tagID: 4,
          Tag: {
            name: "DS360"
          }
        },
        {
          tagID: 3,
          Tag: {
            name: "AIML"
          }
        }
      ],
      learningMode: [
        {
          learningModeID: 1,
          name: "Classroom & Bootcamps",
          image: "image/learningMode/img-1.png",
          description:
            "Experiential learning with in-person mentorship!\r\n\r\n",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        },
        {
          learningModeID: 2,
          name: "Classroom with ILT Blended",
          image: "image/learningMode/img-2.png",
          description:
            "Learn from the best with unrestricted support, wherever you are!\r\n\r\n",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        },
        {
          learningModeID: 3,
          name: "Blended eLearning",
          image: "image/learningMode/img-3.png",
          description: "Learn at your pace with on-demand lectures & Support!",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        }
      ],
      amountINR: 20000,
      discountType: 1,
      discountAmount: 0,
      upcomingBatch: {}
    },
    {
      courseID: 68,
      name: "Certified Big Data Engineer",
      slug: "big-data-analytics-hadoop-spark-training-course-online",
      hoursCount: "210",
      classCount: "30",
      courseType: 1,
      bannerFileName:
        "image/course/machine-learning-course-certification-training1-1616494453966.jpg",
      robotImage: "image/course/Thumbnail-1616494453967.svg",
      courseDetailsImage:
        "image/course/certified-big-data-engineer-1657623430377.png",
      CourseTags: [
        {
          tagID: 2,
          Tag: {
            name: "ABDS"
          }
        },
        {
          tagID: 5,
          Tag: {
            name: "AIE"
          }
        }
      ],
      learningMode: [
        {
          learningModeID: 1,
          name: "Classroom & Bootcamps",
          image: "image/learningMode/img-1.png",
          description:
            "Experiential learning with in-person mentorship!\r\n\r\n",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        },
        {
          learningModeID: 2,
          name: "Classroom with ILT Blended",
          image: "image/learningMode/img-2.png",
          description:
            "Learn from the best with unrestricted support, wherever you are!\r\n\r\n",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        },
        {
          learningModeID: 3,
          name: "Blended eLearning",
          image: "image/learningMode/img-3.png",
          description: "Learn at your pace with on-demand lectures & Support!",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        }
      ],
      amountINR: 25000,
      discountType: 1,
      discountAmount: 0,
      upcomingBatch: {}
    }
  ],
  UserReviewsRecord: [],
  courseCurriculumRecord: {
    curriculumDescription: `<p class="counrse-contn-1">The learning blocks are arranged agilely so that learning is smooth and you have enough time to learn, implement, and experiment through various hands-on projects. A bird’s eye view of the course curriculum is as follows: </p>`,
    CourseCurriculums: [
      {
        courseID: 73,
        courseCurriculumID: 1228,
        name: "Orientation Session",
        sortOrder: 0,
        CourseCurriculumDetails: [
          {
            courseCurriculumDetailsID: 5518,
            courseCurriculumID: 1228,
            title: "What is Artificial Intelligence (AI)?",
            description: `
              <ul class="cours-ovr-ul ct-cours-ovr-ul">
               <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left  mb-2">
                    Introduction to Course Objective - Logistics - Structure of the course
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Demystifying AI and its evolution
                  </p>
                </li>
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Data Science vs. Machine Learning vs. Artificial Intelligence
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    How AI is transforming businesses
                  </p>
                </li>
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Trends, Tools and Applications
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Use cases related to different functions across industries (Retail/ e-commerce, BFSI, Pharma, Manufacturing, Auto, etc.)
                  </p>
                </li>
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Key Areas of AI (Computer Vision, Language Models, Reinforcement Learning, etc.)
                  </p>
                </li>
              </ul>
              `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T05:55:57.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          },
          {
            courseCurriculumDetailsID: 5518,
            courseCurriculumID: 1228,
            title: "What is Generative AI?",
            description: `
                  <ul class="cours-ovr-ul ct-cours-ovr-ul">
                    <li class="drc-blcr m-0" style="list-style-type: none;">
                      <p class="counrse-contn-1 ct-sm-left mb-2">
                        Understand how Generative AI produces new data, text, or images based on training datasets.
                      </p>
                    </li>
                  </ul>
              `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T05:55:57.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          }
        ]
      },
      {
        courseID: 73,
        courseCurriculumID: 1240,
        name: "Generative AI for Everyone (Building Blocks) - Part I",
        sortOrder: 2,
        CourseCurriculumDetails: [
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Introduction to AI & Generative AI",
            description: `
                <ul class="cours-ovr-ul ct-cours-ovr-ul">
                  <li class="drc-blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Introduction to LLMs and evolution
                    </p>
                  </li>
                  <li class="blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Basics of LLMs and Key Terminologies
                    </p>
                  </li>
                  <li class="drc-blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Working of LLMs
                    </p>
                  </li>
                  <li class="blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Applications of LLMs
                    </p>
                  </li>
                  <li class="drc-blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Introduction to Prompt Engineering
                    </p>
                  </li>
                </ul>
            `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          },
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Introduction to ChatGPT interface",
            description: `
              <ul class="cours-ovr-ul ct-cours-ovr-ul">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Creating an account
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Overview of the website and the services
                  </p>
                </li>
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Versions - 3.5 v/s 4 v/s turbo
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Tokens
                  </p>
                </li>
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    OpenAI playground
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Pricing etc.
                  </p>
                </li>
              </ul>

            `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          },
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Introduction to Prompting Techniques",
            description: `
                <ul class="cours-ovr-ul ct-cours-ovr-ul">
                  <li class="drc-blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Specifying output formats (table, bullets, comparison, code format, markdown format)
                    </p>
                  </li>
                  <li class="blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Assigning a role/character (setting comprehension level, tone)
                    </p>
                  </li>
                  <li class="drc-blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Reverse prompting
                    </p>
                  </li>
                  <li class="blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Chain of thought
                    </p>
                  </li>
                  <li class="drc-blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Ask before you answer/ Tell me what I don't know
                    </p>
                  </li>
                </ul>

            `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          },
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "ChatGPT Use Cases",
            description: `
                <ul class="cours-ovr-ul ct-cours-ovr-ul">
                  <li class="drc-blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Learning and Development
                    </p>
                  </li>
                  <li class="blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Making presentations using Google Slides
                    </p>
                  </li>
                  <li class="drc-blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Making Presentations using PowerPoint, Word, and ChatGPT
                    </p>
                  </li>
                  <li class="blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Making Presentations using online tools like tome.app, gamma.app
                    </p>
                  </li>
                  <li class="drc-blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Creating Webpages (generating HTML code using ChatGPT)
                    </p>
                  </li>
                  <li class="blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Usage of ChatGPT in Google Sheets
                    </p>
                  </li>
                  <li class="drc-blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Automating tasks in Google Sheets
                    </p>
                  </li>
                  <li class="blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Integrating ChatGPT in MS Word and Google Docs
                    </p>
                  </li>
                  <li class="drc-blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Using ChatGPT for writing better code
                    </p>
                  </li>
                </ul>
            `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          }
        ]
      },
      {
        courseID: 73,
        courseCurriculumID: 1240,
        name: "GenAI for Business Applications - Part 01",
        sortOrder: 2,
        CourseCurriculumDetails: [
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title:
              "Module 1: Unveiling AI & Generative AI – Trends, Tools and Applications",
            description: `

            <p class='mt-4 mb-1 ct-p-text-gen-ai'><b>What is Generative AI?</b> </p>
            <p class="counrse-contn-1 ct-sm-left">Understand how <b>Generative AI</b> produces new data, text, or images based on training datasets.</p>
            
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Applications of Generative AI in Business:</b> </h6>

              <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Healthcare:</b> Personalized medicine, AI-based drug discovery (Examples: Disease diagnosis, drug discovery, and personalized medicine.)
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Banking & Finance:</b> Automating financial reports and customer service with chatbots. (Examples: Fraud detection, risk assessment, and algorithmic trading.)
                  </p>
                </li>
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Retail & Ecommerce:</b> Inventory management, dynamic pricing, and product recommendations (Examples: Visual search, AI-assisted shopping, and demand forecasting.)
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Manufacturing and Industry 4.0:</b> Predictive maintenance, defect detection, and process automation (Examples: Quality control systems, robotics, and smart factories.)
                  </p>
                </li>
              </ul>

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Generative AI Tools for Productivity:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  <b>Jasper AI:</b> Content creation
                </p>
              </li>
              <li class="blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  <b>Synthesia AI:</b> Video generation
                </p>
              </li>
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  <b>Grammarly:</b> Text enhancement
                </p>
              </li>
            </ul>

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Generative AI Tools for Productivity:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  Moderna’s use of Generative AI for COVID-19 vaccine research.
                </p>
              </li>
              <li class="blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  The New York Times automates article creation with AI.
                </p>
              </li>
            </ul>

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Hands-On Exercise:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  Use Jasper AI to generate a blog post or marketing content.
                </p>
              </li>
              <li class="blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  Create a corporate video presentation with Synthesia AI.
                </p>
              </li>
            </ul>

            `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          },
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Module 2: Machine Learning for Business Problem Solving",
            description: `

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>What is Machine Learning (ML)?</b> </h6>
            <p class="counrse-contn-1 ct-sm-left">Understand ML, its approaches (supervised, unsupervised, reinforcement), and how it differs from traditional AI.</p>
            
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Applications of ML in Business:</b> </h6>

              <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2 ">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Retail:</b> Personalized customer recommendations based on purchase history.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Manufacturing:</b> Predictive maintenance to reduce machine downtime.
                  </p>
                </li>
              </ul>

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>ML Tools for Business:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  <b>DataRobot:</b> No-code ML tool for creating ML models.
                </p>
              </li>
              <li class="blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  <b>Teachable Machines by Google:</b> Simple ML model training.
                </p>
              </li>
            </ul>
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Real-World Case Studies:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  Target uses ML to predict consumer behavior and optimize product recommendations.
                </p>
              </li>
              <li class="blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  Siemens uses ML to monitor factory machinery and prevent failures.
                </p>
              </li>
            </ul>
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Hands-On Exercise:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  Use DataRobot to create an ML model for customer segmentation.
                </p>
              </li>
              <li class="blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  Use Teachable Machines to build a basic image recognition model.
                </p>
              </li>
            </ul>
            `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          },
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Module 3: Ethical and Responsible AI",
            description: `

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>What is Ethical AI?</b> </h6>
            <p class="counrse-contn-1 ct-sm-left">Explore the challenges of <b>AI ethics</b>, including bias, fairness, and transparency in AI systems.</p>
            
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Applications of Responsible AI:</b> </h6>
              <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0 m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>HR:</b> Implementing AI-based hiring tools while avoiding bias.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Healthcare:</b> Ensuring fairness in AI-driven medical diagnostics.
                  </p>
                </li>
              </ul>

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Ethical AI Tools:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  AI Fairness 360: IBM’s toolkit to detect and mitigate bias in AI models.
                </p>
              </li>
              <li class="blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  Watson OpenScale: Ensuring transparency in AI decision-making.
                </p>
              </li>
            </ul>

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Real-World Case Studies:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  Amazon’s AI hiring tool and its bias against female candidates.
                </p>
              </li>
              <li class="blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                   IBM Watson’s struggle with cancer diagnosis due to biased data sets.
                </p>
              </li>
            </ul>

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Hands-On Exercise:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  Use AI Fairness 360 to assess an AI model for bias.
                </p>
              </li>
              <li class="blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  Explore Watson OpenScale to understand how to track AI decision transparency.
                </p>
              </li>
            </ul>
            `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          },
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title:
              "Module 4: Mastering Prompt Engineering for Business Applications",
            description: `

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>What is Prompt Engineering?</b> </h6>
            <p class="counrse-contn-1 ct-sm-left">Learn how to structure prompts to maximize the quality and relevance of AI responses.</p>
            
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Applications of Prompt Engineering:</b> </h6>

              <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Legal Services:</b> Automating document review and summarization.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Finance:</b> Using AI-driven chatbots to provide financial advice.
                  </p>
                </li>
              </ul>

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Prompt Engineering Tools:</b> </h6>





            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  <b>OpenAI Playground:</b> Experiment with GPT models.
                </p>
              </li>
              <li class="blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  <b>Cohere AI:</b> NLP model that allows customized text generation.
                </p>
              </li>
            </ul>
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Real-World Case Studies:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  <b>Luminance AI:</b> Legal firms using AI to review thousands of documents quickly.
                </p>
              </li>
              <li class="blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>JPMorgan’s AI chatbot:</b> Providing instant, accurate financial advice.
                </p>
              </li>
            </ul>
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Hands-On Exercise:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  Design a set of prompts to automate customer service responses using <b>OpenAI Playground.</b>
                </p>
              </li>
              <li class="blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  Use <b>Cohere AI</b> to summarize legal documents for a mock case.
                </p>
              </li>
            </ul>
            `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          },
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title:
              "Module 5: Driving AI Success: Strategic Adoption & Team Empowerment",
            description: `

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>AI Project Lifecycle: Understand the stages of AI project management.</b> </h6>
              <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    AI Capability Maturity Model
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    How to design the optimal AI organization, and the roles and skills needed in AI teams.
                  </p>
                </li>
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    How to adapt agile methodologies for AI projects, the principles of responsible AI, and ways to govern AI projects to drive responsible and ethical outcomes for your business 
                  </p>
                </li>
              </ul>
            
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>How to Manage AI Projects?</b> </h6>
            <p class="counrse-contn-1 ct-sm-left">Learn the critical stages of AI projects: ideation, development, deployment, and scaling.</p>
            
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Applications of AI Project Management:</b> </h6>
              <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Retail:</b> Managing AI-driven inventory optimization projects.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Energy:</b> Leading AI projects for efficient energy exploration and management.
                  </p>
                </li>
              </ul>

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>AI Management Tools:</b> </h6>





            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  <b>Asana:</b> Project management tool with AI integrations.
                </p>
              </li>
              <li class="blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  <b>Trello:</b> Organize and track AI project deliverables.
                </p>
              </li>
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  <b>Scaling AI Initiatives:</b> Learn strategies for scaling and maintaining AI projects.
                </p>
              </li>
              <li class="blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  <b>Evaluating AI Impact:</b> Measure the ROI and impact of AI projects.
                </p>
              </li>
            </ul>
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Real-World Case Studies:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  Walmart’s AI team leading efforts in supply chain optimization.

                </p>
              </li>
              <li class="blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                    BP’s AI projects for improving energy exploration efficiency.
                </p>
              </li>
            </ul>
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Hands-On Exercise:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  Build an AI project roadmap using Asana or Trello, defining milestones and team roles.
                </p>
              </li>

              </ul>
            `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          },
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Module 6: Harnessing Cloud AI Solutions",
            description: `

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>What are Cloud AI Solutions?</b> </h6>
            <p class="counrse-contn-1 ct-sm-left">Explore how <b>cloud platforms</b> like AWS, Azure, and Google Cloud provide the infrastructure to build and scale AI solutions.</p>
            
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Applications of Cloud AI:</b> </h6>
            
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                        <b>Healthcare:</b> Cloud AI solutions for drug discovery and patient data analysis.
                    </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                        <b>Retail:</b> Personalized marketing and customer journey optimization.
                    </p>
                </li>
            </ul>

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Cloud AI Tools:</b> </h6>

            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                        <b>AWS SageMaker:</b> Train and deploy ML models in the cloud.
                    </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                        <b>Azure AI:</b> Ready-to-use AI services for NLP, image recognition, and more.
                    </p>
                </li>
            </ul>

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Real-World Case Studies:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                        Moderna’s use of AWS AI for accelerated vaccine development.
                    </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                        Coca-Cola using Azure AI for personalized marketing campaigns.
                    </p>
                </li>
            </ul>

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Hands-On Exercise:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                        Explore <b>AWS SageMaker</b> to deploy a machine learning model.
                    </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                        Use <b>Azure AI services</b> to build a simple chatbot for customer service.
                    </p>
                </li>
            </ul>
            `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          },
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title:
              "Module 7: Leveraging Open-Source AI for Enterprise Solutions",
            description: `

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>What are Open-Source AI Solutions?</b> </h6>
            <p class="counrse-contn-1 ct-sm-left">Understand the advantages of using <b>open-source AI </b>tools for flexibility and cost-effectiveness.</p>

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Applications of Open-Source AI:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Education:</b> Personalized learning and tutoring systems.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Automotive:</b> Enhancing autonomous vehicle capabilities.
                  </p>
                </li>
            </ul>
            
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Open-Source AI Tools:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Hugging Face:</b> Pre-trained NLP models.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>TensorFlow:</b> Open-source platform for ML and AI development.
                  </p>
                </li>
            </ul>

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Real-World Case Studies:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Tesla’s use of open-source AI for autonomous driving innovations.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    GPT-based adaptive learning platforms used by online education providers.
                  </p>
                </li>
            </ul>

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Hands-On Exercise:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Use <b>Hugging Face</b> to explore pre-trained NLP models.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Experiment with <b>TensorFlow</b> for developing basic AI models.
                  </p>
                </li>
            </ul>


            `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          },
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title:
              "Module 8: Building Chatbots and Retrieval-Augmented Generation (RAG)",
            description: `

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>What is RAG and Chatbots?</b> </h6>
            <p class="counrse-contn-1 ct-sm-left">Learn how <b>Retrieval-Augmented Generation (RAG)</b> enhances chatbots by fetching and integrating external knowledge.</p>
            
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Applications of Chatbots and RAG:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Banking:</b> Automated customer support for account inquiries.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>IT Operations:</b> Virtual agents handling service tickets and queries.
                  </p>
                </li>
            </ul>
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Chatbot Tools:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Dialogflow:</b> Build AI-powered chatbots with Google’s Dialogflow.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Rasa:</b> Open-source chatbot framework.
                  </p>
                </li>
            </ul>
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Real-World Case Studies:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Bank of America’s Erica chatbot for handling customer service requests.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    ServiceNow’s AI virtual agent for improving IT service management.
                  </p>
                </li>
            </ul>
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Hands-On Exercise:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Build a simple chatbot for industry-specific queries using <b>Dialogflow or Rasa.</b>
                  </p>
                </li>
            </ul>
            `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          },
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Module 9: AI in IT Operations (AIOps)",
            description: `

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>What is AIOps?</b> </h6>
            <p class="counrse-contn-1 ct-sm-left">Explore how AI is transforming <b>IT operations</b> through <b>predictive analytics, root cause analysis,</b> and automated monitoring.</p>
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Applications of AIOps:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Banking:</b> Monitoring IT infrastructure for preventing downtime.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>IT Services:</b> Automating incident resolution for faster service.
                  </p>
                </li>
            </ul>
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>AIOps Tools:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Moogsoft:</b> AI for IT operations, including incident prediction.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Dynatrace:</b> Full-stack monitoring with AI-driven root cause analysis.
                  </p>
                </li>
            </ul>
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Real-World Case Studies:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Splunk’s AI tools for automating IT operations in large enterprises.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    HSBC’s use of AIOps to ensure minimal system disruptions.
                  </p>
                </li>
            </ul>
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Hands-On Exercise:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Set up an IT monitoring system using <b>Dynatrace</b> to predict and resolve issues.
                  </p>
                </li>
            </ul>
            `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          },
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Module 10: AI in Business Operations",
            description: `

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>AI for Optimizing Business Operations:</b> </h6>
            <p class="counrse-contn-1 ct-sm-left">Learn how AI is used to <b>automate decision-making, optimize processes,</b> and <b>drive efficiency</b> across different business functions.</p>
            
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Applications of AI in Business Operations:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Supply Chain:</b> Route optimization and demand forecasting.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>HR:</b> AI for talent acquisition and employee engagement.
                  </p>
                </li>
            </ul>
            
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Business Operations Tools:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>UiPath:</b> AI-driven process automation platform.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Workday:</b> HR management system using AI for recruitment and employee management.
                  </p>
                </li>
            </ul>
            
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Real-World Case Studies:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Amazon’s AI-powered logistics systems for optimizing supply chain operations.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Unilever’s AI-powered hiring platform that screens and recruits candidates at scale.
                  </p>
                </li>
            </ul>
            
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Hands-On Exercise:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Use <b>UiPath</b> to automate a routine business process (e.g., invoice processing).
                  </p>
                </li>
            </ul>
            `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          }
        ]
      },
      {
        courseID: 73,
        courseCurriculumID: 1240,
        name: "Capstone Project",
        sortOrder: 2,
        CourseCurriculumDetails: [
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Capstone Project",
            description: `

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Apply Everything You've Learned:</b></h6>
            <p class="counrse-contn-1 ct-sm-left">Develop a <b>comprehensive AI strategy</b> for your organization, integrating AI applications, ethical considerations, cloud solutions, and project management principles.</p>
            
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Presentation:</b></h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Present your AI strategy to the cohort and receive feedback on feasibility, scalability, and implementation.
                  </p>
                </li>
            </ul>

             `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          }
        ]
      },
      {
        courseID: 73,
        courseCurriculumID: 1240,
        name: "Generative AI for Everyone - Building Blocks (Part 02)",
        sortOrder: 2,
        CourseCurriculumDetails: [
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Introduction to Google Gemini",
            description: `
            
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Introduction and Usage
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Integration with Google workspace and Youtube
                  </p>
                </li>
            </ul>

             `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          },
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Introduction to Image Generation Using AI",
            description: `
            
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Introduction, applications in different industries, and ethical responsibilities
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Introduction to Midjourney and Basics of image generation
                  </p>
                </li>
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Deep-dive into image-generation techniques
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Midjourney Working with images
                  </p>
                </li>
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Understanding Image aesthetics
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    DALL-E: Generating e-commerce photos and promotional banners for products
                  </p>
                </li>
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Introduction to BING Images
                  </p>
                </li>
            </ul>
             `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          }
        ]
      },
      {
        courseID: 73,
        courseCurriculumID: 1240,
        name: "Generative AI for Professionals/Developers",
        sortOrder: 2,
        CourseCurriculumDetails: [
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Introduction to OpenAI",
            description: `
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Introduction to OPENAI API
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Function Calling - Introduction
                  </p>
                </li>
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Function Calling using weather API
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Function calling - Building a Quiz Application
                  </p>
                </li>
            </ul>

             `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          },
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Introduction to Opensource LLM’s",
            description: `
            
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Introduction to Ollama and Open Source LLMs 
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Ollama as a tool to run Gemma, Mistral, Llava and Llama models locally
                  </p>
                </li>
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Creating a custom model with system prompt
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Using Ollama in Python and Rest API
                  </p>
                </li>
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Small Project - Building an image annotation tool using Ollama + Llava + Streamlit
                  </p>
                </li>
            </ul>
             `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          },
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Introduction to Langchain",
            description: `
            
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Introduction to Langchain, concept of chains 
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Function calling
                  </p>
                </li>
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Agents in Langchain
                  </p>
                </li>
            </ul>
             `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          },
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Introduction to Retrieval Augmented Generation (RAG)",
            description: `
            
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Introduction to implementing a RAG in python using Langchain 
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Langchain loaders and splitters and vector databases
                  </p>
                </li>
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Using Pinecone as vector database and creating retrieval chains
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Building an application and creating a streamlit UI
                  </p>
                </li>
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Creating Git repo and deployment to streamlit cloud
                  </p>
                </li>
            </ul>
             `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          }
        ]
      },
      {
        courseID: 73,
        courseCurriculumID: 1240,
        name: "Workshop",
        sortOrder: 2,
        CourseCurriculumDetails: [
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Hands on Projects",
            description: `
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Objective of workshop
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Introduction to AI Project Ecosystem & Tools
                  </p>
                </li>
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Walkthrough of use cases
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Work on end-to-end project-1
                  </p>
                </li>
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Work on end-to-end project-2
                  </p>
                </li>
            </ul>

             `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          }
        ]
      }
    ]
  },
  whoShouldDoRecord: {
    whoShouldDo: `<p class="counrse-contn-1 ct-sm-left">AnalytixLabs has been at the forefront of data science and AI education since 2011. Our expert instructors and seasoned industry veterans deliver cutting-edge curriculum designed to equip you with in-demand skills. Learn by doing with real-world projects and emerge job-ready to tackle complex business challenges.</p>
    <p class="counrse-contn-1 ct-sm-left">
    AnalytixLabs is bridging the opportunity-to-skill gap while taking on real-world challenges head-on. Our Generative AI Certification courses adhere to current market needs and are designed to stay relevant in the coming days. This is because it addresses the three main challenges -
    </p>
    

      <ul class="cours-ovr-ul">
        <li class="drc-blcr" style='list-style:none; text-align:left;'>
          <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
            <b>
              <span class='ct-color-dark-blue' >
                Global Competitiveness: 
              </span>
            </b>
            Mastering Generative AI will position you to lead businesses on a global stage.
          </p>
        </li>
        <li class="blcr" style='list-style:none; text-align:left;'>
          <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
            <b>
              <span class='ct-color-dark-blue' >
                Addresses Local Challenges: 
              </span>
            </b>
            You will be prepared to tackle unique challenges such as healthcare, agriculture, education, and infrastructure.
          </p>
        </li>
        <li class="drc-blcr" style='list-style:none; text-align:left;'>
          <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
            <b>
              <span class='ct-color-dark-blue'>
                Skill Shortage: 
              </span>
            </b>
            There's a growing demand for AI talent in India, making it a lucrative career path. Experts like you will reap maximum benefits with the right skills and an agile approach to deliver results.
          </p>
        </li>
      </ul>
      `,
    CourseWhoShouldDos: []
  },
  jobRolesAndSkills: {
    keyDescription:
      "This course empowers managers and leaders with technical expertise and strategic acumen. By mastering technical and soft skills, you will learn to navigate the AI landscape and drive business transformation.",
    jobRoles: [
      {
        jobRoleID: 21,
        JobRole: {
          jobRoleID: 21,
          name: "AI Engineer"
        }
      },
      {
        jobRoleID: 22,
        JobRole: {
          jobRoleID: 22,
          name: "Machine Learning Engineer"
        }
      },
      {
        jobRoleID: 23,
        JobRole: {
          jobRoleID: 23,
          name: "Robotics Specialist"
        }
      },
      {
        jobRoleID: 24,
        JobRole: {
          jobRoleID: 24,
          name: "Computer Vision Engineer"
        }
      },
      {
        jobRoleID: 25,
        JobRole: {
          jobRoleID: 25,
          name: "Data Scientist"
        }
      },
      {
        jobRoleID: 26,
        JobRole: {
          jobRoleID: 26,
          name: "AI-ML Specialist"
        }
      },
      {
        jobRoleID: 27,
        JobRole: {
          jobRoleID: 27,
          name: "Data Science Consultant"
        }
      },
      {
        jobRoleID: 28,
        JobRole: {
          jobRoleID: 28,
          name: "Analytics Consultant"
        }
      }
    ],
    keySkills: [
      {
        keySkillID: 115,
        KeySkill: {
          keySkillID: 115,
          name: "Large-Scale Analytics on Cloud",
          image: null
        }
      },
      {
        keySkillID: 116,
        KeySkill: {
          keySkillID: 116,
          name: "Predictive Modelling using Neural Network",
          image: null
        }
      },
      {
        keySkillID: 117,
        KeySkill: {
          keySkillID: 117,
          name: "Image Processing Using Deep Learning Techniques",
          image: null
        }
      },
      {
        keySkillID: 118,
        KeySkill: {
          keySkillID: 118,
          name: "Natural Language Processing & Generation (Chatbots)",
          image: null
        }
      },
      {
        keySkillID: 119,
        KeySkill: {
          keySkillID: 119,
          name: "Deep Learning Modelling for AI Applications",
          image: null
        }
      },
      {
        keySkillID: 120,
        KeySkill: {
          keySkillID: 120,
          name: "Data Science Methods",
          image: null
        }
      },
      {
        keySkillID: 121,
        KeySkill: {
          keySkillID: 121,
          name: "Supervised Learning Models",
          image: null
        }
      },
      {
        keySkillID: 122,
        KeySkill: {
          keySkillID: 122,
          name: "Unsupervised Learning Models",
          image: null
        }
      }
    ],
    keySkillsHTMLTable: `
    <div class='counrse-contn'>
        <p class="counrse-contn-1 ct-sm-left mt-2 mb-3">
          This curriculum positions you for beginners and leadership roles that demand a strategic, data-driven approach. You'll be well-prepared for positions like:
        </p>
        <ul class="cours-ovr-ul">
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  Generative AI Engineer: 
                </span>
              </b>
               Develop, train, and deploy generative AI models for business applications.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  Machine Learning Engineer: 
                </span>
              </b>
              Build and maintain the machine learning infrastructure for generative AI systems.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  NLP Engineer: 
                </span>
              </b>
              Focus on natural language processing techniques for AI applications.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  Data Scientist: 
                </span>
              </b>
              Utilize generative AI to extract insights from data and drive business decisions.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  AI Business Analyst: 
                </span>
              </b>
              Bridge the gap between business needs and AI solutions.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  AI Consultant: 
                </span>
              </b>
              Provide AI expertise to organizations seeking to implement generative AI.
            </p>
          </li>
          
        </ul>

          <p class="counrse-contn-1 ct-sm-left mt-2 mb-3">
            The generative AI course empowers beginners, managers, and leaders with technical expertise and strategic insight. You will learn to navigate the AI landscape and drive business transformation by mastering technical and soft skills.
          </p>
    </div>

    `,
    keySkillsHTML: `
     <div class='counrse-contn'>
        <p class="counrse-contn-1 ct-sm-left mt-2 mb-3">
          This curriculum positions you for leadership roles that demand a strategic, data-driven approach. You'll be well-prepared for positions like:
        </p>
        <ul class="cours-ovr-ul ct-cours-ovr-ul">
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  AI Strategy Lead: 
                </span>
              </b>
              Develop and implement AI strategies across the organization.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  AI Project Manager: 
                </span>
              </b>
              Oversee AI initiatives from conception to deployment.
            </p>
          </li>

          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  Head of Data and Analytics: 
                </span>
              </b>
              Lead data-driven decision-making and AI initiatives.
            </p>
          </li>

          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  Digital Transformation Officer: 
                </span>
              </b>
              Drive business transformation leveraging AI and technology.
            </p>
          </li>

          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  Business Unit Head: 
                </span>
              </b>
              Lead business units with a focus on AI-driven innovation.
            </p>
          </li>

          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  Chief AI Officer (CAIO): 
                </span>
              </b>
              Oversee an organization's AI strategy and implementation.
            </p>
          </li>

          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  AI Ethics Officer: 
                </span>
              </b>
              Ensure ethical and responsible use of AI in the organization.
            </p>
          </li>

          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  AI Product Manager: 
                </span>
              </b>
              Look into developing and launching AI-powered products and services.
            </p>
          </li>

        </ul>


    </div>
    `,
    jobRolesAndSkillsHTML: `
     <div class='counrse-contn'>

      <h3 class='ct-color-dark-blue'>Technical Skills</h3>
        <ul class="cours-ovr-ul ct-cours-ovr-ul">
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  AI and Machine Learning Fundamentals: 
                </span>
              </b>
              Understanding core concepts, algorithms, and techniques.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  Deep Learning: 
                </span>
              </b>
              Knowledge of neural networks, architectures (CNNs, RNNs, Transformers), and training methodologies.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  Natural Language Processing (NLP): 
                </span>
              </b>
              Familiarity with text processing, sentiment analysis, and language models.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  Generative Models: 
                </span>
              </b>
              Understanding of GANs, VAEs, and Transformers.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  Large Language Models (LLMs): 
                </span>
              </b>
              Knowledge of LLM architecture, functioning, and applications.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  Prompt Engineering: 
                </span>
              </b>
              Ability to design and optimize prompts for LLMs.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  Python Programming: 
                </span>
              </b>
              Proficiency in Python for data manipulation, model development, and experimentation.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  Cloud Platforms: 
                </span>
              </b>
              Familiarity with cloud-based AI tools and platforms (e.g., AWS, GCP, Azure).
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  Data Analysis and Visualization: 
                </span>
              </b>
              Data exploration, cleaning, and visualization skills.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  AI Fundamentals: 
                </span>
              </b>
              Understanding of AI concepts, types, and applications.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  Data Science: 
                </span>
              </b>
              Proficiency in data collection, processing, and analysis.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  AI Tools: 
                </span>
              </b>
              Familiarity with AI software, platforms, and cloud services.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  AI Model Development: 
                </span>
              </b>
              Knowledge of AI model frameworks and development processes.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  AI Engineering: 
                </span>
              </b>
              Understanding DevOps, MLOps, and DataOps for AI implementation.
            </p>
          </li>
        </ul>
      

      <h3 class='ct-color-dark-blue'>Soft Skills</h3>

      <ul class="cours-ovr-ul ct-cours-ovr-ul">
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  Problem-solving and Critical Thinking: 
                </span>
              </b>
              Ability to analyze complex problems and develop effective solutions.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  Creativity and Innovation: 
                </span>
              </b>
              Capacity to generate new ideas and approaches.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  Business Acumen: 
                </span>
              </b>
              Understanding of business operations and how AI can drive value.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  Strategic Thinking: 
                </span>
              </b>
              Ability to develop AI strategies aligned with business goals.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  Decision Making: 
                </span>
              </b>
              Ability to make data-driven decisions and evaluate AI initiatives.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  Communication and Presentation: 
                </span>
              </b>
              Ability to articulate complex technical concepts to non-technical audiences.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  Teamwork and Collaboration: 
                </span>
              </b>
              Ability to work effectively in cross-functional teams.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  Ethical Considerations: 
                </span>
              </b>
              Understanding of AI ethics and responsible AI practices.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span class='ct-color-dark-blue'>
                  Decision Making: 
                </span>
              </b>
              Ability to make data-driven decisions and evaluate AI initiatives.
            </p>
          </li>
      </ul>
    </div>
    `,
    allScreenSizeSameKeySkill: true
  },
  deliveryFormatRecord: [
    {
      learningModeID: 1,
      LearningMode: {
        learningModeID: 1,
        name: "Classroom & Bootcamps",
        image: "image/learningMode/img-1.png",
        description: "Experiential learning with in-person mentorship!\r\n\r\n"
      }
    },
    {
      learningModeID: 2,
      LearningMode: {
        learningModeID: 2,
        name: "Classroom with ILT Blended",
        image: "image/learningMode/img-2.png",
        description:
          "Learn from the best with unrestricted support, wherever you are!\r\n\r\n"
      }
    },
    {
      learningModeID: 3,
      LearningMode: {
        learningModeID: 3,
        name: "Blended eLearning",
        image: "image/learningMode/img-3.png",
        description: "Learn at your pace with on-demand lectures & Support!"
      }
    }
  ],
  courseCertificationRecord: {
    CourseCertifications: [
      {
        courseID: 73,
        certificationID: 231,
        Certification: {
          certificationID: 231,
          name:
            "AnalytixLabs certifications are highly regarded in the industry due to our extensive domain expertise. As India’s leading Data Science and AI institute, we must maintain the integrity of our certification process.",
          description: null,
          image: null,
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-09-26T18:26:07.000Z",
          updatedAt: "2023-10-14T08:40:30.000Z"
        }
      },
      {
        courseID: 74,
        certificationID: 232,
        Certification: {
          certificationID: 232,
          name:
            "Certification will be granted only upon meeting specified requirements within the course timelines, which include case studies, multiple-choice questions (MCQs), etc. Each candidate will have two attempts to pass each assessment.",
          description: null,
          image: null,
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-09-26T18:26:07.000Z",
          updatedAt: "2023-10-14T08:40:30.000Z"
        }
      },
      {
        courseID: 75,
        certificationID: 233,
        Certification: {
          certificationID: 233,
          name:
            "We aim to provide trainees with essential hands-on experience to prepare them for industry challenges.",
          description: null,
          image: null,
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-09-26T18:26:07.000Z",
          updatedAt: "2023-10-14T08:40:30.000Z"
        }
      },
      {
        courseID: 76,
        certificationID: 234,
        Certification: {
          certificationID: 234,
          name:
            "Certification must be secured within one year of course registration.",
          description: null,
          image: null,
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-09-26T18:26:07.000Z",
          updatedAt: "2023-10-14T08:40:30.000Z"
        }
      }
    ],
    CourseCertificationImages: [
      {
        courseID: 73,
        certificationImage:
          "image/certificateImage/AI_for_Managers_and_Leaders_240827.jpg"
      }
      // {
      //   courseID: 73,
      //   certificationImage:
      //     "image/certificateImage/Advanced_Certification_in_Full_Stack_Applied_AI_1.jpg"
      // }
    ]
  },
  feesRecord: [
    {
      extraText: "(50% Pay after placement)",
      courseLearningModeID: 4199,
      courseID: 73,
      learningModeID: 1,
      offerName: null,
      offerDescription: null,
      offerImage: null,
      amountINR: 30000,
      amountUSD: 950,
      isInstallment: false,
      amountFirstInstallmentINR: null,
      amountSecondInstallmentINR: null,
      amountFirstInstallmentUSD: null,
      amountSecondInstallmentUSD: null,
      discountType: 1,
      isRecommended: false,
      discountAmount: 0,
      discountStartDate: null,
      discountEndDate: null,
      sortOrder: 0,
      isActive: true,
      isDeleted: false,
      createdBy: null,
      updatedBy: null,
      createdAt: "2023-10-31T06:49:55.000Z",
      updatedAt: "2023-10-31T06:49:55.000Z",
      LearningMode: {
        name: "Classroom & Bootcamps"
      }
    },
    {
      extraText: "(50% Pay after placement)",
      courseLearningModeID: 4197,
      courseID: 73,
      learningModeID: 2,
      offerName: null,
      offerDescription: null,
      offerImage: null,
      amountINR: 30000,
      amountUSD: 950,
      isInstallment: false,
      amountFirstInstallmentINR: null,
      amountSecondInstallmentINR: null,
      amountFirstInstallmentUSD: null,
      amountSecondInstallmentUSD: null,
      discountType: 1,
      isRecommended: false,
      discountAmount: 0,
      discountStartDate: null,
      discountEndDate: null,
      sortOrder: 0,
      isActive: true,
      isDeleted: false,
      createdBy: null,
      updatedBy: null,
      createdAt: "2023-10-31T06:49:55.000Z",
      updatedAt: "2023-10-31T06:49:55.000Z",
      LearningMode: {
        name: "Classroom with ILT Blended"
      }
    },
    {
      extraText: "(50% Pay after placement)",
      courseLearningModeID: 4198,
      courseID: 73,
      learningModeID: 3,
      offerName: null,
      offerDescription: null,
      offerImage: null,
      amountINR: 30000,
      amountUSD: 800,
      isInstallment: false,
      amountFirstInstallmentINR: null,
      amountSecondInstallmentINR: null,
      amountFirstInstallmentUSD: null,
      amountSecondInstallmentUSD: null,
      discountType: 1,
      isRecommended: false,
      discountAmount: 0,
      discountStartDate: null,
      discountEndDate: null,
      sortOrder: 0,
      isActive: true,
      isDeleted: false,
      createdBy: null,
      updatedBy: null,
      createdAt: "2023-10-31T06:49:55.000Z",
      updatedAt: "2023-10-31T06:49:55.000Z",
      LearningMode: {
        name: "Blended eLearning"
      }
    }
  ],
  downloadBrochureResult: {
    courseID: 73,
    name: "Artificial Intelligence Engineering",
    brochureFile:
      "image/brochure/AnalytixLabs - AI Engineering Specialization-1626437470670.pdf",
    demoBrochureHTML: `
      <p class="counrse-contn-1 ct-sm-left" ><b>Uncertain about the best generative AI course online?</b> Try our teaching methodology firsthand with a free demo account. Then, gain exclusive access to discounted specialization tracks and job-oriented programs curated by industry experts.</p>
      `
  },
  courseFaqRecord: {
    faqRecord: [
      {
        FaqID: 1016,
        description: `
        <p>The right generative AI learning path involves the following steps: </p>
        
          <ul class='pg-lg-ul-r-20'>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                Enroll for our Generative AI for Developers Certification Course
              </p>
            </li>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                Follow our learning tracks and complete assignments and projects
              </p>
            </li>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                Participate in building your Capstone Project and receive constructive feedback to work on
              </p>
            </li>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                Take experts’ help in building your resume and preparing for interviews through real-time interview simulations
              </p>
            </li>
          </ul>

          <p>That’s it. 🙂</p>
          <p>To become a GenAI developer, you must go through the following learning experience: </p>

          <ul class='pg-lg-ul-r-20'>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                Understand the basics of machine learning and AI
              </p>
            </li>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                Learn Python programming
              </p>
            </li>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                Understand and explore the basics of data science and deep learning
              </p>
            </li>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                Start working with the foundational concepts of generative AI
              </p>
            </li>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                Explore and experiment with various industry-relevant tools to solve real challenges
              </p>
            </li>
            <li>
              <p class="counrse-contn-1 ct-sm-left">
                Complete projects and assignments
              </p>
            </li>
          </ul>
          <p>This is the optimal path to becoming a generative AI developer. If you have any questions or need clarification, please contact us directly by clicking on Apply or our Contact Us page. We are also available on LinkedIn for direct messaging.</p>
          `,
        title: "What is the generative AI learning path for a developer? ",
        sortOrder: 0,
        Course: {
          faqDescription: "FAQs - Frequently Asked Questions"
        }
      },
      {
        FaqID: 1018,
        description: `<p>Our Generative AI certificate course for developers is built by the industry’s leading experts in association with E&ICT Academy, IITG. Our curriculum is at par with the ever-evolving AI landscape, preparing you for the toughest challenges. This one is for you if you are looking for the best generative AI courses near you or anywhere in India. </p>
          `,
        title: "Which course is best for Generative AI?",
        sortOrder: 1,
        Course: {
          faqDescription: "FAQs - Frequently Asked Questions"
        }
      },
      {
        FaqID: 1019,
        description: `<p>This generative AI training course is for beginners comfortable with technical knowhows like Python programming and model buildings. If you are not comfortable with programming and technical additions in course modules, then this course might be hard to grasp. </p>
        <p>
        For non-technical professionals and learners, our <a href="/artificial-intelligence-for-managers-leaders" class="ct-lg-linkUpdate" target="_blank">AI for Leaders and Managers course</a> is ideal. This course is also a beginners’ course and does not include any technical course modules. 
        </p>
        
          `,
        title: "Is this course only for developers?",
        sortOrder: 1,
        Course: {
          faqDescription: "FAQs - Frequently Asked Questions"
        }
      },
      {
        FaqID: 1020,
        description: `<p>No. Both the learning tracks are separately taught. Your choice completely depends on your career goals. The Generative AI for Professionals and Developers course is ideal for anyone comfortable with technical learnings, while AI for Leaders and Managers is ideal for non-technical learners. </p>
        <p>
        Both are for beginners and requires no prior experience. You can take one course at a time or enroll on both the courses and attend them as per class schedule. Learning one is not mandatory to learn the other.
        </p>
        `,
        title:
          "Do I have to take this course before enrolling in the AI for Managers and Leaders course?",
        sortOrder: 1,
        Course: {
          faqDescription: "FAQs - Frequently Asked Questions"
        }
      },
      {
        FaqID: 1021,
        description: `
        <p>Yes. The GenAI for Professionals and Developers course and AI for Managers and Leaders course are short-term courses spanning maximum 18 weeks and 12 weeks, respectively. Our other long courses are open for enrollment for anyone interested in dedicated certification learning. Unless your dates clash and you miss classes, you can opt-in for courses of your choice. </p>
        <p>Bonus: If you are enrolled for our signature certificate courses of 6 months or 1 year, you can get your course fees adjusted for these short courses. The amount you have already paid for the first course will be deducted when you enroll for a new course at AnalytixLabs. However, we encourage you to finish all your courses, including projects and assignments.</p>
          `,
        title:
          "Can I take other courses from AnalytixLabs while still enrolled in this program?",
        sortOrder: 1,
        Course: {
          faqDescription: "FAQs - Frequently Asked Questions"
        }
      },
      {
        FaqID: 1022,
        description: `
        <p>No, there is no free version of this course. However, you can enroll for a course demo from our website to see how learning happens at AnalytixLabs. You can also join our masterclasses, which we frequently host for aspiring students. These classes are free to attend and designed to help offer more clarity to our students. </p>
        <p>
        To know about our upcoming masterclasses and what to expect, contact <a href="/contact-us" class="ct-lg-linkUpdate" target="_blank">our counseling team immediately</a>. 
        </p>
        `,
        title: "Is there a free version of this course?",
        sortOrder: 1,
        Course: {
          faqDescription: "FAQs - Frequently Asked Questions"
        }
      },
      {
        FaqID: 1022,
        description: `<p>Upon successful completion of the course, you will receive a globally recognized certificate from AnalytixLabs. As a pioneer in the AI and Data Science domain since 2011, AnalytixLabs is renowned for its industry-leading training programs and the deep expertise of its core team. This certification carries significant credibility in the global job market, reflecting the rigorous curriculum and practical knowledge imparted throughout the program.
        </p>
        
        `,
        title:
          "What kind of certification or accreditation is offered upon course completion?",
        sortOrder: 1,
        Course: {
          faqDescription: "FAQs - Frequently Asked Questions"
        }
      }
    ],
    courseID: 73,
    description: "FAQs - Frequently Asked Questions"
  },
  successStories: [
    {
      userReviewsId: 102,
      courseID: 73,
      reviewText:
        "I am ineffable to express my journey through AnalytixLabs, Gurgaon .  Since we all know the faculties are the backbone of any institute to make it reach to world class level and AnalytixLabs has very experienced faculties who not only teach you but also supervise you in your further academic career . The institute has best infrastructure in terms of study material , faculties , classrooms etc . Student will get chance to work on live projects . You will get excellent training in analytic field . The whole training period is just wonderful . To be precise , AnalytixLabs is one of the INDIA's best analytics  institute .",
      User: {
        userID: 107,
        email: "gmohit280@gmail.com",
        Student: {
          userID: 107,
          studentID: 87,
          name: "Mohit Jain",
          profileImage: "image/studentImage/Mohit_Jain.jpg",
          designation: "Data Scientist",
          organization: "J.P. Morgan Chase & Co.",
          organizationImage: null,
          linkedinUrl: "https://in.linkedin.com/pub/mohit-jain/3a/b83/973"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 92,
      courseID: 73,
      reviewText:
        "I went for a combo program @ AnalytixLabs - Business Analytics (SAS+BA) + Big Data Analytics (R+Hadoop). The program was superbly well structured and faculty had great command on their subject. While I had opted for classroom training, but the online facility came in as a boon and I attended all classes from home without feeling any difference. The assignments for each module ensured that we worked hands on and gained quality training. The practical examples and Q&amp;A ensured the classes were not just interactive but also lively. I had a great experience @ Analytixlabs and would recommend everyone who is looking for professional training in analytics.",
      User: {
        userID: 97,
        email: "deepika.goel@hotmail.com",
        Student: {
          userID: 97,
          studentID: 77,
          name: "Deepika Goel, VP - Genpact Bangalore",
          profileImage: "image/studentImage/Deepika_Goel.jpg",
          designation: "Director",
          organization: "Marsh & McLennan Companies",
          organizationImage: null,
          linkedinUrl: "https://www.linkedin.com/in/deepikagoel9811347436/"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 138,
      courseID: 73,
      reviewText:
        "The rapid pace at which the institutes are mushrooming all over India and Facebook newsfeed being inundated with thousands of options for analytics training, there are only handful of training institutes or rather say only couple of places where they ???prepare??? you to foray into rapidly growing analytics vertical. I have been working into legacy systems for past 5 years and was quite apprehensive if I will ever break into data science successfully. While I was working on my options of training institutes, a brief ???counseling??? by Mouli sir and Sumeet sir obviated the further process of evaluating my options. And I can safely say that settling in for AnalytixLabs was a safe and wise decision. The reason they have been bellwether among all training institutes is due to the fact that they have been emphasizing more on algorithms and underlying mathematical and statistical concepts which is bedrock for this track and not just trammeling their trainees with list of syntax of statistical tools. Fortunately with proper guidance and encouragement from faculty, I managed to switch over to analytics from traditional IT background. BIG thanks!",
      User: {
        userID: 143,
        email: "sumitasthana14@gmail.com ",
        Student: {
          userID: 143,
          studentID: 123,
          name: "Sumit Asthana",
          profileImage: "image/studentImage/2d50efc.jpg",
          designation: "Assistant Manager",
          organization: "Senior Data Scientist",
          organizationImage: null,
          linkedinUrl: "https://in.linkedin.com/in/thatsumit"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 165,
      courseID: 73,
      reviewText:
        "First of all Big Thanks to Analytix Labs. Its an Awesome institute to the one who wants to shine up his/her career in Data Science &amp; Analytics. As the name suggest's, its not just a learning institute but also a play ground to play with Case study based modules crafted by experts based on real life business scenarios. Though I discontinued from the Sessions due to personal reasons, The Team helped me like an AnalytiX Labs Alumni when I came back to Institute after two long years. The esteemed help from the Mentors varied from providing Mock Exams, Doubts Clarification sessions, Case Studies, Resume Building and Mock Interview Sessions. I am really grateful to the Analytix Labs Team for providing all the help in an need hour. The extended support and Mock exams helped me to achieve Base SAS Certification (BP064209V9) with 97%. I'll definitely highly recommend this institute to everyone without hesitation.",
      User: {
        userID: 170,
        email: "viswanadhsogana@gmail.com",
        Student: {
          userID: 170,
          studentID: 150,
          name: "Viswanadh Sogana",
          profileImage: "image/studentImage/Sogna.jpg",
          designation: "Accenture",
          organization: "Senior Analyst",
          organizationImage: null,
          linkedinUrl: "https://in.linkedin.com/in/viswanadhsogana"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 130,
      courseID: 73,
      reviewText:
        "AnalytixLabs stands out from the crowd of vast analytics courses mainly because of its practical and conceptual approach. The 'intent' and the 'content' was way too awesome, to say the least. Excellent course, brilliant co-founders with immense knowledge on the subject matter. Just The Best Learning Centre till date.",
      User: {
        userID: 135,
        email: "atul_kapur@outlook.com",
        Student: {
          userID: 135,
          studentID: 115,
          name: "Atul Kapur",
          profileImage: "image/studentImage/Atul_Kapur1.jpg",
          designation: "",
          organization: "RBS Chennai",
          organizationImage: null,
          linkedinUrl: "linkedin.com/in/atulkap"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 112,
      courseID: 73,
      reviewText:
        "I underwent 2 trainings at AnalytixLabs: SAS+Business Analytics and Big Data Analyst. After these 2 big courses, I am confident enough to never go wrong when I say that the quality of training here is truly tough to find anywhere else. When you are really concerned about taking the next big career step, you undoubtedly need - not just a trainer, not just a mentor and not just a career counsellor. You need it all, and that's what AnalytixLabs has given me. During these times, when institutes bring forward low-experienced trainers to make money out of the &quot;Analytics&quot; buzzword, the highly-experienced faculty members at AnalytixLabs provided such an individual attention that I felt like going nowhere else for any career advice or technical help at different points of time during my stint to switch job to analytics. And yes, finally I have succeeded to get a great one for myself! Thanks AnalytixLabs! You are a big hope for all such people like me who are looking for that one hand to take us along the right career path. Keep doing this great work and I am sure you will grow high with these endeavours!!",
      User: {
        userID: 117,
        email: "neha.analytics303@gmail.com",
        Student: {
          userID: 117,
          studentID: 97,
          name: "Neha Luthra",
          profileImage: "image/studentImage/Neha_Luthra.jpg",
          designation: "Assistant Manager",
          organization: "The Smartcube Pvt ltd",
          organizationImage: null,
          linkedinUrl: "https://in.linkedin.com/in/neha-luthra-4406a497"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 105,
      courseID: 73,
      reviewText:
        "AnalytixLab is one of the best institute we can prefer, especially for big data analytics perspective which i am familiar. ALabs has dedicated, and real time experienced faculty in the industry on various analytics domains, they are also available at any time if we need any clarification on career or any technical quires. One thing which i observed on my association with them was they would complete the full syllabus irrespective of the time constraint, other institutes are bounded to their predefined time, and try to rush up the syllabus to catch up the next batches",
      User: {
        userID: 110,
        email: "sumendar@gmail.com",
        Student: {
          userID: 110,
          studentID: 90,
          name: "Sumendar Karupakala",
          profileImage: "image/studentImage/Sumendar_Karupakala.jpg",
          designation: "Certified Big Data Analyst",
          organization: "TrueBees Technologies",
          organizationImage: null,
          linkedinUrl: "https://in.linkedin.com/in/sumendar"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 117,
      courseID: 73,
      reviewText:
        "I took three months Big Data training  from Analytix labs. Before joining this course, I had so many questions and doubts that will this course be worthy enough but later I found myself lucky to join this program. All trainers are amazing and always ready to help. Live examples and Case Studies are given which helps in understanding the concept and hands on practice.Not only they help in quality in depth knowledge transfer but also tend to give right direction to your career.",
      User: {
        userID: 122,
        email: "surbhi.sultania@gmail.com",
        Student: {
          userID: 122,
          studentID: 102,
          name: "Surbhi Sultania",
          profileImage: "image/studentImage/Surbhi_Sultania.jpg",
          designation:
            "Assistant Manager - Consumer Research and Insights Specialist, Primary Quantitative Research",
          organization: "The Smartcube Pvt ltd",
          organizationImage: null,
          linkedinUrl: "https://in.linkedin.com/in/surbhi-sultania-0486b24a"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 125,
      courseID: 73,
      reviewText:
        "I sincerely appreciate the learning I got at Analytixlabs. Faculties here are very experienced and their dedication towards teaching is commendable. Sumeet and Chandra, both are very cooperative and provide all the counseling and support a person could ask for. My internship at the Analytixlabs has given me professional experience also helped me in achieving my goals. I would recommend Analytixlabs to everyone as they are THE BEST!! I wish AnalytixLabs all the best and a BIG THANK YOU.",
      User: {
        userID: 130,
        email: "kirti14dayal@gmail.com",
        Student: {
          userID: 130,
          studentID: 110,
          name: "Kirti Dayal",
          profileImage: "image/studentImage/3a0462f.jpg",
          designation: "Sr. Analyst",
          organization: "AbsolutData Analytics",
          organizationImage: null,
          linkedinUrl: "https://in.linkedin.com/in/kirtidayal"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 120,
      courseID: 73,
      reviewText:
        "AnalytixLabs is the best place for all sorts of Analytics training be it R, Hadoop, SAS, Excel or others. They have a very meticulous approach towards the curriculum development, training, peer-faculty interaction, placements as well as career guidance. I myself have had an awesome experience with AnalytixLabs and would rate them the best in the field.",
      User: {
        userID: 125,
        email: "tarun.bhavnani@gmail.com",
        Student: {
          userID: 125,
          studentID: 105,
          name: "Tarun Bhavnani",
          profileImage: "image/studentImage/default.png",
          designation: "AVP, Data Science",
          organization: "SMECorner",
          organizationImage: null,
          linkedinUrl: "https://www.linkedin.com/in/tarunbhavnani/"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    }
  ],
  careerSupport: [
    {
      courseCareerSupportID: 611,
      courseID: 73,
      careerSupportID: 615,
      sortOrder: 1,
      createdAt: "2020-09-28T09:27:47.000Z",
      updatedAt: "2020-10-01T10:34:24.000Z",
      CareerSupport: {
        careerSupportID: 615,
        title: "Pay After Placement Model",
        image: "image/course/Analytixlabs-icons-svg-36.svg",
        description:
          "We align our success with yours. Our innovative pay-after-placement model requires only a 50% upfront course fee. The remaining balance is conveniently structured as EMIs, payable solely after you secure a job meeting our minimum CTC threshold.",
        isActive: true,
        isDeleted: false,
        createdBy: null,
        updatedBy: null,
        createdAt: "2020-09-28T09:27:47.000Z",
        updatedAt: "2023-10-31T06:49:55.000Z"
      }
    },
    {
      courseCareerSupportID: 613,
      courseID: 73,
      careerSupportID: 617,
      sortOrder: 2,
      createdAt: "2020-09-28T09:27:47.000Z",
      updatedAt: "2020-10-01T10:34:24.000Z",
      CareerSupport: {
        careerSupportID: 617,
        title: "Placement Readiness Program",
        image: "image/course/Analytixlabs-icons-svg-34.svg",
        description:
          "Our intensive 2-month program is meticulously crafted to bridge the gap between academia and industry. Through technical skill enhancement, soft skill coaching, and rigorous interview simulations, we equip you with the arsenal to excel in high-stakes recruitment processes.",
        isActive: true,
        isDeleted: false,
        createdBy: null,
        updatedBy: null,
        createdAt: "2020-09-28T09:27:47.000Z",
        updatedAt: "2023-10-31T06:49:55.000Z"
      }
    },
    {
      courseCareerSupportID: 614,
      courseID: 73,
      careerSupportID: 618,
      sortOrder: 3,
      createdAt: "2020-09-28T09:27:47.000Z",
      updatedAt: "2020-10-01T10:34:24.000Z",
      CareerSupport: {
        careerSupportID: 618,
        title: "Flexible Opt-Out Options",
        image: "image/course/Analytixlabs-icons-svg-33.svg",
        description:
          "Before commencing the Placement Readiness Program (PRP), certified students may elect to forgo the Pay After Placement option. This is suitable for learners prioritizing skill enhancement or certificate acquisition who feel sufficiently prepared for independent job seeking. While basic placement support remains accessible, these students will receive a waiver on the remaining 50% program fee.",
        isActive: true,
        isDeleted: false,
        createdBy: null,
        updatedBy: null,
        createdAt: "2020-09-28T09:27:47.000Z",
        updatedAt: "2023-10-31T06:49:55.000Z"
      }
    },
    {
      courseCareerSupportID: 612,
      courseID: 73,
      careerSupportID: 616,
      sortOrder: 4,
      createdAt: "2020-09-28T09:27:47.000Z",
      updatedAt: "2020-10-01T10:34:24.000Z",
      CareerSupport: {
        careerSupportID: 616,
        title: "End-to-end Support",
        image: "image/course/Analytixlabs-icons-svg-35.svg",
        description:
          "Our support extends beyond PRP completion. Graduates consistently leverage their acquired skills to secure multiple interview opportunities and fulfilling careers. Our alumni network demonstrates our program's effectiveness, with many securing roles in top-tier companies.",
        isActive: true,
        isDeleted: false,
        createdBy: null,
        updatedBy: null,
        createdAt: "2020-09-28T09:27:47.000Z",
        updatedAt: "2023-10-31T06:49:55.000Z"
      }
    }
  ],
  courseDetails: {
    courseID: 73,
    courseFullName: "Generative AI Course",
    name: "Generative AI Course",
    slug: "generative-ai-course",
    shortDescription:
      "Generative AI Course: Hands-On Training for Career Advancement",
    longDescription: `
        <p class="counrse-contn-1 ct-sm-left">
          <strong>
            With AI set to disrupt 80% of current job roles in the next decade, upskilling is no longer an option—it's your ticket to staying relevant and thriving in the future workforce.
          </strong>
        </p>
        <p class="counrse-contn-1 ct-sm-left">Generative AI, or GenAI, is at the center of every innovation, and companies of all sizes are either investing or exploring GenAI to achieve business goals faster and more efficiently. The advent of GenAI has revolutionized various job roles across every industry and sector. It is your turn to become a GenAI expert and take lead in the world of AI. </p>
        <p class="counrse-contn-1 ct-sm-left">The Generative AI programs at AnalytixLabs aim to offer a holistic learning experience that will prepare future AI experts and leaders. Whether you have a technical background or not, there is a learning track designed for you. </p>

        <ul class="cours-ovr-ul">
          <li class="drc-blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
                <b>GenAI for Tech Professionals</b>
            </p>
          </li>
          <li class="blcr" style='list-style:none; text-align:left;'>
              <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
                <a href="/artificial-intelligence-for-managers-leaders" class="ct-lg-linkUpdate ct-font-link-16" target="_blank">
                  <b>AI for Managers and Leaders</b>
                </a>
              </p>
          </li>
        </ul>
        <p class="counrse-contn-1 ct-sm-left">
          Both learning tracks are power-packed and offer a crash course in Generative AI, covering everything from foundational concepts to advanced applications, industry use cases, and actionable strategies for real-world impact.
        </p>






        <div class='row'>
        <div class="mb-3 text-center tablecover">
            <table style="border-collapse: collapse; border: 1px solid rgb(0, 0, 0); padding: 10px 20px;">
                <tbody>
                    <tr>
                        <td style="border-right: 1px solid rgb(0, 0, 0); border-bottom: 1px solid rgb(28, 69, 135); padding: 10px 18px; background-color: rgb(28, 69, 135); width: 19.8402%; vertical-align: middle;">
                            <span style="color: rgb(28, 69, 142);"><br /></span>
                        </td>
                        <td style="border: 1px solid rgb(0, 0, 0); padding: 10px 18px; background-color: rgb(28, 69, 135); vertical-align: middle;">
                            <p style="text-align: center; line-height: 1.15; margin: 8px 0;">
                                <span style="color: rgb(255, 255, 255);">
                                    <strong> GenAI for Tech Professionals </strong>
                                </span>
                            </p>
                        </td>
                        <td style="border: 1px solid rgb(0, 0, 0); padding: 10px 18px; background-color: rgb(28, 69, 135); vertical-align: middle;">
                            <p style="text-align: center; line-height: 1.15; margin: 8px 0;">
                                <span style="color: rgb(255, 255, 255);">
                                    <strong> AI for Managers & Leaders </strong>
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td style="border: 1px solid rgb(0, 0, 0); padding: 10px 18px; background-color: rgb(28, 69, 135); width: 19.8402%; vertical-align: middle;">
                            <p style="text-align: center; line-height: 1.15; margin: 8px 0;">
                                <span style="color: rgb(255, 255, 255);">
                                    <strong>Duration</strong>
                                </span>
                            </p>
                        </td>
                        <td style="border: 1px solid rgb(0, 0, 0); padding: 10px 18px; background-color: rgb(207, 226, 243); width: 45%; vertical-align: middle;">
                            <p style="text-align: center; line-height: 1.15; margin: 8px 0;">
                                <span style="color: rgb(28, 69, 142);">
                                    18 weeks
                                </span>
                            </p>
                        </td>
                        <td style="border: 1px solid rgb(0, 0, 0); padding: 10px 18px; background-color: rgb(207, 226, 243); vertical-align: middle;">
                            <p style="text-align: center; line-height: 1.15; margin: 8px 0;">
                                <span style="color: rgb(28, 69, 142);">
                                    12 weeks
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td style="border: 1px solid rgb(0, 0, 0); padding: 10px 18px; width: 19.8402%; background-color: rgb(28, 69, 135); vertical-align: middle;">
                            <p style="text-align: center; line-height: 1.15; margin: 8px 0;">
                                <span style="color: rgb(255, 255, 255);">
                                    <strong>Delivery Mode</strong>
                                </span>
                            </p>
                        </td>
                        <td style="border: 1px solid rgb(0, 0, 0); padding: 10px 18px; width: 45%; background-color: rgb(207, 226, 243); vertical-align: middle;">
                            <p style="text-align: center; line-height: 1.15; margin: 8px 0;">
                                <span style="color: rgb(28, 69, 142);">
                                    Live Online
                                </span>
                            </p>
                        </td>
                        <td style="border: 1px solid rgb(0, 0, 0); padding: 10px 18px; background-color: rgb(207, 226, 243); vertical-align: middle;">
                            <p style="text-align: center; line-height: 1.15; margin: 8px 0;">
                                <span style="color: rgb(28, 69, 142);">
                                    Live Online
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td style="border: 1px solid rgb(0, 0, 0); padding: 10px 18px; width: 19.8402%; background-color: rgb(28, 69, 135); vertical-align: middle;">
                            <p style="text-align: center; line-height: 1.15; margin: 8px 0;">
                                <span style="color: rgb(255, 255, 255);">
                                    <strong>Fees</strong>
                                </span>
                            </p>
                        </td>
                        <td style="border: 1px solid rgb(0, 0, 0); padding: 10px 18px; width: 45%; background-color: rgb(207, 226, 243); vertical-align: middle;">
                            <p style="text-align: center; line-height: 1.15; margin: 8px 0;">
                                <span style="color: rgb(28, 69, 142);">
                                    INR 32,000 + GST
                                </span>
                            </p>
                        </td>
                        <td style="border: 1px solid rgb(0, 0, 0); padding: 10px 18px; background-color: rgb(207, 226, 243); vertical-align: middle;">
                            <p style="text-align: center; line-height: 1.15; margin: 8px 0;">
                                <span style="color: rgb(28, 69, 142);">
                                    INR 28,000 + GST
                                </span>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        </div>











      `,
    hoursCount: "500",
    classCount: "46",
    classHours: "+ 21 hrs e-learning",
    projectCount: "44",
    ratingValue: 4.73,
    bestRating: 5,
    worstRating: 2,
    ratingCount: 2680,
    metaTitle: "Generative AI Course | Master GenAI Skills with AnalytixLabs",
    metaDescription:
      "Analytixlabs offers a comprehensive Generative AI course, covering both theoretical and practical applications. Learn from industry experts and develop in-demand skills. Enroll now!",
    metaKeywords: "",
    selfStudyHoursCount: "(2-4 hrs/ week)",
    classCountSubline: "",
    hoursPerClassCount: "18 x 3 = 51 hrs",
    readinessProgramClassCount: "27",
    readinessProgramSubline: "(Post Certification)",
    theme: "Default",
    isScholarship: 1,
    CourseLearningModes: {
      amountINR: 48000,
      maxAmountINR: 55000
    },
    taxPercentage: "18",
    LMSCourse: "ckeq05bmi00bgtqjl1i4l0h4e"
  },
  courseCapstoneRecord: {
    courseID: 73,
    capstoneProjects: null,
    capstoneProjectsDescription:
      "Our artificial intelligence courses in India include multiple case studies and assignments for self-study and hands-on skills, in addition to multiple case studies.",
    CourseCapstoneProjects: [
      {
        capstoneProjectID: 3070,
        CapstoneProject: {
          capstoneProjectname:
            "Image classification for inventory management (AI DL)"
        }
      },
      {
        capstoneProjectID: 3071,
        CapstoneProject: {
          capstoneProjectname:
            "Machine Language Translation (French to English & Hindi to English)"
        }
      },
      {
        capstoneProjectID: 3072,
        CapstoneProject: {
          capstoneProjectname: "Stock price forecasting (Deep Learning)"
        }
      },
      {
        capstoneProjectID: 3073,
        CapstoneProject: {
          capstoneProjectname: "Image classification project (AI DL)"
        }
      },
      {
        capstoneProjectID: 3074,
        CapstoneProject: {
          capstoneProjectname: "Credit Card fraud detection (Deep Learning)"
        }
      },
      {
        capstoneProjectID: 3075,
        CapstoneProject: {
          capstoneProjectname: "Automated image captioning (AI DL)"
        }
      },
      {
        capstoneProjectID: 3076,
        CapstoneProject: {
          capstoneProjectname: "Age detection using images (AI DL)"
        }
      },
      {
        capstoneProjectID: 3077,
        CapstoneProject: {
          capstoneProjectname: "Toxic comments classification (Deep Learning)"
        }
      },
      {
        capstoneProjectID: 3078,
        CapstoneProject: {
          capstoneProjectname:
            "Image classification & localization (Using YOLO)"
        }
      },
      {
        capstoneProjectID: 3079,
        CapstoneProject: {
          capstoneProjectname:
            "Network intrusion detection (Supervised machine learning)"
        }
      },
      {
        capstoneProjectID: 3080,
        CapstoneProject: {
          capstoneProjectname: "Online job posting analysis (Text mining & NLP)"
        }
      },
      {
        capstoneProjectID: 3081,
        CapstoneProject: {
          capstoneProjectname:
            "Banking customer cluster (Unsupervised machine learning)"
        }
      },
      {
        capstoneProjectID: 3082,
        CapstoneProject: {
          capstoneProjectname:
            "Retail chain sales forecasting (multivariate time series)"
        }
      },
      {
        capstoneProjectID: 3083,
        CapstoneProject: {
          capstoneProjectname:
            "Predicting credit card spend (regression methods)"
        }
      },
      {
        capstoneProjectID: 3084,
        CapstoneProject: {
          capstoneProjectname: "E-commerce recommendation engine (MBA)"
        }
      },
      {
        capstoneProjectID: 3085,
        CapstoneProject: {
          capstoneProjectname: "Chatbot creation (Seq2seq Deep Learning)"
        }
      },
      {
        capstoneProjectID: 3086,
        CapstoneProject: {
          capstoneProjectname: "Bank reviews analysis (Text mining & NLP)"
        }
      }
    ],
    CourseAssignments: [
      {
        courseAssignmentID: 1893,
        Assignment: {
          name: "10 short exercises on NumPy & Pandas"
        }
      },
      {
        courseAssignmentID: 1894,
        Assignment: {
          name:
            "4 case studies on Pandas for data munging, descriptive & visual analysis"
        }
      },
      {
        courseAssignmentID: 1895,
        Assignment: {
          name: "3 Basic exercises (Excel, SQL & Tableau)"
        }
      },
      {
        courseAssignmentID: 1896,
        Assignment: {
          name: "1 Assignment on basic Python"
        }
      },
      {
        courseAssignmentID: 1897,
        Assignment: {
          name: "2 exercises for statistical analysis"
        }
      }
    ]
  },
  similarCourseRecords: [
    {
      courseID: 60,
      name: "Data Science using R",
      shortDescription:
        "Best R training for industry relevant Advanced Analytics and Machine Learning skills!",
      robotImage: "image/course/thumbnail-1616501648.svg",
      courseDetailsImage: "image/course/data-science-using-R-1657623295981.png",
      slug: "data-science-r-analytics-training"
    },
    {
      courseID: 63,
      name: "Data Science using Python",
      shortDescription:
        "Best Python Data Science Course with Industry-Certified Excellence!",
      robotImage: "image/course/thumbnail-1616501891.svg",
      courseDetailsImage:
        "image/course/iitg-web-icon-logo-1714134463298-1714199689299.png",
      slug: "data-science-using-python"
    },
    {
      courseID: 64,
      name: "Business Analytics 360",
      shortDescription:
        "Dynamic Business Analytics Course with Job-Focused Certification!",
      robotImage: "image/course/dva-banners-creatives-alabs-04 copy.svg",
      courseDetailsImage: "image/course/iitg-web-icon-logo-1714199891639.png",
      slug: "business-analytics-data-science-course"
    }
  ],
  paymentKeys: {
    payumoneyKey: "qsxM7i",
    payumoneySalt: "A5v0ZQIJ",
    accountType: "nonLLP"
  },
  callUs: {
    number: "9555219007",
    country_code: "+91",
    start_time: "10:00:00",
    end_time: "19:00:00"
  },
  landingCallUs: {
    number: "9555525908",
    country_code: "+91",
    start_time: "10:00:00",
    end_time: "19:00:00"
  },
  careerPath: {
    CareerPathHtml: `
      <p class="counrse-contn-1 ct-sm-left">We recognize that career paths are unique and dynamic, shaped by individual interests, strengths, and opportunities. Our programs help you explore various career trajectories, including: </p>


        <ul class="cours-ovr-ul">
          <li class="blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                  <span class='ct-color-dark-blue'>
                    Industry Specialization: 
                  </span>
              </b>Gain expertise in high-demand sectors like banking, finance, retail, healthcare, or hospitality to stand out as a domain expert.
            </p>
          </li>
          <li class="drc-blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                  <span class='ct-color-dark-blue'>
                    Entrepreneurship: 
                  </span>
              </b>Leverage your AI and analytics knowledge to launch your own venture.
            </p>
          </li>
          <li class="blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                  <span class='ct-color-dark-blue'>
                    Advanced Academic Pursuits: 
                  </span>
              </b>Consider higher education or research opportunities to deepen your understanding and contribute to advancements in the field.
            </p>
          </li>
        </ul> 
    <p class="counrse-contn-1 ct-sm-left">Transform your career with AnalytixLabs—your future in AI starts here!</p>
    <p class="counrse-contn-1 ct-sm-left">Bonus: If you are enrolled for our signature certificate courses of 6 months or 1 year, you can get your course fees adjusted for these short courses. The amount you have already paid for the first course will be deducted when you enroll for a new course at AnalytixLabs. However, we encourage you to finish all your courses, including projects and assignments.</p>
    `
  },
  learningBenefits: {
    learningBenefitsHTML: `
    <div>
    <p class="counrse-contn-1 ct-sm-left">Engage in an intuitive and hands-on learning experience. Work on relevant projects under the guidance of industry experts. Experience our no-code approach to AI for managers course. Skip the coding or programming struggles and focus on learning to deliver results with AI. </p>
    <ol class="ct-number-ol-date">
          <li class='mb-4'>
            <h3 class='ct-heading-fs-color'>
              Democratize AI for Strategic Decision Making
            </h3>
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4 ct-ft-weight-small">
              By understanding the core concepts of AI without the need for coding, you will become an active participant in AI-driven decision-making.
            </p>
          </li>
          <li class='mb-4'>
            <h3 class='ct-heading-fs-color'>
              Enhance Operational Efficiency and Productivity
            </h3>
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4 ct-ft-weight-small">
              The course will equip you with the knowledge to streamline operations, automate routine tasks, and optimize resource allocation using AI tools.
            </p>
          </li>
          <li class='mb-4'>
            <h3 class='ct-heading-fs-color'>
              Cultivate a Data-Driven Culture
            </h3>
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4 ct-ft-weight-small">
              Understanding how AI leverages data is crucial for fostering a data-driven culture. As Managers and leaders, you will learn to harness data insights to make evidence-based decisions, identify trends, and anticipate market changes.
            </p>
          </li>
          <li class='mb-4'>
            <h3 class='ct-heading-fs-color'>
              Develop an Agile Business Mindset
            </h3>
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4 ct-ft-weight-small">
              The course will emphasize the role of AI in driving business agility. You will learn to adapt to changing market conditions, experiment with new AI-powered strategies, and create a more responsive organization.
            </p>
          </li>
          <li class='mb-4'>
            <h3 class='ct-heading-fs-color'>
              Build a Competitive Advantage
            </h3>
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4 ct-ft-weight-small">
              Managers and leaders can position their organizations at the forefront of innovation by mastering AI. With this certification course, you will learn to identify new business opportunities, develop AI-powered products and services, and have a competitive edge in the market.
            </p>
          </li>
    </ol>
    `
  }
};

export const GenerativeAICourseCareerStory = `<p class="counrse-contn-1 ct-sm-left">Numerous success stories from our program showcase remarkable achievements by graduates in their respective fields. These individuals have effectively translated their learning into tangible accomplishments, from impactful projects to securing high-level positions in leading organizations.</p>
<p class="counrse-contn-1 ct-sm-left">

The underlying theme in these success stories is the practical application of knowledge acquired through experiential learning, a core focus of our course. Experiential learning prioritizes hands-on experiences, real-world projects, and practical applications.</p>
<p class="counrse-contn-1 ct-sm-left">

Experiential Learning fosters active engagement, critical thinking, and reflection and equips learners with practical skills and real-world insights beyond conventional classroom instruction. Active participation in activities, simulations, projects, or internships enables a deep comprehension of concepts, develops advanced problem-solving abilities, and establishes a solid, applicable knowledge base.</p>
<p class="counrse-contn-1 ct-sm-left">

This approach empowers learners to absorb and engage with information, fostering a profound understanding and setting the stage for meaningful career accomplishments.
</p>
`;

export const feeDetailsGenerativeAICourse =
  "Contrary to popular belief, our Generative AI for Business certification course offers exceptional value. Fees are competitively priced and aligned with industry standards, making AI education accessible to all.";

export const feeDetailsGenerativeAICourseSecondLine =
  "Invest in your future with AnalytixLabs, where mastery begins at just INR xxx + taxes.";

export const EMITextGenerativeAICourse = "INR 28,000*/-";
export const EMITextGenerativeAICourseSpamText = "onwards";

export const feesDetailsGenerativeAI = {
  amount: EMITextGenerativeAICourse,
  text: EMITextGenerativeAICourseSpamText
};

export const CareerSupportGenerativeAICourseHTML = `
  <p class="counrse-contn-1 ct-sm-left">
    AnalytixLabs is committed to helping you launch your career successfully. Our default career guidance program focuses on developing job-relevant skills. You'll receive expert guidance on resume building, interview preparation, and how to apply for relevant job roles.
  </p>
  <ul class="cours-ovr-ul">
    <li class="drc-blcr" style='list-style:none; text-align:left;'>
      <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
        <strong>Profile Optimization: </strong>Tailored guidance to update your professional profile, aligning it with industry expectations and showcasing your strengths.
      </p>
    </li>
    <li class="drc-blcr" style='list-style:none; text-align:left;'>
      <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
        <strong>Role Targeting: </strong>Personalized advice on identifying suitable job roles based on your background, skills, and career aspirations.
      </p>
    </li>
    <li class="drc-blcr" style='list-style:none; text-align:left;'>
      <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
        <strong>Skill Development: </strong>Building job-relevant skills that employers value through hands-on training.
      </p>
    </li>
    <li class="drc-blcr" style='list-style:none; text-align:left;'>
      <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
        <strong>Expert Assistance: </strong>Insights and tips for crafting impactful resumes, acing interviews, and applying for the right opportunities.
      </p>
    </li>
  </ul>
`;

export const jobRolesSkillsGenerativeAiHTML = `
    <table class="custom-table">
      <tr>
        <th>Course Name</th>
        <th>Price</th>
      </tr>
      <tr>
        <td>Generative AI Course for Developers</td>
        <td class="price">INR 32,000 + GST</td>
      </tr>
    </table>
    <h3 class="date-ttl mb-2 ct-capitalize mt-4 ct-sm-left">Admission Process</h3>
    <p class="counrse-contn-1 ct-sm-left">
      We implement a personalized enrollment process for all our courses. To enroll in our machine learning and AI courses, you must express your interest through our website, email, chat, or phone. Once we receive your interest in enrolling in our artificial intelligence certification course, one of our learning advisors will contact you within 24 to 48 hours.</p><p class="counrse-contn-1 ct-sm-left">

      Our learning advisor will help you understand the industry relevance of our course and assess whether this specific course best aligns with your profile. If our advisor determines that your educational and professional background may be better suited for other courses, they will guide alternative options. Additionally, our advisor will assess your preferred location, upcoming course batches, and learning preferences.</p><p class="counrse-contn-1 ct-sm-left">

      We aim to ensure that you derive the maximum benefit from our courses, which requires us to understand your goals and expectations from our learning materials. Our learning advisors will provide comprehensive guidance on course details and job prospects and evaluate both your technical and non-technical knowledge, location, and preferred learning methods to tailor the best solution for you.</p><p class="counrse-contn-1 ct-sm-left">

      By paying the admission fee, as agreed upon with your learning advisor, you can secure your spot in the course at your chosen location and upcoming batch date.
      </p>

      
`;

export const idealCareerPathGenAITitle =
  "Career Growth Trajectory in Generative AI";
export const idealCareerPathGenAIDescription = `
  <p class="counrse-contn-1 ct-sm-left">
    The growth trajectory in Generative AI typically unfolds as follows:</p><p class="counrse-contn-1 ct-sm-left">

    1. Start as a Data Scientist, Machine Learning Specialist, or AI Specialist.</p><p class="counrse-contn-1 ct-sm-left">
    2. Advance by mastering deep learning and neural network frameworks.</p><p class="counrse-contn-1 ct-sm-left">
    3. Transition into specialized roles like Generative AI Engineer.</p><p class="counrse-contn-1 ct-sm-left">


    As a Generative AI Engineer, your responsibilities include: </p>

    <ul class="cours-ovr-ul">
      <li class="drc-blcr" style='list-style:none; text-align:left;'>
        <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
          Building and fine-tuning AI models.
        </p>
      </li>
      <li class="blcr" style='list-style:none; text-align:left;'>
        <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
          Preparing and managing data for analysis.
        </p>
      </li>
      <li class="drc-blcr" style='list-style:none; text-align:left;'>
        <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
          Implementing machine learning algorithms to create end-to-end AI solutions.
        </p>
      </li>
    </ul>

    <p class="counrse-contn-1 ct-sm-left">
      As a Generative AI Engineer, your responsibilities include: 
    </p>

    <ul class="cours-ovr-ul">
      <li class="drc-blcr" style='list-style:none; text-align:left;'>
        <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
          Junior Generative AI Engineer
        </p>
      </li>
      <li class="blcr" style='list-style:none; text-align:left;'>
        <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
          Mid-Level Generative AI Engineer
        </p>
      </li>
      <li class="drc-blcr" style='list-style:none; text-align:left;'>
        <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
          Senior Generative AI Engineer
        </p>
      </li>
      <li class="blcr" style='list-style:none; text-align:left;'>
        <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
          Generative AI Researcher or Product Specialist
        </p>
      </li>
      <li class="drc-blcr" style='list-style:none; text-align:left;'>
        <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
          Director of AI
        </p>
      </li>
    </ul>


`;

export const curriculamMobileViewGenAIDescription = `
        <div class="mb-5">
          <div class="terms-outer info-header-outer box-sticky ct-terms-outer">
            <div class="row">
              <div class="col-md-12 ">
                <span class="term-title-pg-diploma font-bold">
                  Orientation Session
                </span>
              </div>
            </div>
          </div>
          <div class="p-0 mt-3">
            <div class="terms-outer ct-terms-outer">
              <div class="row">
                <div class="col-md-12">
                  <span class="term-title-pg-diploma font-bold">
                    I. Introduction to AI Revolution
                  </span>
                </div>
              </div>
            </div>
            <div class="term-info-outer">
              <div class="term-details-outer ct-term-details-outer">
                <div class="ct-term-details-inner">
                  <div class="row">
                    <div class="col-md-12  mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        Understand the course objectives,
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div class="p-0 mt-3">
            <div class="terms-outer ct-terms-outer">
              <div class="row">
                <div class="col-md-12">
                  <span class="term-title-pg-diploma font-bold">
                    II. What is Artificial Intelligence (AI)
                  </span>
                </div>
              </div>
            </div>
            <div class="term-info-outer">
              <div class="term-details-outer ct-term-details-outer">
                <div class="ct-term-details-inner">
                  <div class="row">
                    <div class="col-md-12 mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        Understand where and how data science, machine learning,
                        and AI intersect.
                      </p>
                    </div>
                  </div>
                  <div class="term-details-border-b mb-2"></div>
                  <div class="row">
                    <div class="col-md-12 mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        Learn how AI is driving business decisions.
                      </p>
                    </div>
                  </div>
                  <div class="term-details-border-b mb-2"></div>
                  <div class="row">
                    <div class="col-md-12 mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        Explore current trends and tools.
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div class="p-0 mt-3">
            <div class="terms-outer ct-terms-outer">
              <div class="row">
                <div class="col-md-12">
                  <span class="term-title-pg-diploma font-bold">
                    III. What is Generative Artificial Intelligence (GenAI)
                  </span>
                </div>
              </div>
            </div>
            <div class="term-info-outer">
              <div class="term-details-outer ct-term-details-outer">
                <div class="ct-term-details-inner">
                  <div class="row">
                    <div class="col-md-12 mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        Explore the concept and working of GenAI across multiple
                        use cases.
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-5 mb-5">
          <div class="terms-outer info-header-outer box-sticky ct-terms-outer">
            <div class="row">
              <div class="col-md-12 ">
                <span class="term-title-pg-diploma font-bold">
                  Generative AI for Everyone (Building Blocks) - Part I
                </span>
              </div>
            </div>
          </div>
          <div class="p-0 mt-3">
            <div class="terms-outer ct-terms-outer">
              <div class="row">
                <div class="col-md-12">
                  <span class="term-title-pg-diploma font-bold">
                    I. Introduction to AI and GenAI
                  </span>
                </div>
              </div>
            </div>
            <div class="term-info-outer">
              <div class="term-details-outer ct-term-details-outer">
                <div class="ct-term-details-inner">
                  <div class="row">
                    <div class="col-md-12  mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        Learn about the evolution, working, and applications of
                        Large Language Models (LLMs)
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div class="p-0 mt-3">
            <div class="terms-outer ct-terms-outer">
              <div class="row">
                <div class="col-md-12">
                  <span class="term-title-pg-diploma font-bold">
                    II. Introduction to ChatGPT interface
                  </span>
                </div>
              </div>
            </div>
            <div class="term-info-outer">
              <div class="term-details-outer ct-term-details-outer">
                <div class="ct-term-details-inner">
                  <div class="row">
                    <div class="col-md-12  mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        Explore ChatGPT interface across multiple versions
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div class="p-0 mt-3">
            <div class="terms-outer ct-terms-outer">
              <div class="row">
                <div class="col-md-12">
                  <span class="term-title-pg-diploma font-bold">
                    III. Introduction to Prompting Techniques
                  </span>
                </div>
              </div>
            </div>
            <div class="term-info-outer">
              <div class="term-details-outer ct-term-details-outer">
                <div class="ct-term-details-inner">
                  <div class="row">
                    <div class="col-md-12  mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        Get familiar with prompting techniques like generating
                        desired outcomes, assigning role/character, reverse
                        prompting, chain of thought, and more
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div class="p-0 mt-3">
            <div class="terms-outer ct-terms-outer">
              <div class="row">
                <div class="col-md-12">
                  <span class="term-title-pg-diploma font-bold">
                    IV. ChatGPT use cases
                  </span>
                </div>
              </div>
            </div>
            <div class="term-info-outer">
              <div class="term-details-outer ct-term-details-outer">
                <div class="ct-term-details-inner">
                  <div class="row">
                    <div class="col-md-12  mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        Experiment with various ChatGPT use cases like making
                        presentations in Google Slides, automating tasks on
                        Google Sheets, and more.
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-5 mb-5">
          <div class="terms-outer info-header-outer box-sticky ct-terms-outer">
            <div class="row">
              <div class="col-md-12 ">
                <span class="term-title-pg-diploma font-bold">
                  GenAI for Business Applications - Part 01
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 ">
              <p class="counrse-contn-1 mb-1 p-1 pt-3">
                All modules include real-world use cases and hands-on exercises
                to experiment and implement the module’s learnings under expert
                guidance.
              </p>
            </div>
          </div>
          <div class="p-0 mt-3">
            <div class="terms-outer ct-terms-outer">
              <div class="row">
                <div class="col-md-12">
                  <span class="term-title-pg-diploma font-bold">
                    I. Unveiling AI and GenAI - Trends, Tools, and Applications
                  </span>
                </div>
              </div>
            </div>
            <div class="term-info-outer">
              <div class="term-details-outer ct-term-details-outer">
                <div class="ct-term-details-inner">
                  <div class="row">
                    <div class="col-md-12  mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        GenAI and its applications across multiple domains like
                        healthcare, banking and finance, retail and eCommerce,
                        manufacturing and industry, and more.
                      </p>
                    </div>
                  </div>
                  <div class="term-details-border-b"></div>
                  <div class="row">
                    <div class="col-md-12 mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        <b>AI-Productivity tools:</b> Jasper AI, Synthesia AI,
                        Grammarly.
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div class="p-0 mt-3">
            <div class="terms-outer ct-terms-outer">
              <div class="row">
                <div class="col-md-12">
                  <span class="term-title-pg-diploma font-bold">
                    II. Machine learning for business problem-solving
                  </span>
                </div>
              </div>
            </div>
            <div class="term-info-outer">
              <div class="term-details-outer ct-term-details-outer">
                <div class="ct-term-details-inner">
                  <div class="row">
                    <div class="col-md-12  mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        Understand machine learning concepts and apply them to
                        solve simple to complex business problems across sectors
                        like retail and manufacturing.
                      </p>
                    </div>
                  </div>
                  <div class="term-details-border-b"></div>
                  <div class="row">
                    <div class="col-md-12 mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        <b>ML tools for business: </b> DataRobot,Teachable
                        Machines by Google
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div class="p-0 mt-3">
            <div class="terms-outer ct-terms-outer">
              <div class="row">
                <div class="col-md-12">
                  <span class="term-title-pg-diploma font-bold">
                    III. Ethical and responsible AI
                  </span>
                </div>
              </div>
            </div>
            <div class="term-info-outer">
              <div class="term-details-outer ct-term-details-outer">
                <div class="ct-term-details-inner">
                  <div class="row">
                    <div class="col-md-12  mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        Learn about AI ethics, explore challenges like bias and
                        fairness, and learn to apply them into segments like HR
                        and healthcare.
                      </p>
                    </div>
                  </div>
                  <div class="term-details-border-b"></div>
                  <div class="row">
                    <div class="col-md-12 mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        <b>Ethical AI tools: </b> AI Fairness 360, Watson OpenScale.
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div class="p-0 mt-3">
            <div class="terms-outer ct-terms-outer">
              <div class="row">
                <div class="col-md-12">
                  <span class="term-title-pg-diploma font-bold">
                    IV. Mastering prompt engineering for business applications
                  </span>
                </div>
              </div>
            </div>
            <div class="term-info-outer">
              <div class="term-details-outer ct-term-details-outer">
                <div class="ct-term-details-inner">
                  <div class="row">
                    <div class="col-md-12  mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        Learn about prompt engineering, its techniques, and
                        application in legal services and finances.
                      </p>
                    </div>
                  </div>
                  <div class="term-details-border-b"></div>
                  <div class="row">
                    <div class="col-md-12 mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        <b>Prompt engineering tools: </b> OpenAI Playground,
                        Cohere AI.
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div class="p-0 mt-3">
            <div class="terms-outer ct-terms-outer">
              <div class="row">
                <div class="col-md-12">
                  <span class="term-title-pg-diploma font-bold">
                    V. Driving AI success: Strategic adoption and Team
                    empowerment
                  </span>
                </div>
              </div>
            </div>
            <div class="term-info-outer">
              <div class="term-details-outer ct-term-details-outer">
                <div class="ct-term-details-inner">
                  <div class="row">
                    <div class="col-md-12  mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        Learn and explore the AI project lifecycle stages.
                      </p>
                    </div>
                  </div>
                  <div class="term-details-border-b"></div>
                  <div class="row">
                    <div class="col-md-12 mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        Design optimal AI structuring within an organization and
                        identify the roles and skills required by AI teams.
                      </p>
                    </div>
                  </div>
                  <div class="term-details-border-b"></div>
                  <div class="row">
                    <div class="col-md-12 mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        Learn to adapt agile methodologies for end-to-end AI
                        project streamlining and completion within the ethical
                        AI boundaries
                      </p>
                    </div>
                  </div>
                  <div class="term-details-border-b"></div>
                  <div class="row">
                    <div class="col-md-12 mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        <b>AI-PM tools: </b> Asana, Trello, Scaling AI
                        initiatives, Evaluating AI impact.
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div class="p-0 mt-3">
            <div class="terms-outer ct-terms-outer">
              <div class="row">
                <div class="col-md-12">
                  <span class="term-title-pg-diploma font-bold">
                    VI. Harnessing cloud solutions
                  </span>
                </div>
              </div>
            </div>
            <div class="term-info-outer">
              <div class="term-details-outer ct-term-details-outer">
                <div class="ct-term-details-inner">
                  <div class="row">
                    <div class="col-md-12  mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        Learn about cloud AI and its application in healthcare
                        and retail
                      </p>
                    </div>
                  </div>
                  <div class="term-details-border-b"></div>
                  <div class="row">
                    <div class="col-md-12 mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        <b>Cloud AI tools: </b> AWS SageMaker, Azure AI.
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div class="p-0 mt-3">
            <div class="terms-outer ct-terms-outer">
              <div class="row">
                <div class="col-md-12">
                  <span class="term-title-pg-diploma font-bold">
                    VII. Leveraging OpenSource AI for Enterprise Solutions
                  </span>
                </div>
              </div>
            </div>
            <div class="term-info-outer">
              <div class="term-details-outer ct-term-details-outer">
                <div class="ct-term-details-inner">
                  <div class="row">
                    <div class="col-md-12  mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        Focus on enterprise-specific challenges and learn how
                        opensource AI solutions can personalize and enhance AI
                        performance in sectors like education and automotive
                      </p>
                    </div>
                  </div>
                  <div class="term-details-border-b"></div>
                  <div class="row">
                    <div class="col-md-12 mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        <b>OpenSource AI tools: </b> Hugging Face,TensorFlow.
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div class="p-0 mt-3">
            <div class="terms-outer ct-terms-outer">
              <div class="row">
                <div class="col-md-12">
                  <span class="term-title-pg-diploma font-bold">
                    VIII. Building Chatbots and Retrieval-Augmented Generatives
                    (RAG)
                  </span>
                </div>
              </div>
            </div>
            <div class="term-info-outer">
              <div class="term-details-outer ct-term-details-outer">
                <div class="ct-term-details-inner">
                  <div class="row">
                    <div class="col-md-12  mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        Learn how RAG enhances chatbots in fetching and
                        integrating external knowledge
                      </p>
                    </div>
                  </div>
                  <div class="term-details-border-b"></div>
                  <div class="row">
                    <div class="col-md-12 mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        <b>Chatbot tools: </b> Dialogflow, Rasa
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div class="p-0 mt-3">
            <div class="terms-outer ct-terms-outer">
              <div class="row">
                <div class="col-md-12">
                  <span class="term-title-pg-diploma font-bold">
                    IX. AI in IT Operations (AIOps)
                  </span>
                </div>
              </div>
            </div>
            <div class="term-info-outer">
              <div class="term-details-outer ct-term-details-outer">
                <div class="ct-term-details-inner">
                  <div class="row">
                    <div class="col-md-12  mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        Explore how AI is transforming IT operations through
                        predictive analytics, root cause analysis, and automated
                        monitoring
                      </p>
                    </div>
                  </div>
                  <div class="term-details-border-b"></div>
                  <div class="row">
                    <div class="col-md-12 mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        <b>AIOps Tools: </b> Moogsoft, Dynatrace.
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div class="p-0 mt-3">
            <div class="terms-outer ct-terms-outer">
              <div class="row">
                <div class="col-md-12">
                  <span class="term-title-pg-diploma font-bold">
                    X. AI in Business Operations
                  </span>
                </div>
              </div>
            </div>
            <div class="term-info-outer">
              <div class="term-details-outer ct-term-details-outer">
                <div class="ct-term-details-inner">
                  <div class="row">
                    <div class="col-md-12  mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        Learn how AI wields the power to optimize and automate
                        various business operations like process management and
                        decision-making efficiently
                      </p>
                    </div>
                  </div>
                  <div class="term-details-border-b"></div>
                  <div class="row">
                    <div class="col-md-12 mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        <b>Business Operations tools: </b> UiPath, Workday.
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-5 mb-5">
          <div class="terms-outer info-header-outer box-sticky ct-terms-outer">
            <div class="row">
              <div class="col-md-12 ">
                <span class="term-title-pg-diploma font-bold">
                  Capstone Project
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 ">
              <p class="counrse-contn-1 mb-1 p-1 pt-3">
                You will apply all your learnings to this industry-relevant
                Capstone Project. What you will do:
              </p>
            </div>
          </div>
          <div class="p-0 mt-3">
            <div class="terms-outer ct-terms-outer">
              <div class="row">
                <div class="col-md-12">
                  <span class="term-title-pg-diploma font-bold">
                    Industry-relevant Capstone Project
                  </span>
                </div>
              </div>
            </div>
            <div class="term-info-outer">
              <div class="term-details-outer ct-term-details-outer">
                <div class="ct-term-details-inner">
                  <div class="row">
                    <div class="col-md-12  mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        Develop a comprehensive business strategy for your
                        organization
                      </p>
                    </div>
                  </div>
                  <div class="term-details-border-b"></div>
                  <div class="row">
                    <div class="col-md-12  mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        Integrate AI applications
                      </p>
                    </div>
                  </div>
                  <div class="term-details-border-b"></div>
                  <div class="row">
                    <div class="col-md-12  mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">Stick to AI ethics</p>
                    </div>
                  </div>
                  <div class="term-details-border-b"></div>
                  <div class="row">
                    <div class="col-md-12  mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        Implement AI cloud solutions
                      </p>
                    </div>
                  </div>
                  <div class="term-details-border-b"></div>
                  <div class="row">
                    <div class="col-md-12  mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        Streamline end-to-end functioning of the project,
                        applying project management principles.
                      </p>
                    </div>
                  </div>
                  <div class="term-details-border-b"></div>
                  <div class="row">
                    <div class="col-md-12  mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        Present your AI strategy and receive constructive
                        feedback on feasibility, scalability, and
                        implementation.
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-5 pb-5 mb-5">
          <div class="terms-outer info-header-outer box-sticky ct-terms-outer">
            <div class="row">
              <div class="col-md-12 ">
                <span class="term-title-pg-diploma font-bold">
                  Generative AI for Everyone - Building Blocks (Part 02)
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 ">
              <p class="counrse-contn-1 mb-1 p-1 pt-3">
                This referential learning module will help you explore and
                experiment with widely acclaimed AI tools like Google Gemini and
                learn about image generation using AI.
              </p>
            </div>
          </div>
          <div class="p-0 mt-3">
            <div class="terms-outer ct-terms-outer">
              <div class="row">
                <div class="col-md-12">
                  <span class="term-title-pg-diploma font-bold">
                    I. Introduction to Google Gemini
                  </span>
                </div>
              </div>
            </div>
            <div class="term-info-outer">
              <div class="term-details-outer ct-term-details-outer">
                <div class="ct-term-details-inner">
                  <div class="row">
                    <div class="col-md-12  mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        Learn application and usage.
                      </p>
                    </div>
                  </div>
                  <div class="term-details-border-b"></div>
                  <div class="row">
                    <div class="col-md-12  mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        Learn integration with Google Workspace and Youtube
                      </p>
                    </div>
                  </div>
                  <div class="term-details-border-b"></div>
                  <div class="row">
                    <div class="col-md-12  mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        <b>Tool: </b> Google Gemini.
                      </p>
                    </div>
                  </div>
                  <div class="term-details-border-b"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-0 mt-3">
            <div class="terms-outer ct-terms-outer">
              <div class="row">
                <div class="col-md-12">
                  <span class="term-title-pg-diploma font-bold">
                    II. Introduction to Image Generation using AI
                  </span>
                </div>
              </div>
            </div>
            <div class="term-info-outer">
              <div class="term-details-outer ct-term-details-outer">
                <div class="ct-term-details-inner">
                  <div class="row">
                    <div class="col-md-12  mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        Dive in deep into the ethical responsibilities of using
                        AI for various functions like image generation from text
                        prompts
                      </p>
                    </div>
                  </div>
                  <div class="term-details-border-b"></div>
                  <div class="row">
                    <div class="col-md-12  mt-3 mb-2">
                      <p class="counrse-contn-1 mb-1">
                        <b>Tools:</b> Midjourney, DALL-E, BING images.
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>



`;

export const GenAIdeliveryImags = [
  {
    path: "/static/media/social-1.png",
    name: "Classroom & Bootcamp",
    description: `<p>Skill mastery with immersive, hands-on learning guided by mentors. Opt for either our intensive weekday bootcamps or flexible weekend classes to suit your schedule. Experience firsthand the power of in-person mentorship, available in weekday classroom bootcamps, ensuring a rich, experiential learning environment
      </p>`
  },
  {
    path: "/static/media/streamingIcon.png",
    name: "Fully Interactive Live Online",
    description: `<p>Blend the dynamic experience of traditional classroom with engaging, real-time interactive sessions, carefully tailored to meet the demand of busy schedules. This innovative approach ensures effective learning, fostering a deeper understanding and retention of knowledge.
      </p>`
  },
  {
    path: "/static/media/play-1.png",
    name: "Blended with eLearning",
    description: `<p>Fuse the rich atmosphere of classroom instruction with the flexibility and accessibility of eLearning modules, meticulously integrated to accommodate learning preferences. This unique blend ensures an optimal learning experience, empowering participants to delve into subjects deeply.
    </p>`
  }
];

export const secondDesriptionOfOverView = `        
        <p class="counrse-contn-1 ct-sm-left mt-3">
          <b><i>
            For comprehensive details about the <a href="/artificial-intelligence-for-managers-leaders" class="ct-lg-linkUpdate ct-italic" target="_blank">AI for Managers and Leaders course, please visit this page.</a>
          </i></b>
        </p>
        <h3 class="date-ttl mb-2 ct-capitalize mt-4 ">About Generative AI Education Programs at AnalytixLabs</h3>
        <p class="counrse-contn-1 ct-sm-left">The GenAI industry is experiencing a seismic shift as more businesses lock in budgets to experiment with and invest in AI solutions. Companies are looking to leverage AI’s predictive capabilities and tie them to numbers to fuel strategic business growth. To do this, skilled AI and data experts must be on the frontline. </p>
        <p class="counrse-contn-1 ct-sm-left">
          Looking for the best generative AI courses near you? 
        </p>
        <p class="counrse-contn-1 ct-sm-left">
        Our Generative AI training is designed to equip you with the knowledge and skills to be at the forefront of this revolution. You'll learn how to:</p>
          <ul class="cours-ovr-ul">
            <li class="drc-blcr" style='list-style:none; text-align:left;'>
              <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
                Understand core AI concepts and apply them across diverse industries
              </p>
            </li>
            <li class="blcr" style='list-style:none; text-align:left;'>
              <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
                Identify and evaluate potential business opportunities for AI implementation
              </p>
            </li>
            <li class="drc-blcr" style='list-style:none; text-align:left;'>
              <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
                Build AI-driven business strategies to enhance business models and decision-making
              </p>
            </li>
            <li class="blcr" style='list-style:none; text-align:left;'>
              <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
                Take the lead in executing and managing AI projects within the organization and deliver successful outcomes
              </p>
            </li>
            <li class="drc-blcr" style='list-style:none; text-align:left;'>
              <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
                Communicate AI research, strategies, and findings effectively to stakeholders and leverage its benefits to drive business value
              </p>
            </li>
          </ul>

        
        <p class="counrse-contn-1 ct-sm-left">
          Choose the learning program that fits you the most -
        </p>
        <p class="counrse-contn-1 ct-sm-left">
          We offer two specialized programs tailored for different career objectives. Choose the learning program that fits you the most:
        </p>

        <p class="counrse-contn-1 ct-sm-left">
          <span class='color-dark-blue'>
            <strong>
            1. GenAI for Tech Professionals:
            </strong>
            </span> Designed for individuals with a technical background, this program focuses on developing in-depth expertise in Generative AI.
        </p>
        <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
            <strong>Learning Objective:</strong> Build technical proficiency from foundational knowledge to hands-on implementation, enabling participants to design, develop, and deploy AI-driven solutions.
        </p>

        <p class="counrse-contn-1 ct-sm-left">
          <span class='color-dark-blue'>
            <strong>
            2. AI for Leaders& Managers:
            </strong>
            </span> Ideal for non-technical professionals, this program provides a strategic understanding of Generative AI.
        </p>
          <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
            <strong>Objective:</strong> Equip leaders to drive AI adoption, optimize business processes, and craft impactful AI-driven strategies for organizational success.
          </p>
        














        <p class="counrse-contn-1 ct-sm-left">
        Choose the learning track that best aligns with your profile. <strong>GenAI for Tech Professionals</strong> includes Python programming modules and modules that focus on implementing programming to build AI models.</p><p class="counrse-contn-1 ct-sm-left">
 
        The AI for Leaders and Managers is designed for AI thinkers to be more involved in business decision-making, automating business operations, collaborating with multi-functioning teams, and delivering strategies that will drive business growth. With zero programming involved, this course is ideal for anyone with a nontechnical background. 
        </p>


        <h3 class="date-ttl mb-2 ct-capitalize mt-4 ct-sm-left">GenAI Course Highlights</h3>
          <p class="counrse-contn-1 ct-sm-left">
            The curriculum focuses on understanding Generative AI, Business Applications of Generative AI, Developing generative AI strategy, Planning and Managing generative AI projects, Future trends of generative AI, and mastering Prompt Engineering. </p><p class="counrse-contn-1 ct-sm-left">

            You will gain hands-on experience with cutting-edge generative tools, including ChatGPT, Llama, Dall-E, Gemini, Midjourney, Stable Diffusion, and Microsoft OpenAI.</p><p class="counrse-contn-1 ct-sm-left">

            By the end of the course, you will be able to
          </p>
          <ul class="cours-ovr-ul">
            <li class="drc-blcr" style='list-style:none; text-align:left;'>
              <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
                Master the fundamentals to learn Generative AI and apply it across industries
              </p>
            </li>
            <li class="blcr" style='list-style:none; text-align:left;'>
              <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
                Develop practical skills in using Generative AI tools and platforms
              </p>
            </li>
            <li class="drc-blcr" style='list-style:none; text-align:left;'>
              <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
                Build and deploy Generative AI models to solve real-world business problems
              </p>
            </li>
            <li class="blcr" style='list-style:none; text-align:left;'>
              <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
                Evaluate the ethical and societal implications of Generative AI
              </p>
            </li>
          </ul>
         <h3 class="date-ttl mb-2 ct-capitalize mt-4 ct-sm-left">Learning Prerequisites</h3>
            <p class="counrse-contn-1 ct-sm-left">
              Our Generative AI course for professionals and developers includes learning the basics of GenAI and essential tools and understanding how GenAI can propel business growth by combining data and AI's predictive capabilities. 
            </p>
            <p class="counrse-contn-1 ct-sm-left">
              This course is ideal for anyone looking to build a career or transition into generative AI roles. You will learn to implement and leverage genAI to achieve business goals faster, manage projects better, and harness cloud AI solutions. By the end of the course, you will be a pro with GenAI tools. Prepare to experience learning through hands-on projects and assignments rooted in core business challenges that enterprises face daily.
            </p>`;
