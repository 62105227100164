import React from "react";

const CommonMobileViewTable = ({data,text,title, subTitle}) => {

  const renderCell = (cell, cellIndex) => {
    if (cell.isNestedTable) {
      return (
        <td
          key={cellIndex}
          className='common-table-cell nested-table-cell'
          style={{
            backgroundColor: cell.backgroundColor || "rgb(207, 226, 243)",
          }}
        >
          <table className='nested-table'>
            <tbody>
              {cell.nestedTableData.map((nestedRow, nestedRowIndex) => (
                <tr key={nestedRowIndex}>
                  {nestedRow.map((nestedCell, nestedCellIndex) => (
                    <td
                      key={nestedCellIndex}
                      className='common-table-cell'
                      style={{
                        backgroundColor: nestedCell.backgroundColor || "rgb(207, 226, 243)",
                        borderTop: nestedCell?.borderTop ? "1px solid rgb(0, 0, 0)" : "none",
                        borderBottom: nestedCell?.borderBottom ? "1px solid rgb(0, 0, 0)" : "none",
                        borderRight: nestedCell?.borderRight ? "1px solid rgb(0, 0, 0)" : "none",
                        borderLeft: nestedCell?.borderLeft ? "1px solid rgb(0, 0, 0)" : "none",
                      }}
                    >
                      {nestedCell.isOnlyImage ? (
                        <>
                            <img
                              alt="logo"
                              src={nestedCell.imageFile}
                              className="table-logo"
                            />
                        </>
                      ) : (
                        <>
                          <p className='m-0'>
                            <span style={{ color: nestedCell.textColor || "rgb(28, 69, 142)" }}>
                              {nestedCell.isBold ? <strong>{nestedCell.content}</strong> : nestedCell.content}
                            </span>
                          </p>
                          {!!nestedCell.subtext && <p className='m-0'>            
                            <span style={{ color: nestedCell.textColor || "rgb(28, 69, 142)", fontSize: "13px" }}>
                              {nestedCell.subtext}
                            </span></p>}
                        </>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </td>
      );
    }

    return (
      <td
        key={cellIndex}
        className='common-table-cell'
        style={{
          backgroundColor: cell.backgroundColor || "rgb(207, 226, 243)",
        }}
      >
        {cell.isOnlyImage ? (
          <div>
            <img alt="logo" src={cell.imageFile} className="table-logo" />
          </div>
        ) : (
          <>
          <p className='m-0'>
            <span style={{ color: cell.textColor || "rgb(28, 69, 142)" }}>
              {cell.isBold ? <strong>{cell.content}</strong> : cell.content}
            </span>
          </p>
          {!!cell.subtext && <p className='m-0'>            
            <span style={{ color: cell.textColor || "rgb(28, 69, 142)", fontSize: "13px" }}>
              {cell.subtext}
            </span></p>}
          </>
        )}
      </td>
    );
  };

  return (
    <section className='left-part'>
      <div className="container">
      {title &&
      <div class="title-pg-diploma mb-4"><h2>{title}</h2></div>}
            {subTitle &&
      <h3 class="date-ttl mt-3 ct-capitalize">{subTitle}</h3>}
        <div className='cover-common-table'>
          <table className='common-table'>
            <tbody>
              {data.map((row, rowIndex) => (
                <tr key={rowIndex}>{row.map((cell, cellIndex) => renderCell(cell, cellIndex))}</tr>
              ))}
            </tbody>
          </table>
        </div>
        <div class="ct-note-message-table mb-3 mt-3">{text}</div>
      </div>
    </section>
    
  );
};

export default CommonMobileViewTable;

