/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import BlueTitleWithDesc from 'components/BlueTitleWithDesc';
import React from 'react'

import TitleWithArrowDescription from '../TitleWithArrowDescription';
import contactNumber, {contactNumber10} from 'data/ppcData';
import {

  getDownloadBrochureModal,
  onCallUsClick
} from "../../../shared/function";
import phone from "assets/images/footer/phone.png";
import {useModalTypeContext} from 'context/modalTypeContext';
import download from "assets/images/svgs/download.png";
import BulletPointTitle from '../BulletPointTitle';
import MainBulletsPoint from '../Buletpointsdescription';
import CommonMobileViewTable from 'components/MobileViewTable';
import {useRouteMatch} from 'react-router-dom/cjs/react-router-dom';
import {selectCommonTableContent} from 'data/commonSataicTable';
import {certificatebelowText, pgdpcta} from 'data/pgDiplomaAnalytics';
import PGOverView from 'views/pgDiplomaAnalytics/PGOverView';

const CommonPGComponent = ({PGdetails}) => {
  const { setModalType } = useModalTypeContext()
  const route = useRouteMatch()
  return (
    PGdetails.map((item , index)=>(
      <div key={`PGDelhi-${index}`}>
    <section>
      <div>
        {(item?.title || item?.longDescription) &&
          <div className='mt-5'>
            <BlueTitleWithDesc
              info={item}
              classAddOn="menu-title-pg-diploma-analytics"
              classAddDescOn="counrse-contn-pg-diploma-analytics"
            />
          </div>
        }
        <BulletPointTitle
          title={item?.bulletsPointTitle}
          description={item?.bulletsPointDescription}
        />
        {item.bulletPoint &&
          Array.isArray(item.bulletPoint) &&
          item.bulletPoint.length > 0 &&
          item.bulletPoint.map((item,index )=>(
                <MainBulletsPoint
                key={`bulletPoint-${index}`}
                  info={item}
                  classAddOn="sub-menu-title-pg-diploma-analytics mt-3"
                  classAdddescOn="counrse-contn-pg-diploma-analytics"
                />)
          )
        }

        {Array.isArray(item.subDetails) && item.subDetails.length>0 && item.subDetails.map((data,index )=>(
          <div  key={`subDetails-${index}`}>
            <div className='mt-4'>
              <BlueTitleWithDesc
                info={data}
                classAddOn="light-blue-color menu-title-pg-diploma-analytics"
                classAddDescOn="counrse-contn-pg-diploma-analytics"
              />
            </div>
            <BulletPointTitle
              title={data?.subBulletsPointTitle}
              description={data?.subBulletsPointDescription}
            />
            {Array.isArray(data?.SubBulletPoint)
              &&  data?.SubBulletPoint?.length>0
              &&  data?.SubBulletPoint?.map((item,index)=>(
                <div className="mb-3" key={`SubBulletPoint-${index}`}>
                  <TitleWithArrowDescription info={item} classAddOn={'classAddOn'}/>
                </div>
                )
            )}
          </div>
        ))}

      </div>
    </section>
    {item?.needHelp && <section>
        <div className="container">
          <div className="row justify-content-center mt-5 mb-5">
              <div className="col-md-12 ct-help-title text-center mb-3">
                <h4 className="ct-font-40-lg">
                  <span>Need help?</span>{" "}
                  <span className="ct-span-light">Call us</span>
                </h4>
              </div>
            <div className="col-lg-4 col-md-5  mb-3 ct-width-max-min-300">
              <div className=" row ct-m-auto justify-content-around ct-lg-btn-fontsize">
                <a
                    href={`tel:+${contactNumber.otherContactNumber}`}
                    className="ft-call ct-lg-btn"
                    onClick={onCallUsClick}
                  >
                    <img
                      className="lazyload"
                      src={phone}
                      width="15"
                      height="15"
                      alt=""
                    />
                    &nbsp;&nbsp;&nbsp;{`+91 ${contactNumber10?.otherContactNumber}`}
                  </a>
              </div>
            </div>
            {!item?.notShowDownloadBrochure && <div className="col-lg-4 col-md-5 mb-3 ct-width-max-min-300">
              <div
              className=" row ct-m-auto justify-content-around ">
                <div
                  className="dnld-btn ct-lg-downloadBTN"
                >
                  <a
                    className="ctc-btn teblet-down ft-call ct-lg-btn-download"
                    data-toggle="modal"
                    data-target={!item.isShowRequestACallback? "#download_brochure" : '#contactus_form'}
                    onClick={() =>
                      setModalType(
                        getDownloadBrochureModal(
                          'downloadBrochure',
                          'downloadBrochure',
                          'downloadBrochure',
                          'downloadBrochure',
                          'ppcBingDownloadBrochure',
                          'ppcBrandDownloadBrochure',
                          'ppcGhBlrDownloadBrochure',
                          'ppcGhNcrDownloadBrochure',
                          'ppcDsBlrDownloadBrochure',
                          'downloadBrochure',
                        ),
                      )
                    }
                  >
                    <img
                      className="lazyloaded"
                      src={download}
                      width="15"
                      height="15"
                      alt=""
                    />{' '}
                       {!item.isShowRequestACallback? 'Download Brochure' : 'Request a Callback' }
                  </a>
                </div>
              </div>
            </div>}


          </div>

        </div>
      </section>}

    {item?.showCommonTable && <>
      <CommonMobileViewTable data={selectCommonTableContent(route.url).data} text={selectCommonTableContent(route.url).text} />
      <PGOverView
        ctaList={pgdpcta}
        ctaBelow={certificatebelowText}
        />
      </>}
    {item?.isShowRequestACallbackOnlyCTA && <>
        <div className="dnld-btn mt-5 mb-5">
          <a
            class="ctc-btn teblet-down ct-cta-big-yellow  "
            href="javascript:void(0);"
            data-toggle="modal"
            data-target="#contactus_form"
            aria-label="Request call back"
          >
            Check Your Eligibility
          </a>
        </div>
      </>}
    </div>))
  )
}

export default CommonPGComponent
