import ReactGA from 'react-ga4'
const BASE_URL = 'https://api.analytixlabs.co.in/'
const URL_BLOG = 'https://www.analytixlabs.co.in/blog/'
const URL = 'https://www.analytixlabs.co.in'
const Logo_URL = 'https://www.analytixlabs.co.in'
const LMS_URL = 'https://lms.analytixlabs.co.in/'
const Razorpay_Key = 'rzp_live_6JNg2DdiRl1MHt'
const domain = { domain: '.analytixlabs.co.in' }

const handleGA = () => {
  ReactGA.initialize('G-S8DQVCX660') // only need in production
  ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "analytixlabs" });// only need in production
}
const heapCode = '2336048362'
const server = 'production'

export {
  BASE_URL,
  URL_BLOG,
  URL,
  Logo_URL,
  Razorpay_Key,
  LMS_URL,
  domain,
  handleGA,
  heapCode,
  server,
}
