import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import icon from '../../../assets/images/icon-11.png'
import { useRouteMatch } from 'react-router-dom'

const LearningTrackSilde = memo(({ course, customStyle = {}}) => {
  let isLgPage =false
  const route = useRouteMatch()
  if(route.path === '/courses/lg'){
    isLgPage = true
  }
  if (!course) return <div />
  let totalINR = 0
  return (
    <div
      onClick={() => (window.location.href = isLgPage ? `/${course?.slug}/lg` :`/${course?.slug}`)}
      style={{ cursor: 'pointer' }}
    >
      <div className="item bnd-three-blk" style={customStyle}>
        <div
          className={
            'three-blk outdiv h-auto animation-box ' + course?.cssClass
          }
        >
          <h5 className="course-card-text">{course?.name}</h5>
          <span
            className="thre-rghimg"
            ref={(node) => {
              if (node) {
                node.style.setProperty('width', '200px', 'important')
              }
            }}
          >
            {course?.robotImage ? (
              <img
                className=" ls-is-cached lazyloaded"
                alt={course.name}
                height={'100%'}
                width={'100%'}
                src={course.robotImage}
              />
            ) : null}
          </span>
          <div className="innerdiv innerdiv-2 animation-inner-box">
            <ul
              className="timing"
              style={{
                width: '100%',
              }}
            >
              <li
                style={{
                  width: '100%',
                }}
              >
                <img
                  alt=""
                  className="lazyload"
                  src={icon}
                  style={{
                    display: 'inline-block',
                    width: 'auto',
                  }}
                />{' '}
                <span>
                  <b>{course?.hoursCount}</b> hours{' '}
                  {course?.yearCount && (
                    <>
                      | <b>{course?.yearCount}</b> Months
                    </>
                  )}
                  {course?.isShownSeparatelyClassCount && (
                    <>
                      | <b>{course?.isShownSeparatelyClassCount}</b>
                    </>
                  )}
                </span>{' '}
              </li>
            </ul>
            <h6 className="expand-h6">
              {course.isStatic ? 'Job-focussed skills on:' : 'Course Included:'}
            </h6>
            <div className="inner-total">
              {course.courseIncludedRecord &&
                course.courseIncludedRecord.map((item, index) => {
                  totalINR = totalINR + item.amountINR
                  return (
                    <ul className="countpay" key={'_LTrack_' + index}>
                      <li
                        className={`countpy-detal ${
                          course?.isStatic ? 'pr-0' : ''
                        }`}
                      >
                        {item?.name}{' '}
                        {item?.amountINR && (
                          <span className="pay-ttl">₹ {item?.amountINR}/-</span>
                        )}
                      </li>
                    </ul>
                  )
                })}
              {totalINR ? (
                <ul className="countpay-totl">
                  <li className="countpay-totlpay-ttl">₹ {totalINR}/-</li>
                </ul>
              ) : null}
            </div>
            {course?.isEMIShow ? 
            <div className="total-head-prc">
              ₹{course?.totalAmount}​/- EMI
                <sup>*</sup>
                <br/>
                {course?.plusTaxes && course.plusTaxes}
            </div> : 
              <>
              <div className="total-head-prc">
                  ₹{course?.totalAmount}​ 
              </div>
              </>
            }
            <Link className="totl-buton" to={isLgPage ? `/${course?.slug}/lg` :`/${course?.slug}`}>
              Learn More
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
})
export default LearningTrackSilde
