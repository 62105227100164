/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useModalTypeContext } from '../../context/modalTypeContext'
import React from 'react'
import download from '../../assets/images/footer/downloadIcon.png'

const BlueCTABrochure = ({ btnInfo,CTAOuterClassName }) => {
  const { setModalType } = useModalTypeContext()

  return (
    <div className="row m-0 mt-2 pg-diploma-btn-center ">
      <div
        className="pg-dnld-btn downlaod-brochure-pg-btn"
        style={{
          display:
            window?.innerWidth && window.innerWidth > 767
              ? 'block'
              : 'inline-block',
        }}
      >
        <a
          className={`ctc-btn teblet-down ctc-btn-container ${CTAOuterClassName?CTAOuterClassName:'blue-gradient-bg'} blue-btn-w`}
          data-toggle="modal"
          data-target="#download_brochure"

          onClick={() => setModalType(`${btnInfo?.modalType}`)}
        >
          <div className="row m-0 max-width">
            <div className="col-md-10">
              <div className="font-bold">
                {btnInfo?.title || 'Download Brochure'}
              </div>
              <div className="font-italic font-normal">
                {btnInfo?.description}
              </div>
            </div>
            <div className="col-md-2 right-download-icon">
              <img
                className="lazyloaded"
                src={download}
                width="25"
                height="25"
                alt=""
              />
            </div>
          </div>
        </a>
      </div>
    </div>
  )
}

export default BlueCTABrochure
