import logoNasscomMinistry from 'assets/images/pg-diploma/logo-nasscom-ministry-removebg.png'
import daFresher from 'assets/images/svgs/userWithBg.svg'
import seniorDA from 'assets/images/svgs/presentation.svg'
import daIV from 'assets/images/svgs/searchAnalyst.svg'


const bannerRecordDelhi = {
  courseID: 66,
  name: 'Data Science 360 Course',
  bannerType: null,
  robotImage: 'image/course/thumbnail-1616501509.svg',
  courseDetailsImage: 'image/course/thumbnail-1616501509-1660822396508.svg',
  introURL: '',
  bannerFileName: 'image/course/bg-banner-1644823150421.png',
  hoursCount: '500',
  classCount: '46',
}

const toolsList =[
  {
    "id" : 1,
    "title": "Excel",
    "link": null,
    "largeImage": "image/toolsImage/excel-1601020530983.png"

  },
  {
    "id" : 2,
    "title": "PowerBi",
    "link": null,
    "largeImage": "image/toolsImage/PowerBI-1662105782661.jpg"

  },
  {
    "id" : 3,
    "title": "SQL",
    "link": null,
    "largeImage": "image/toolsImage/sql-1601021568250.png"

  },
  {
    "id" : 4,
    "title": "Python",
    "link": null,
    "largeImage": "image/toolsImage/tool-lg1-1596020742035.png"

  },
  {
    "id" : 5,
    "title": "Jupyter Notebook",
    "link": null,
    "largeImage": "image/toolsImage/1200px-Jupyter_logo-1707735380727.png"

  },
  {
    "id" : 6,
    "title": "GIT",
    "link": null,
    "largeImage": "image/toolsImage/Git-logo-1707482493040.png"

  },
  {
    "id" : 7,
    "title": "Statsmodel",
    "link": null,
    "largeImage": "image/toolsImage/images-1707482464267.png"

  },
  {
    "id" : 8,
    "title": "Seaborn",
    "link": null,
    "largeImage": "image/toolsImage/Seaborn-1601022428840.png"

  },
  {
    "id" : 9,
    "title": "Pandas",
    "link": null,
    "largeImage": "image/toolsImage/pandas-1601020776154.jpg"

  },
  {
    "id" : 10,
    "title": "NumPy",
    "link": null,
    "largeImage": "image/toolsImage/asset2tools-1595857644274.png"

  },
  {
    "id" : 11,
    "title": "Matplotlib",
    "link": null,
    "largeImage": "image/toolsImage/Matplotlib-1601031916549.png"

  },
]

const faqsDelhi = {
  faqTitle: 'FAQs',
  faqDescription: null,
  faqsData: [
    {
      FaqID: 1,
      title: 'What are the career opportunities for Data Analysts in Delhi?',
      description:
        '<p>After completing the data analytics course in Delhi, you’ll be open to an array of job opportunities there. Data analysts in Delhi have diverse career opportunities in sectors such as IT, finance, healthcare, e-commerce, consulting, government, marketing, telecommunications, education, and manufacturing. </p><p>Starting with an average salary of ₹504,938/year, you can explore various job roles like Analytics Consultant, Business Analyst, Data Analyst, MIS Analyst, Statistical Analyst, and more. </p>',
    },
    {
      FaqID: 2,
      title: 'What are the prerequisites for this data analytics course?',
      description:
        '<p><span style="font-weight: 400;">Our data analysis certification in Delhi doesn&rsquo;t require you to review many concepts beforehand. A basic knowledge base in mathematics, statistics, and a bit of critical thinking would do; the rest, our experts, will help you grasp all the concepts. You can read this below-mentioned article to get yourself started -&nbsp;</span></p><p><a href="https://www.analytixlabs.co.in/blog/data-analytics-application/" class="ct-lg-linkUpdate" target="_blank"><span style="font-weight: 400;" >Data Analytics: Applications</span></a></p><p><a class="ct-lg-linkUpdate"  href="https://www.analytixlabs.co.in/blog/data-analytics-project-for-students/" target="_blank"><span style="font-weight: 400;">Top 10 Data Analytics Projects in 2024</span></a></p><p><a class="ct-lg-linkUpdate" target="_blank" href="https://www.analytixlabs.co.in/blog/career-in-data-analytics/"><span style="font-weight: 400;">Data Analyst Career Guide 2024</span></a></p>',
    },
    {
      FaqID: 3,
      title: 'What are the key topics covered in the course?',
      description: '<p>With a duration of 5 months, our data analyst course in Delhi module will contain the following topics:</p><ol class="ct-pg-ol"> <li> <p>Foundations of Mathematics &amp; Statistics, Fundamentals of Programming</p></li><li> <p>Business Intelligence, Analytics &amp; Data Visualization</p></li><li> <p>Exploratory Data Analysis &amp; Data Management</p></li><li> <p>Python for Analytics</p></li><li> <p>Data Analytics in Practice</p></li></ol>',
    },
    {
      FaqID: 4,
      title: 'Will I be working on real-world projects?',
      description:
        `<p><span style="font-weight: 400;">AnalytixLabs offers capstone projects with a Data analytics certification course in Delhi. These projects, meticulously curated to align with industry demands, allow participants to apply theoretical knowledge in practical settings.&nbsp;</span></p>
        <p><span style="font-weight: 400;">As part of the course, you'll delve into impactful projects such as Sports event analysis and reporting, airline data analysis, and others. These real-world applications reinforce your learning and equip you with the practical skills essential for success in the dynamic field of data analytics.</span></p>`,
    },
    {
      FaqID: 5,
      title: 'What is the level of difficulty for this course?',
      description:
        `<p><span style="font-weight: 400;">Our Data Analytics Certification Course in Delhi is meticulously crafted with a beginner&rsquo;s perspective in mind. This comprehensive program ensures that you grasp fundamental concepts and gain hands-on experience and in-depth insights.&nbsp;</span></p><p><span style="font-weight: 400;">With a focus on practical applications, this course is structured to empower you with a solid understanding of data analytics, making it an ideal starting point for those new to the field. Importantly, we've ensured that the difficulty level is carefully managed, guaranteeing a smooth and accessible learning journey for all participants.</span></p>`,
    },
    {
      FaqID: 6,
      title: 'Do I need any prior coding experience?',
      description:
        `<p><span style="font-weight: 400;">Embarking on a career in data analysis doesn't require an extensive coding background. Our data analyst training in Delhi is crafted to teach you essential programming concepts, making coding accessible to everyone, regardless of prior experience.&nbsp;</span></p><p><span style="font-weight: 400;">We're here to guide you through this journey, ensuring that lack of coding proficiency won't hinder your success in pursuing a career in data analytics.</span></p>`,
    },
    {
      FaqID: 7,
      title: 'Is this course tailored to the Delhi job market?',
      description:
        ` <p><span style="font-weight: 400;">Yes, the course content and career guidance of our Data analytics certification course in Delhi is tailored to the Delhi job market.&nbsp;</span></p>
        <p><span style="font-weight: 400;">As the data analytics market is expected to grow to USD 303.4 billion by 2030, AnalytixLabs ensures that you receive relevant training with trending industry-related projects from our industry experts aligned with the specific demands and trends in the local job market, increasing your chances of success in securing employment opportunities in Delhi.</span></p>`,
    }
  ],
}

const toolsRecordDelhi = [
  {
    title: 'Industry-Aligned<br/>Bootcamp Certification',
    description:
      'Aligned with Industry Standards and Designed for practical, job-oriented learning. Cost-effective program with high ROI, making it worth every penny you invest.',
  },
  {
    title: 'For Technical &<br/>Non-technical Candidates',
    description:
      'Industry-first approach to maximize the job prospects for beginner-to-mid career candidates from a technical or non-technical background.',
  },
  {
    title: 'Classroom & Online<br/>Blended',
    description:
      'Choose from 100% online or classroom-blended, hands-on learning with extensive student support and mentorship by seasoned industry experts.',
  },
]

const courseDetailsDelhi = {
  courseFullName: 'DATA ANALYST COURSE IN DELHI',
  slug: 'pg-in-data-analytics-delhi',
  shortDescription: 'Certified Data Analytics Course with Placements',
  highHighRegulatory: {
    title: 'Learn high in-demand job skills',
    images: [
      {
        imageUrl: logoNasscomMinistry,
        height: '90%',
        width: '90%',
        col: '4',
      },
    ],
  },
  hoursCount: '500',
  classCount: '46',
  classHours: '+ 74 hrs e-learning',
  projectCount: '18',
  ratingValue: 4.73,
  bestRating: 5,
  worstRating: 2,
  ratingCount: 4860,
  metaTitle: 'Become a Data Analyst in Delhi: Find Top-Rated Institutes & Courses',
  metaDescription:
    'Find the best Data Analyst courses and institutes in Delhi with placement opportunities. Learn about top programs and training options for aspiring Data Analysts.',
  metaKeywords:
    'data science course, data analytics certificate, data analytics certification, data analytics online course, data analytics course in India, data analytics course in Delhi, data analytics training in Bangalore, data analytics course in Noida',
  selfStudyHoursCount: '260',
  classCountSubline: ' (8-10 hrs/ week)',
  hoursPerClassCount: '46 x 3 = 138 hrs',
  CourseLearningModes: {
    amountINR: 38000,
    maxAmountINR: 48000,
  },
  taxPercentage: '18',
  LMSCourse: 'ckepznduu00attqjl6qni9nwp',
  courseID: 9997,
}

const PGdetailsDelhi = [
  {
    mainTitle: 'Introduction to Data Analytics Training in Delhi ',
    longDescription: `<p>In an era where data is the new currency, the ever-expanding demand for data-savvy professionals has made data analytics one of the most sought-after skills in today's job market.&nbsp;</p><p>As the India Big Data Technology &amp; Service Market is expected to reach&nbsp;USD 3.38 billion&nbsp;by 2029, all the major tech cities, like Bangalore, Pune, and Delhi, are facing a rise in the demand for data analysts.&nbsp;</p><p>Being at the heart of the country, Delhi offers a plethora of opportunities. Its excellent connectivity and frequent tech conferences and hackathons make Delhi a hub for tech enthusiasts.&nbsp;</p><p>Hence, whether you're a budding professional or looking to improve your skills, a data analyst course in Delhi can equip you with the essential tools and knowledge to thrive in this exciting field. In our <a href="https://www.analytixlabs.co.in/blog/data-science-101-guide/" class="ct-lg-linkUpdate" target="_blank">Data Science Bible Report 2023</a>, we discuss a wide range of tools and trends in data science and analytics. For more advanced learning, explore our <a href="/pg-in-data-science" class="ct-lg-linkUpdate" target="_blank">PG courses in Data Science</a> [offered in both 1-year and 6-month advanced courses].</p><p>With the data analytics training in Delhi from AnalytixLabs, you will learn with a robust educational approach curated by experts to guide you through the dynamic realm of data analytics. Our course integrates cutting-edge learning tools with a practical, hands-on methodology, ensuring you are well-prepared for the industry.&nbsp;</p>    <p>Whether you're starting from the basics or delving into advanced techniques, our data analyst training in Delhi equips you with a broader knowledge base. Enroll in our data analytics course in Delhi to cultivate a comprehensive skill set in data visualization, descriptive analytics, and predictive modeling for making informed business decisions.</p>`,
    needHelp: false,
    showCommonTable: true,
    subDetails : [
      {
        subBulletsPointDescription: '<p>The learning objectives of our data analytics training in Delhi are outlined as follows: </p>' ,
        SubBulletPoint:[
          {
            title : 'Develop the skill to uncover valuable business insights from data.',
          },
          {
            title : `Address your company's challenges by providing analytical solutions to bridge the gap.`,
          },
          {
            title : `Demonstrate proficiency in constructing data models and utilizing data analytic tools.`,
          },
          {
            title : `Empower decision-making by implementing data-driven solutions for smart business strategies.`,
          },
          {
            title : `Effectively communicate technical information and analysis results to both technical and non-technical audiences.`,
          },
          {
            title : `Assess and articulate data-driven solutions' precise operational, financial, and ethical impacts in structured and unstructured environments.`,
          }
        ]
      },
    ],
  },
  {
    title: `What is Data Analytics?`,
    longDescription: `<p><span style="font-weight: 400;">Data analytics inspects, cleans, transforms, and models raw data to extract valuable insights, draw conclusions, and support decision-making. It involves using various techniques and tools to analyze large data sets, uncover patterns, trends, and correlations, and derive meaningful information.&nbsp;</span></p>
    <p><span style="font-weight: 400;">Data analytics encompasses a range of methods, including descriptive analytics to summarize and describe data, diagnostic analytics to identify the causes of past events, predictive analytics to forecast future trends, and prescriptive analytics to recommend actions based on analysis results.&nbsp;</span></p>
    <p><span style="font-weight: 400;">Organizations leverage data analytics to enhance decision-making, optimize processes, improve efficiency, and gain a competitive edge in various industries. The field is multidisciplinary, combining aspects of statistics, mathematics, computer science, and domain-specific knowledge to extract actionable insights from data.</span></p>
    <p><span style="font-weight: 400;">Mastering these skills from the data analytics course in Delhi will help you learn the art of transforming raw data into actionable intelligence, shaping the future with each hidden pattern. </span></p>`,
    bulletsPointDescription: `<h3 class="h3 light-blue-color mt-4 mb-3"><b>Types of Data Analytics</b></h3>` ,
    noShowArrow : true,
    subDetails : [
      {
        SubBulletPoint:[
          {
            title : `<h4>Descriptive Analytics</h4>`,
            description: `<p>This foundational type answers "What happened?". It summarizes and organizes data, uncovering basic trends, patterns, and statistics. Imagine it as painting a picture of the past.</p>`,
            notShowArrow : true,
          },
          {
            title : `<h4>Diagnostic Analytics</h4>`,
            description: `<p>This type asks, "Why did it happen?". It investigates the root causes behind trends and identifies factors influencing them. Consider it a detective work for data!</p>`,
            notShowArrow : true,
          },
          {
            title : `<h4>Predictive Analytics</h4>`,
            description: `<p>Looking forward, this type asks, "What will happen?". It uses statistical models and machine learning to forecast trends and anticipate potential outcomes. It's like having a crystal ball powered by data.</p>`,
            notShowArrow : true,
          },
          {
            title : `<h4>Prescriptive Analytics</h4>`,
            description: `<p>Going beyond predictions, this type asks, "What should we do?". It recommends optimal actions based on insights from all other types, guiding future decisions. Think of it as having a data-driven advisor at your fingertips.</p>`,
            notShowArrow : true,
          },
          {
            title : ``,
            description: `<h5 class="mt-5 ct-pg-font-h5"><blockquote>
            <p class="ct-ml-60 ct-pg-blockquote-color" >For more insights, you may also read: <a class="ct-lg-link-blockquote" href="https://www.analytixlabs.co.in/blog/types-of-business-analytics/" target="_blank">Types of Data Analytics</a></p>
            </blockquote></h5>`,
            notShowArrow : true,
          },
        ]
      },
    ],

  },
  {
    title: `Course Syllabus of Data Analytics Course in Delhi`,
    BTNTitleShow: true,
    longDescription: `<p>Our data analyst training in Delhi is fit for individuals from various backgrounds like Finance, Maths, Engineering, and Business Management who are looking to upskill themselves to begin their Analytics. </p>
    <p>AnalytixLab’s data analyst course in Delhi is designed to cater to college graduates' and working professionals' diverse needs and career aspirations. You can choose specialized modules, opt for different learning intensities, and tailor the program to your specific learning style and career goals.</p>
    <p>At AnalytixLabs, we have thoughtfully organized our Data Analytics course in Delhi, breaking it down into three distinct terms. This intentional division allows for a comprehensive and well-paced learning journey, ensuring that participants can delve deeply into each aspect of the curriculum, grasp complex concepts thoroughly, and apply their newfound skills effectively. </p>
    `,
    bulletPoint:[
      {
        title : `TERM 1`,
        description: `<p>This term prepares participants to kickstart through structured problem-solving. It covers foundational math, statistics, and programming with a blend of eLearning. The curriculum includes business intelligence, analytics, and Excel and PowerBI data visualization. Participants also learn about exploratory data analysis, data management, and SQL for traditional and cloud environments.</p><ul>
          <li class='mb-2'>Orientation - Setting up for success</li>
          <li class='mb-2'>Problem-Solving - A structured approach to problem-solving</li>
          <li class='mb-2'>Building Blocks - Foundations of Mathematics &amp; Statistics, Fundamentals of Programming (Blended eLearning)</li>
          <li class='mb-2'>Business Intelligence, Analytics &amp; Data Visualization (using Excel &amp; PowerBI)</li>
          <li class='mb-2'>Exploratory Data Analysis &amp; Data Management (RDBMS - ETL - SQL for Data Science - SQL on Cloud)</li>
        </ul>
        `,
      },
      {
        title : `TERM 2`,
        description: `<p>Term 2 of the course focuses on "Python for Analytics," covering core Python, data analysis, statistical methods, and predictive modeling. The curriculum includes Python foundation, exploratory data analysis, statistical analysis, and linear and logistic regression modeling.
        </p><p>The curriculum also explores data analytics applications in various industries such as marketing, operations, risk analytics, retail, eCommerce, banking, insurance, and telecom. Practical applications are reinforced through practice bootcamps with weekday sessions.</p><h4 class="h5 light-blue-color mt-3 mb-3"><b>Python for Analytics - Statistical Analysis and Modelling using Python :</b></h4><ul>
        <li class='mb-2'><a class="ct-lg-linkUpdate" target="_blank" href="/deep-learning-with-python">Python</a> Foundation (Core Python) - Python Packages</li>
        <li class='mb-2'>Exploratory Data Analysis - Data Visualization</li>
        <li class='mb-2'>Statistical Analysis (Basic Statistics - Statistical Methods)</li>
        <li class='mb-2'>Predictive Modeling using Linear &amp; Logistic Regression</li>
        </ul>
        <h4 class="h5 light-blue-color mt-3 mb-3"><b>Data Analytics Applications - Industries & Functions </b>(24 hrs eLearning)</h4><ul>
        <li class='mt-2'>Marketing Analytics</li>
        <li class='mt-2'>Operations Analytics</li>
        <li class='mt-2'>Risk Analytics</li>
        <li class='mt-2'>Industry Application of Analytics in Retail, eCommerce, Banking, Insurance, Telecom, etc.</li>
        <li class='mt-2'>Practice Bootcamps (Weekday sessions)</li>
        </ul>
        `,
      },
      {
        title : `TERM 3`,
        description: `<p>Term 3 focuses on the hands-on application of data analytics through an industry capstone project, covering end-to-end pipeline development, business requirement gathering, and documentation. The curriculum includes analytics project management and emphasizes problem-solving skills.</p><p>Additionally, career preparation is strongly emphasized, including interview readiness, mock interviews, placement assistance, and a success accelerator program covering resume building and interview preparation.
        </p><h4 class="h5 light-blue-color mt-3 mb-3"><b>Data Analytics in Practice - Industry Capstone Project work - Final Viva</b></h4><ul>
        <li class='mb-2'>Data Analytics Project - End-to-End Pipeline
        </li><li class='mb-2'>Business Requirement Gathering - Problem-Solving
        </li><li class='mb-2'>Documentation - best practices</li><li class='mb-2'>Analytics Project Management
        </li>
        </ul><h4 class="h5 light-blue-color mt-3 mb-3"><b>Placement Preparation - Interview Preparation - Mock Interviews</b></h4>
        <ul>
        <li class='mb-2'>Placement Readiness Programme</li>
        <li class='mb-2'>Success Accelerator</li>
        <li class='mb-2'>Career Assistance (Resume, Mock Sessions, Interview prep, etc.)</li>
        </ul><p class='mt-3'>Each term is strategically designed to build upon the foundation laid in the previous one, creating a seamless and progressive educational experience for our students. The duration is 4 days a week, spread across Weekdays + Weekends.</p><p>You can experience fully interactive learning through live online sessions or classroom sessions in Gurgaon, Bangalore, and Noida. Benefit from a blended e-learning approach with Live Doubt Support and practice bootcamps. Moreover, you'll have access to recordings for future reference and self-study.
        </p>`,
      },
    ],
  },
  {
    mainTitle: `Data Analytics Certification Details`,
    longDescription: `<p>Due to our extensive domain expertise and esteemed clientele, AnalytixLabs Certification holds significant prestige within the industry, both in India and abroad. As one of the best institutes for data analysis courses in Delhi, we maintain the integrity of our certification process.</p>`,
  },
  {
    title: `Benefits of our certification include:`,
    subDetails : [
      {
        SubBulletPoint:[
          {
            title : `Certification is granted following a fair evaluation of mandatory case studies, assignments, multiple-choice questions (MCQs), and viva assessments integrated into the course curriculum.`,

          },
          {
            title : `Certification must be completed within one year of course registration to ensure timely progression and proficiency.`,
          },
          {
            title : `Trainees encountering challenges with assignments and projects are encouraged to seek assistance and support for improvement. However, academic integrity is non-negotiable, and plagiarism will not be tolerated during evaluation.`,
            description: `<p class='mt-3'>These measures ensure that our certification maintains its credibility and reflects the true capabilities of our certified professionals.</p>
            `
          },
        ]
      },
    ],
  },
  {
    title: `Capstone Projects Included in Data Analytics Delhi Course`,
    longDescription: `<p>In addition to multiple case studies used for class sessions, our data analyst course Delhi includes the following assignments and projects for self-study and hands-on skills.</p>`,
    needHelp: true,
    bulletPoint:[
      {
        title : `Data Analytics Project - End-to-End Pipeline`,
        description: `<p>Our Data Analytics Project features a robust end-to-end pipeline that seamlessly handles data from collection through cleaning, transformation, and analysis. We meticulously sourced and cleaned diverse datasets applied advanced transformation techniques, and utilized cutting-edge tools for insightful analytics. </p><p>The streamlined pipeline enhances efficiency, ensuring high-quality, actionable results. This comprehensive approach underscores our commitment to excellence in data analytics, empowering informed decision-making.
        </p>`,
      },
      {
        title : `Business Requirement Gathering - Problem-Solving`,
        description: `<p>When it comes to solving problems for business needs, the journey begins with the meticulous process of defining the business problem. This initial step sets the stage for the subsequent phases, starting with scoping the requirements.</p><p>Our problem-solving approach follows a systematic path from defining the business problem to deploying solutions. We begin by scoping requirements, setting analytics objectives, and carefully gathering and preparing data. The journey progresses through solution development, validation, and implementation, culminating in the crucial stages of tracking and fine-tuning. This comprehensive methodology ensures a strategic and effective approach to addressing business challenges.
        </p>`
      },
      {
        title : `Documentation - Best Practices`,
        description: `<p>Effective documentation is a cornerstone of successful projects, ensuring clarity, continuity, and collaboration. Embracing best practices in documentation streamlines processes and enhances overall project efficiency. </p><p>Emphasis is placed on clarity, coherence, and academic rigor. Additionally, integrating data analytics in practice is highlighted, showcasing its contemporary relevance in research methodologies. This ensures that the documentation meets academic standards and contributes meaningfully to the field of study.
        </p>`
      },
    ],
  },
]

const PGdetailsDelhiSecondSections = [
  {
    title: `Admission and Application Process`,
    needHelp: true,
    isShowRequestACallback:true,
    longDescription: `<p>We follow a personalized enrollment process for all our courses. To enroll in our data analytics course in Delhi, you must communicate your interest via our website, mail, chat, or phone. Once we receive your interest in enrolling in our data analytics certification course, one of our learning advisors will get in touch.</p><p>
    Our learning advisor will help you understand our course's industry relevance and evaluate whether this particular course will benefit you the most based on your profile. If our advisor finds your educational and professional know-how more suited for other courses, they will help you understand and decide how to proceed. Our advisor will also evaluate your choice of location, upcoming batches, and learning methodology.</p><p>
    We want to make sure you derive the best out of our courses, and we can do that only when we understand what you are looking for and your expectations from our learning materials. Our learning advisors will thoroughly and transparently guide you with course details and job scenarios, and they will evaluate your technical and non-technical knowledge, location, and learning preferences to deliver what is best for you.</p><p>
    Once you and your learning advisor agree, you may pay the admission fee to reserve your spot for the course at your location and upcoming batch date.</p>`,
  },
  {
    title: `Data Analytics Course Eligibility`,
    longDescription: `<p>The data analytics certification course is ideal for beginners, fresh graduates, and early-stage professionals looking to upskill.All our certification programs are ideal for learners acquainted with the basics of data analytics who are looking to climb the ladder to more experienced job roles. Once you complete this course, you can opt for any of our diploma courses based on your choice of subject.Our data analytics certification training course in Delhi requires no prior knowledge or experience in data analytics. It is designed for individuals with diverse educational backgrounds interested in exploring the data field.General requisites, but not mandatory:</p>`,
    bulletsPointDescription: `<h3 style='color:#013368'>General requisites, but not mandatory:</h3>` ,
    bulletPoint:[
      {
        title : `Bachelor's degree`,
        description: `Preferred in quantitative fields like statistics, computer science, or mathematics and commerce: This is often a requirement for university-level programs.`,
      },
      {
        title : `Basic understanding of statistics and mathematics`,
        description: `Most courses involve working with data, requiring some foundation in these subjects.`,
      },
      {
        title : `Programming skills`,
        description: `Many data analysis tasks involve programming languages like Python or R. Some courses require prior knowledge, while others offer introductory programming lessons.`,
      },
    ],
  },
  {
    mainTitle: `Online Data Analytics Course in Delhi - Learning Methodology`,
    longDescription: `<p>Our faculty members of the data analytics training in Delhi have a strong track record of global analytics experience. By working with the corporate and having the corporate level training experience, our trainers can share their insights based on their experiences, keeping the students in touch with the ongoing trends.  </p><p>As students embark on their journey through our data analytics course in Delhi, they can choose from diverse learning modes. Options include classroom bootcamps, interactive live online sessions, and self-paced e-learning modules. All students are then given access to a cutting-edge learning system for the course duration, with the courses constantly being updated to meet industry requirements.</p>`,
    bulletPoint:[
      {
        title : `Self-paced eLearning`,
        description: `<p>Learn through on-demand e-learning video sessions in your learning management system. Along with extensive doubts support over mail/ online/ in-person.</p>`,
      },
      {
        title : `Classroom & Bootcamp`,
        description: `<p>Experiential learning with in-person mentorship. We have both weekday and Weekend data analytics courses in Delhi.</p>`,
      },
      {
        title : `Interactive online classes`,
        description: `<p>We offer live online data analytics courses in Delhi, so students can conveniently attend them from anywhere. These are fully interactive sessions for real-time discussions and questions/ answers.</p>`,
      },
    ],
    subDetails : [
      {
        longDescription: `<p>Our data analytics training in Delhi emphasizes a personalized approach with small batch sizes and individualized counseling, fostering high dedication and personal attention. </P><p>The curriculum integrates practical exercises and real-world business datasets in practice assignments to ensure that knowledge retention is comprehensive and practical.</p>
        `,
      },
    ],
  },
  {
    title: `Learning Benefits with AnalytixLabs `,
    longDescription: `<p>AnalytixLabs, one of the best institutes for data analytics in Delhi, has welcomed data enthusiasts from various fields since 2011 with its data science and analytics courses. With our Data analytics certification course Delhi, you will get focused, practical, and personalized learning to make you job-ready. </p>
    <p>The most updated curriculum, professionally structured projects, and three different teaching modes according to student’s pace make it worth every penny you invest. You can choose from our weekday or weekend data analytics course in Delhi.</p>
    <p>Professionals design our data analytics training in Delhi to keep you abreast of the market's best practices and boost your data analytics career with constant updates according to market trends.</p>
    `,
  },
  {
    title: `Skills and Tools Covered`,
    longDescription: `<p>This Data Analytics Course with placement assistance in Delhi includes a comprehensive range of tools and library packages, including but not limited to the following.
    </p>
    `,
    needHelp: true,
    subDetails : [
      {
        subBulletsPointDescription: `<h3 class="h3 light-blue-color mt-4 mb-3"><b>Tools Included</b></h3>` ,
        SubBulletPoint:[
          {
            title: `<strong>Excel</strong><span> - </span><span>It is a widely used spreadsheet software for data organization and analysis.</span>`,
          },
          {
            title: `<strong>PowerBI</strong><span> - </span><span>Microsoft-developed tool for efficient data analysis with user-friendly features.</span>`,
          },
          {
            title: `<strong>SQL Server</strong><span> - </span><span>Cloud-based SQL Server for managing relational databases</span>`,
          },
          {
            title: `<strong>Python</strong><span> - </span><span>Versatile programming language for web development, data analysis, and machine learning.</span>`,
          },
          {
            title: `<strong>Jupyter Notebook</strong><span> - </span><span>Interactive web-based platform for creating and sharing documents with live code.</span>`,
          },
          {
            title: `<strong>GIT</strong><span> - </span><span>It is a version control system for tracking changes in source code during software development.</span>`,
          },
          {
            title: `<strong>MS PowerPoint</strong><span> - </span><span>Presentation software for creating slideshows and visual content.</span>`,
          },
          {
            title: `<strong>Statsmodel</strong><span> - </span><span>Python library for data analysis, data science, and statistical modeling.</span>`,
          },
          {
            title: `<strong>Seaborn</strong><span> - </span><span>Python library for creating statistical visualizations built on Matplotlib.</span>`,
          },
          {
            title: `<strong>Pandas</strong><span> - </span><span>Python library for data analysis, specializing in data manipulation and cleaning.</span>`,
          },
          {
            title: `<strong>NumPy</strong><span> - </span><span>Versatile array processing package for efficient scientific computations in Python.</span>`,
          },
          {
            title: `<strong>Matplotlib</strong><span> - </span><span>Python library for 2D plotting graphics, offering diverse data visualization capabilities.</span>`,
          },
          {
            title: `<strong>DAX</strong><span> - </span><span>Data Analysis Expressions, a formula language for data analysis, often associated with Microsoft Excel and PowerBI.</span>`,
            description: `<p class='mt-3'>In addition, you will develop soft skills such as business acumen, business analysis, and the ability to articulate data findings in a way that is accessible to all, enhancing the comprehensibility of your predictions.</p><p>The course focuses on fostering two pivotal skill sets, namely:</p>
            `,
          },
        ],
      },
      {
        longDescription: `<h3 class="h3 light-blue-color mt-4 mb-3"><b>Technical Skills</b></h3><p>This course encompasses instruction on several key technical languages and tools, including</p>`,
        SubBulletPoint:[
          {
            title : `<h4>Core Python</h4>`,
            description: `<p>Core Python refers to the fundamental and essential aspects of the Python programming language. It includes the basic syntax, data types, control structures (such as loops and conditionals), functions, and essential libraries that come with Python.</p>
            <p>
            Mastering core Python is crucial for building a strong foundation in programming and enables developers to handle a wide range of tasks, from simple scripting to more complex software development. As the backbone of the language, core Python concepts are essential for anyone looking to become proficient in Python programming.
            </p>`
          },
          {
            title : `<h4>JIRA</h4>`,
            description: `<p>An essential tool in business analytics, <a class="ct-lg-linkUpdate" href="https://www.atlassian.com/software/jira/guides/getting-started/introduction" target="_blank"><b>JIRA</b></a>  is employed for evaluating team performance and creating detailed, visually enriched data reports. It is particularly useful in operational analytics and can serve as a database.
            </p>`
          },
          {
            title : `<h4>Power BI</h4>`,
            description: `<p><a class="ct-lg-linkUpdate" href="https://powerbi.microsoft.com/en-us/" target="_blank">PowerBI</a>, developed by Microsoft, is a powerful business analytics tool that enables users to visualize and analyze data comprehensively and interactively. It offers a range of features for data preparation, modeling, and creating visually compelling reports and dashboards. </p><p>
            Power BI is designed to facilitate data-driven decision-making by providing users with a user-friendly interface and powerful tools for exploring and understanding their data. Whether working with structured or unstructured data, Power BI allows users to transform raw information into meaningful insights, making it a valuable asset for businesses seeking to leverage their data for strategic purposes.
            </p>`
          },
          {
            title : `<h4>Microsoft Excel</h4>`,
            description: `<p>Microsoft Excel is a widely utilized spreadsheet software in the Microsoft Office suite. It is designed for data organization, management, and representation tasks. </p><p>
            Excel provides a user-friendly interface for creating, editing, and manipulating spreadsheets containing numerical and text data. One of its powerful features is the built-in pivot tables, which allow users to analyze and summarize large datasets efficiently. </p><p>It is commonly used for tasks such as data entry, calculation, charting, and financial analysis, making it a versatile tool for both personal and professional use.</p>`
          },
          {
            title : `<h4>Microsoft PowerPoint</h4>`,
            description: `<p>Utilized for data visualization, especially when presenting data insights to non-technical team members and stakeholders. PowerPoint and other tools in the Microsoft Office suite function as an analytical suite.</p>`
          },
          {
            title : `<h4>Azure (Cloud Computing)</h4>`,
            description: `<p><a class="ct-lg-linkUpdate" href="https://azure.microsoft.com/en-in/" target="_blank">Azure</a> Analysis Services offers cloud-based, enterprise-grade data models. It allows the assembly of data from multiple sources, refinement, definition of metrics, and securing of data in a tabular semantic data model.</p>`
          },
          {
            title : `<h4>Tableau (Optional)</h4>`,
            description: `<p>An end-to-end data analytics platform designed to prepare data for analysis. You can collaborate and share your data insights with this tool. The visual analysis in Tableau is highly powerful and is easily shared across organizations.
            </p>`
          },
        ]
      },
      {
        longDescription: `<h3 class="h3 light-blue-color mt-4 mb-3"><b>Soft Skills</b></h3><p>Soft skills play a pivotal role in professionals' success across various domains, and the field of data analysis is no exception. A proficient data analyst possesses technical prowess and also excels in essential soft skills that enhance their effectiveness in the dynamic and collaborative nature of data-driven environments. Here, we delve into the core soft skills that distinguish a skilled data analyst:</p>`,
        SubBulletPoint:[
          {
            title : `<h4>Communication Skills</h4>`,
            description: `<p>Data analysts must effectively convey complex technical information to non-technical stakeholders. Concise and clear communication is essential for sharing insights and recommendations.</p>`
          },
          {
            title : `<h4>Critical Thinking</h4>`,
            description: `<p>The ability to think critically and analyze data objectively is vital. Data analysts must evaluate information, identify trends, and draw meaningful conclusions to support decision-making.</p>`
          },
          {
            title : `<h4>Problem-Solving</h4>`,
            description: `<p>Data analysts often encounter challenges in data quality, incomplete datasets, or ambiguous requirements. Strong problem-solving skills enable analysts to navigate obstacles and find effective solutions.</p>`
          },
          {
            title : `<h4>Attention to Detail</h4>`,
            description: `<p>Precision is crucial in data analysis. Data analysts must pay close attention to details to ensure accuracy in their work, from cleaning and processing data to creating visualizations.</p>`
          },
          {
            title : `<h4>Time Management</h4>`,
            description: `<p>Data projects often have deadlines, and data analysts must manage their time efficiently to meet those deadlines. Prioritizing tasks and balancing workload is key to success.</p>`
          },
          {
            title : `<h4>Adaptability</h4>`,
            description: `<p>The field of data analysis is dynamic, with evolving technologies and methodologies. Data analysts must stay adaptable, embracing new tools and techniques to stay current and effectively address evolving challenges in the data landscape.</p>`
          },
        ]
      },
    ],

  },
]

const MarketingAnalyticsDelhi = [
  {
    title: `Top Job Roles You Can Explore in Delhi `,
    bulletsPointDescription: `<p>Upon completing the course, take the opportunity to explore a variety of career paths that align with your acquired skills and knowledge. This exploration will allow you to apply your expertise in diverse professional settings and discover the most suitable and fulfilling career opportunities for your unique skill set.</p>` ,
    bulletPoint:[
      {
        title : `Data Analyst`,
        description: `<p>Responsibilities: Data Analysts are tasked with reviewing and analyzing data to extract meaningful insights that enhance overall business performance. This role involves interpreting patterns, identifying trends, and providing actionable recommendations to guide strategic decisions.</p>`,
      },
      {
        title : `Analytics Consultant`,
        description: `<p>Responsibilities: This role entails analyzing large datasets to extract insights crucial for business objectives. You'll develop data-driven strategies to optimize processes and drive growth, collaborating with teams to communicate findings effectively and provide actionable recommendations.</p>`,
      },
      {
        title : `Business Analyst`,
        description: `<p>Responsibilities: Business Analysts bridge the gap between data and business strategy. This role includes a comprehensive understanding of business processes, identifying areas for improvement, efficiency enhancement, and strategic alignment. The extracted insights guide decision-makers toward informed choices.</p>`,
      },
      {
        title : `Business Intelligence Analyst`,
        description: `<p>Responsibilities: In the dynamic field of Business Intelligence, a BI Analyst translates raw data into actionable insights. The prime responsibilities include transforming complex information into comprehensible reports and dashboards, impacting internal and external stakeholders for informed decision-making.</p>`,
      },
    ],
  },
]

const PlacementAndSupportDelhi = [
  {
    isShowRequestACallbackOnlyCTA: true,
    title: `Data Analytics Course with Placement Support`,
    longDescription: `<p>Upon completing training for data analytics from our institute in Delhi, we are dedicated to securing placements for our students in well-established companies across diverse industries, including Accenture, American Express, and McKinsey.</p><p>Our primary goal is to furnish our learners with fundamental and advanced analytics skills, enabling them to emerge as highly proficient professionals poised for career success.</p><p>
    We emphasize job-relevant skills as a key aspect of our philosophy, creating a successful data analyst course in Delhi with placement for our students. Our experienced faculty members in the data analytics training Delhi course provide ongoing guidance, covering aspects such as job applications, interview preparation, and the opportunity to participate in mock interviews if needed.</p><p>Furthermore, we actively advocate for our students by referring their resumes to various companies whenever suitable opportunities arise.</p>
    <p>AnalytixLabs is committed to helping you launch your career successfully. Our default placement guidance program focuses on developing job-relevant skills. You'll receive expert guidance on resume building, interview preparation and how to apply for relevant job roles. For those seeking an even greater advantage, our optional Placement Readiness Program (PRP) offers bouquet of benefits:</p>`,
    bulletPoint:[
      {
        title : `No Upfront Fees`,
        description: `<p>We believe in shared success. That's why we offer a unique model which comes with zero upfront costs. Instead, you'll only pay for the Placement Readiness Program (PRP) if you secure a job with a minimum salary of INR 7 Lakhs per annum within six months of completing PRP.</p>`,
      },
      {
        title : `Placement Readiness Program`,
        description: `<p>An optional 2-month, industry-focused module designed to enhance technical and soft skills. It includes coaching, key topic recaps for interviews, practice tests, case studies, and simulated recruitment drives. The program also features mock interviews with an industry panel, providing one-to-one feedback and guidance.</p>`,
      },
      {
        title : `Flexible Opt-in`,
        description: `<p>After certification you can take upto 30 days to decide and opt in for the Placement Readiness Program. This option is ideal for those who are looking for upskilling or a coveted industry-recognized certificate, feel confident in their acquired skills, and wish to pursue job applications independently. These students will still be eligible for basic placement guidance.</p>`,
      },
      {
        title : `Continuous Support`,
        description: `<p>After you successfully complete PRP, we will continue offering ongoing support as long as you need it. Many of our students benefit from numerous interview calls and rewarding career prospects attributed to the skills acquired during the course.</p>`,
      },
    ],
    // subDetails : [
    //   {
    //     longDescription: `<p>Our prime focus is on the following:</p>`,
    //     SubBulletPoint:[
    //       {
    //         title : `<h4>Profile Building</h4>`,
    //         description: `<p>Our team of seasoned professionals offers personalized assistance in crafting CVs and online profiles tailored to your educational background and expertise.</p>`
    //       },
    //       {
    //         title : `<h4>Interview Preparation</h4>`,
    //         description: `<p>This will be followed by one-on-one interview preparation, guidance for career development tasks, and mock interviews (if required).</p>`
    //       },
    //       {
    //         title : `<h4>Job Referrals</h4>`,
    //         description: `<p>With over a decade in the industry, AnalytixLabs maintains strong relationships with top organizations, regularly receiving job requirements. Tap into AnalytixLabs' extensive industry network, connecting you with job opportunities from top organizations, clients, HR consultants, and alumni across various industries.</p>`
    //       },
    //       {
    //         title : `<h4>Continuous Support</h4>`,
    //         description: `<p>Upon completing our data analysis certification in Delhi, we are committed to offering continuous support as long as you need it. Most of our students do get multiple interview calls and good career options based on the skills they learn during the course.</p>`
    //       },
    //     ]
    //   },
    // ],

  },
  {

    title: `Career Stories by AnalytixLabs Alumni`,
    longDescription: `<p>Success stories from our course abound, with graduates showcasing remarkable achievements in their respective fields. These individuals have seamlessly translated their learning into tangible accomplishments, ranging from impactful projects to securing coveted positions in leading organizations.
    The common thread in these success narratives is the application of knowledge gained through experiential learning, as our course strongly emphasizes hands-on experiences, real-world projects, and practical applications. </p><p>Experiential learning, a transformative educational approach, prioritizes hands-on, practical experiences at the core of the learning journey. This methodology not only stimulates active engagement, critical thinking, and reflection but also empowers learners to acquire practical skills and real-world insights that surpass the confines of conventional classroom instruction. </p><p>By directly participating in activities, simulations, projects, or internships, experiential learning facilitates a profound comprehension of concepts, cultivates advanced problem-solving capabilities, and nurtures a comprehensive, applicable knowledge foundation. </p><p>This approach empowers learners to absorb information and engage with it actively, fostering a deeper understanding and paving the way for meaningful career achievements.</p>`,
  },
]
const UpcomingBatchesDelhi = [
  {
  title: `Data Analytics Course in Delhi - Upcoming Batches`,
  longDescription: `<p>Our data analytics training course in Delhi is happening on multiple dates. Choose a date and timing of your choice, and get your learning started.</p>`,
  needHelp: false,
},
]

const careerGraphDelhi = {
  title: 'CAREER GRAPH [IN INR]',
  careerList: [
    {
      imageUrl: daFresher,
      upperContent: 'Data Analyst [Fresher]',
      lowerContent: '5-7 L/Year',
    },
    {
      imageUrl: seniorDA,
      upperContent: 'Senior Data Analyst',
      lowerContent: '12-20 L/Year',
    },
    {
      imageUrl: daIV,
      upperContent: 'Data Analyst IV',
      lowerContent: '20+ L/Year',
    },
  ],
}

const DataAnalyticsCourseFeeDataDelhi = {
  mainTitle :  `Data Analytics Course Fee and Duration`,
  table : {
    title: `Data Analytics Course Fee and Duration`,
    mobileViewTable:
    `<div class='pg-table-width'>
    <table style="border-collapse: collapse; width: 100%; border: 1px solid #000000; padding: 10px 20px;">
    <tbody>
    <tr>
    <td style="border-right: 1px solid #000000; border-bottom: 1px solid #1c4587; padding: 25px 20px 10px; background-color: #1c4587; width: 40%;" rowspan="2">
    <p style="text-align:center"><span style="color: #ffffff;"><strong>Data Analytics Course in Delhi</strong></span></p>
    </td>
    <td style="border: 1px solid #000000; padding: 20px 20px 10px; background-color: #1c4587; text-align: center;">
    <p><span style="color: #ffffff;"><strong>Duration</strong></span></p>
    </td>
    <td style="border: 1px solid #000000; padding: 20px 20px 10px; background-color: #1c4587; text-align: center;">
    <p><span style="color: #ffffff;"><strong> Fees</strong></span></p>
    </td>
    </tr>
    <tr>
    <td style="border: 1px solid #000000; padding: 20px 20px 10px; background-color: #cfe2f3; width: 30%; text-align: center;">
    <p><span style="color: #1c458e;">5 months</span></p>
    </td>
    <td style="border: 1px solid #000000; padding: 20px 20px 10px; background-color: #cfe2f3; text-align: center;">
    <p><span style="color: #1c458e;">INR 52,000/- plus taxes</span></p>
    </td>
    </tr>
    </tbody>
    </table>
    </div>`
      ,
  },
  jobScope : [
    {
      longDescription: `<div class="menu-title-pg-diploma mb-3 mt-4 menu-title-pg-diploma-analytics"><h1>Data Analytics Career Graph in Delhi</h1></div></div><p>Delhi's booming tech scene makes it a prime spot for your data analytics education. Nestled in India's National Capital Region, Delhi boasts cutting-edge infrastructure and is fast becoming a tech hub. TCS, INFOSYS, AIRTEL, ICICI, HDFC, and many more are major tech companies recruiting business analytics professionals.</p><p>There are many advantages to taking a data analytics course in Delhi. Network and collaborate with many tech companies and startups, especially those specializing in data analytics. </p><p>Gain invaluable access to industry resources and expertise right at your doorstep. Imagine learning alongside potential colleagues and exploring internship opportunities within this dynamic ecosystem.
      </p><p>On average, the career graph for a data analyst in Delhi looks like this:</p>`,
    },
  ],
  otherDescription: `<p class='mt-3'>Join this thriving community and propel your data analytics journey to new heights. Choose our data analytics training Delhi and unlock your potential in the exciting world of data! </p>`,
  jobScopeOtherPart: [
  ]
}

const  DataAnalyticsDelhiTable = {
  title: `Data Analytics Course Fee and Duration`,
  mobileViewTable:
  `<div class='pg-table-width' style="border-collapse: collapse; width: 80%;">
  <table style="border-collapse: collapse; width: 100%; border: 1px solid #000000; padding: 10px 20px;">
  <tbody>
  <tr>
  <td style="border-right: 1px solid #000000; border-bottom: 1px solid #1c4587; padding: 25px 20px 10px; background-color: #1c4587; width: 35%;" rowspan="2">
  <p style="text-align:center"><span style="color: #ffffff;"><strong>Data Analytics Course in Delhi</strong></span></p>
  </td>
  <td style="border: 1px solid #000000; padding: 20px 20px 10px; background-color: #1c4587; text-align: center;">
  <p><span style="color: #ffffff;"><strong>Duration</strong></span></p>
  </td>
  <td style="border: 1px solid #000000; padding: 20px 20px 10px; background-color: #1c4587; text-align: center;">
  <p><span style="color: #ffffff;"><strong> Fees</strong></span></p>
  </td>
  </tr>
  <tr>
  <td style="border: 1px solid #000000; padding: 20px 20px 10px; background-color: #cfe2f3; width: 30%; text-align: center;">
  <p><span style="color: #1c458e;">5 months</span></p>
  </td>
  <td style="border: 1px solid #000000; padding: 20px 20px 10px; background-color: #cfe2f3; text-align: center;">
  <p><span style="color: #1c458e;">INR 1,19,000/- Plus GST</span></p>
  <p className="fee-ttl ct-font-size-12" style="font-size:14px; color: #1c458e;" >(Save up to 40% on fees*)</p>
  </td>
  </tr>
  </tbody>
  </table>
  </div>`
    ,
  longDescription: `<p class='mt-3'><span style="font-weight: 400;">The fees for our data analytics course in Delhi adhere to industry standards. With the course duration of </span><b>5 months</b><span style="font-weight: 400;"> or </span><b>850 learning hours</b><span style="font-weight: 400;">, the total fee is </span><b>INR 1,19,000</b><span style="font-weight: 400;"> Plus GST.</span></p>`,
}

const skillsAndToolsDelhi = {
  title: 'Industries and Functions Covered',
  longDescription:
    '<p>The data analytics training Delhi course comprehensively explores real-time data analytics applications across diverse industries, including:</p>',
  descWithBg: [
    {
      label:
        'BFSI (Finance and Insurance), Healthcare, Telecom, Hospitality, Manufacturing, Retail/eCommerce',
    }
  ],
  LastDescription:
    '<p>This broad spectrum ensures a thorough understanding of how data analytics is applied in various sectors, enhancing practical knowledge and adaptability for real-world scenarios. These <a class="ct-lg-linkUpdate" href="https://www.analytixlabs.co.in/blog/data-analytics-application/ " target="_blank">industries employ data analytics</a> for a wide variety of functions, some of which are presented below:<p>',
}

const PGskillsAndToolsDelhi = [
  {
    bulletPoint:[
      {
        title : `Marketing Analytics`,
        description: `<p>Data analytics is crucial in understanding customer behavior, identifying reasons behind their actions, and optimizing the overall return on investment (ROI) for marketing activities. </p><p>This module focuses on techniques and methodologies to analyze marketing data, identify trends and patterns, and take an analytical approach to refine marketing campaigns. Students will gain insights into user behavior, enabling them to enhance marketing strategies and foster customer loyalty.
        </p>`,
      },
      {
        title : `Operational Analytics`,
        description: `<p>Operational analytics aims to improve the efficiency of business operations. It is a subset of business analytics that emphasizes synchronizing data from the warehouse into various business tools, ensuring accessibility for all employees regardless of their technical expertise. </p><p>This course delves into monitoring current and real-time operations, enhancing productivity, and streamlining daily business activities.</p>`,
      },
      {
        title : `Risk Analytics`,
        description: `<p>Risk analytics equips individuals with the skills to quantify and predict risks precisely. Participants will learn to leverage various technologies to delve into business data, formulate risk-driven scenarios, and accurately predict potential future risks. </p><p>Data analytics applications in risk management help businesses identify, analyze, and evaluate risks, preventing large-scale debacles.
        </p>`,
      },
      {
        title : `Industry Application of Analytics`,
        description: `<p>This segment provides real-time learning experiences on implementing data analytics across diverse business domains, industries, and functions such as retail, eCommerce, banking, insurance, and telecom. </p><p>Students gain hands-on knowledge of addressing business problems, developing applicable models, and mitigating large-scale risks. The course emphasizes adapting analytical knowledge based on the specific industry or function participants are involved in.</p>`,
      },
    ],
  },
]

const demoBrochureDelhi = {
  header : 'DEMO & BROCHURE',
}
const feesRecordPGDelhi = 'INR 6,878/- EMI*';

export {
  toolsList,
  toolsRecordDelhi,
  courseDetailsDelhi,
  bannerRecordDelhi,
  faqsDelhi,
  PGdetailsDelhi,
  careerGraphDelhi,
  DataAnalyticsCourseFeeDataDelhi,
  skillsAndToolsDelhi,
  MarketingAnalyticsDelhi,
  PlacementAndSupportDelhi,
  DataAnalyticsDelhiTable,
  PGskillsAndToolsDelhi,
  PGdetailsDelhiSecondSections,
  demoBrochureDelhi,
  feesRecordPGDelhi,
  UpcomingBatchesDelhi,
}

// TODO: SampleArrayObject use in this file.

// const SampleArrayObject = {
//   mainTitle : ``,
//   title: ``,
//   needHelp: boolean,
//   BTNTitleShow: boolean,
//   longDescription: `<p></p>`,
//   bulletsPointTitle : ``,
//   bulletsPointDescription: `` ,
//   bulletPoint:[
//     {
//       title : ``,
//       description: ``,
//     }
//   ],
//   subDetails : [
//     {
//       title: ``,
//       longDescription: ``,
//       subBulletsPointTitle :``,
//       subBulletsPointDescription: `` ,
//       SubBulletPoint:[
//         {
//           title : ``,
//           description: ``
//         },
//       ]
//     },
//   ],

// }
