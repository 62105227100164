/* eslint-disable jsx-a11y/anchor-is-valid */
import BlueCTABrochure from 'components/BlueCTABrochure'
import React, { memo } from 'react'

const PGOverView = memo(({ PGOverView, ctaList, ctaBelow }) => {
  return (
    <section>
      <div className="container mt-4 ">
       {PGOverView && <div
          className="counrse-contn-pg-diploma-analytics"
          dangerouslySetInnerHTML={{
            __html: PGOverView?.longDescription,
          }}
        ></div>}
        {ctaList && <div className="row m-0 mt-2 pg-diploma-btn-center">
          {ctaList.map((item) => {
            return (
              <BlueCTABrochure btnInfo={item} CTAOuterClassName="blue-bg" />
            )
          })}
        </div>}
       {ctaBelow && <div
          className="counrse-contn-pg-diploma-analytics mt-2"
          dangerouslySetInnerHTML={{
            __html: ctaBelow?.longDescription,
          }}
        ></div>}
      </div>
    </section>
  )
})
export default PGOverView
