import React from 'react'
import {Helmet} from 'react-helmet-async'

const HTMLHeadCommon = () => {
  return (
    <Helmet>
      <script async src="https://www.googletagmanager.com/gtag/js?id=AW-783236209"></script>
      <script>
        {`window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config','AW-783236209', {'allow_enhanced_conversions':true});`}
      </script>
      <script>
        {`function gtag_report_conversion(url) {
        var callback = function () {
          if (typeof(url) != 'undefined') {
          window.location = url;
          }
        };
        gtag('event', 'conversion', {
          'send_to': 'AW-783236209/__HmCMiv3_4BEPH4vPUC',
          'event_callback': callback
        });
        return false;
        }`}
      </script>
    </Helmet>
  )
}

export default HTMLHeadCommon
