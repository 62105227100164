export const AIForManagersLeadersData = {
  dummy: false,
  bannerRecord: {
    courseID: 73,
    name: "AI for Managers and Leaders Certification Course",
    bannerType: null,
    robotImage: "image/course/iitg-web-icon-logo-17144720123276.webp",
    courseDetailsImage: "image/course/iitg-web-icon-logo-17144720123276.webp",
    introURL: "",
    bannerFileName: "image/course/gen-ai-course-banner-web.jpg",
    hoursCount: "52",
    classCount: "14"
  },
  toolsRecord: [
    {
      toolID: 3,
      Tool: {
        toolID: 3,
        title: "ChatGPT",
        smallImage: "image/toolsImage/chatgpt-1734957170701.png",
        link: null,
        largeImage: "image/toolsImage/chatgpt-1734957170701.png"
      }
    },
    {
      toolID: 3,
      Tool: {
        toolID: 3,
        title: "Gemini",
        smallImage: "/image/toolsImage/gemini-1734957148379.png",
        link: null,
        largeImage: "/image/toolsImage/gemini-1734957148379.png"
      }
    },
    {
      toolID: 11,
      Tool: {
        toolID: 11,
        title: "OpenAI",
        smallImage: "image/toolsImage/openai-1734957133254.png",
        link: null,
        largeImage: "image/toolsImage/openai-1734957133254.png"
      }
    },
    {
      toolID: 15,
      Tool: {
        toolID: 15,
        title: "Cohere",
        smallImage: "image/toolsImage/cohere-1734957111480.png",
        link: null,
        largeImage: "image/toolsImage/cohere-1734957111480.png"
      }
    },
    {
      toolID: 18,
      Tool: {
        toolID: 18,
        title: "Microsoft Azure",
        smallImage: "image/toolsImage/azure-1734957095704.png",
        link: null,
        largeImage: "image/toolsImage/azure-1734957095704.png"
      }
    },
    {
      toolID: 7,
      Tool: {
        toolID: 7,
        title: "Amazon Web Services",
        smallImage:
          "image/toolsImage/Amazon_Web_Services-Logo-1734937217929.png",
        link: null,
        largeImage:
          "image/toolsImage/Amazon_Web_Services-Logo-1734937217929.png"
      }
    },
    {
      toolID: 1,
      Tool: {
        toolID: 1,
        title: "Microsoft Copilot",
        smallImage: "image/toolsImage/mscopilot-1734957066901.png",
        link: null,
        largeImage: "image/toolsImage/mscopilot-1734957066901.png"
      }
    },
    {
      toolID: 17,
      Tool: {
        toolID: 17,
        title: "Teachable",
        smallImage: "image/toolsImage/teachable-1734956962540.png",
        link: null,
        largeImage: "image/toolsImage/teachable-1734956962540.png"
      }
    },
    {
      toolID: 39,
      Tool: {
        toolID: 39,
        title: "Jasper",
        smallImage: "image/toolsImage/jasper-1734957044305.png",
        link: null,
        largeImage: "image/toolsImage/jasper-1734957044305.png"
      }
    },
    {
      toolID: 39,
      Tool: {
        toolID: 39,
        title: "Synthesia",
        smallImage: "image/toolsImage/synthesia-1734957033447.png",
        link: null,
        largeImage: "image/toolsImage/synthesia-1734957033447.png"
      }
    },
    {
      toolID: 39,
      Tool: {
        toolID: 39,
        title: "Streamlit",
        smallImage: "image/toolsImage/1683823510dall-e-logo-png-1738761048419.png",
        link: null,
        largeImage: "image/toolsImage/1683823510dall-e-logo-png-1738761048419.png"
      }
    },
    {
      toolID: 39,
      Tool: {
        toolID: 39,
        title: "DataRobot",
        smallImage: "image/toolsImage/datarobot-1734956974003.png",
        link: null,
        largeImage: "image/toolsImage/datarobot-1734956974003.png"
      }
    }
  ],
  sidebarTitles: {
    courseSidebarID: 11,
    courseID: 73,
    overview: "Course Overview",
    curriculum:
      "Curriculum for Artificial Intelligence For Managers and Leaders",
    whoShouldDo: "AI for Managers Course Eligibility",
    jobRoles: "Top Job Roles to Explore",
    keySkills: "Top Skills You Will Learn",
    capstoneProject: "AI engineering Capstone Projects",
    deliveryFormat:
      "Artificial Intelligence online course - Learning Methodologies",
    certification: "Certification Details",
    feeDetails: "Learning Methodologies & Course Fees",
    careerSupport: "Career Support by AnalytixLabs",
    successStories: "Career stories by AnalytixLabs",
    demoAndBrochure: "Demo & Brochure",
    upcomingBatches: "Upcoming Batches",
    FAQAndBlog: "FAQs & Blogs",
    createdAt: "2021-07-16T11:58:29.000Z",
    updatedAt: "2022-05-20T05:16:49.000Z",
    careerPath: "Career Path",
    learningBenefits: "Learning Benefits"
  },
  upcomingBatchResult: [
    {
      courseBatchID: 925,
      LMSBatchID: null,
      courseID: 73,
      instructorID: null,
      learningModeID: 2,
      name: "Python for Data Science- Gurgaon '24",
      description: null,
      seat: 4,
      cityID: 4,
      startDate: "2024-05-05T00:00:00.000Z",
      batchTime: "10:00 AM - 1:00 PM",
      isInteractive: true,
      showOnWebsite: 1,
      sortOrder: 0,
      isActive: true,
      isDeleted: false,
      createdBy: 345,
      updatedBy: null,
      modifiedDate: "2024-04-20T09:25:03.000Z",
      modifiedUser: 345,
      createdAt: "2023-03-15T09:12:44.000Z",
      updatedAt: "2024-04-20T09:25:03.000Z",
      City: {
        cityID: 4,
        name: "Gurgaon"
      }
    },
    {
      courseBatchID: 924,
      LMSBatchID: null,
      courseID: 73,
      instructorID: null,
      learningModeID: 2,
      name: "Python for Data Science- Bangalore '24",
      description: null,
      seat: 15,
      cityID: 1,
      startDate: "2024-05-19T00:00:00.000Z",
      batchTime: "2:00 PM - 5:00 PM",
      isInteractive: true,
      showOnWebsite: 1,
      sortOrder: 0,
      isActive: true,
      isDeleted: false,
      createdBy: 345,
      updatedBy: null,
      modifiedDate: "2024-04-16T06:52:48.000Z",
      modifiedUser: 345,
      createdAt: "2023-03-15T09:12:06.000Z",
      updatedAt: "2024-04-16T06:52:48.000Z",
      City: {
        cityID: 1,
        name: "Bangalore"
      }
    }
  ],
  courseIncludedRecord: [
    {
      courseID: 63,
      name: "Data Science using Python",
      slug: "data-science-using-python",
      hoursCount: "570",
      classCount: "43",
      courseType: 1,
      bannerFileName:
        "image/course/iitg-web-banner-1713875251999-1714199901805.webp",
      robotImage: "image/course/thumbnail-1616501891.svg",
      courseDetailsImage:
        "image/course/iitg-web-icon-logo-1714134463298-1714199689299.png",
      CourseTags: [
        {
          tagID: 4,
          Tag: {
            name: "DS360"
          }
        },
        {
          tagID: 1,
          Tag: {
            name: "BA360"
          }
        }
      ],
      learningMode: [
        {
          learningModeID: 1,
          name: "Classroom & Bootcamps",
          image: "image/learningMode/img-1.png",
          description:
            "Experiential learning with in-person mentorship!\r\n\r\n",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        },
        {
          learningModeID: 2,
          name: "Classroom with ILT Blended",
          image: "image/learningMode/img-2.png",
          description:
            "Learn from the best with unrestricted support, wherever you are!\r\n\r\n",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        },
        {
          learningModeID: 3,
          name: "Blended eLearning",
          image: "image/learningMode/img-3.png",
          description: "Learn at your pace with on-demand lectures & Support!",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        }
      ],
      amountINR: 35000,
      discountType: 1,
      discountAmount: 0,
      upcomingBatch: {
        courseID: 63,
        learningModeID: 2,
        startDate: "2024-05-05T00:00:00.000Z"
      }
    },
    {
      courseID: 65,
      name: "Machine Learning using Python",
      slug: "machine-learning-course-certification-training",
      hoursCount: "570",
      classCount: "43",
      courseType: 2,
      bannerFileName:
        "image/course/iitg-web-banner-1713875251999-1714199901805-1714562347744.webp",
      robotImage: "image/course/website-banners-creatives-alabs-07 copy.svg",
      courseDetailsImage:
        "image/course/iitg-web-icon-logo-1714134463298-1714199689299-1714562347747.png",
      CourseTags: [
        {
          tagID: 2,
          Tag: {
            name: "ABDS"
          }
        },
        {
          tagID: 4,
          Tag: {
            name: "DS360"
          }
        },
        {
          tagID: 3,
          Tag: {
            name: "AIML"
          }
        }
      ],
      learningMode: [
        {
          learningModeID: 1,
          name: "Classroom & Bootcamps",
          image: "image/learningMode/img-1.png",
          description:
            "Experiential learning with in-person mentorship!\r\n\r\n",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        },
        {
          learningModeID: 2,
          name: "Classroom with ILT Blended",
          image: "image/learningMode/img-2.png",
          description:
            "Learn from the best with unrestricted support, wherever you are!\r\n\r\n",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        },
        {
          learningModeID: 3,
          name: "Blended eLearning",
          image: "image/learningMode/img-3.png",
          description: "Learn at your pace with on-demand lectures & Support!",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        }
      ],
      amountINR: 20000,
      discountType: 1,
      discountAmount: 0,
      upcomingBatch: {}
    },
    {
      courseID: 68,
      name: "Certified Big Data Engineer",
      slug: "big-data-analytics-hadoop-spark-training-course-online",
      hoursCount: "210",
      classCount: "30",
      courseType: 1,
      bannerFileName:
        "image/course/machine-learning-course-certification-training1-1616494453966.jpg",
      robotImage: "image/course/Thumbnail-1616494453967.svg",
      courseDetailsImage:
        "image/course/certified-big-data-engineer-1657623430377.png",
      CourseTags: [
        {
          tagID: 2,
          Tag: {
            name: "ABDS"
          }
        },
        {
          tagID: 5,
          Tag: {
            name: "AIE"
          }
        }
      ],
      learningMode: [
        {
          learningModeID: 1,
          name: "Classroom & Bootcamps",
          image: "image/learningMode/img-1.png",
          description:
            "Experiential learning with in-person mentorship!\r\n\r\n",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        },
        {
          learningModeID: 2,
          name: "Classroom with ILT Blended",
          image: "image/learningMode/img-2.png",
          description:
            "Learn from the best with unrestricted support, wherever you are!\r\n\r\n",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        },
        {
          learningModeID: 3,
          name: "Blended eLearning",
          image: "image/learningMode/img-3.png",
          description: "Learn at your pace with on-demand lectures & Support!",
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-07-27T13:46:18.000Z",
          updatedAt: "2022-07-29T11:23:24.000Z"
        }
      ],
      amountINR: 25000,
      discountType: 1,
      discountAmount: 0,
      upcomingBatch: {}
    }
  ],
  UserReviewsRecord: [],
  courseCurriculumRecord: {
    curriculumDescription: `<p class="counrse-contn-1">Learn artificial intelligence for management to automate daily mundane tasks. AI for management is like having an intelligent staff who can anticipate what you need to know, what business problems may arise, how to handle those challenges, and how exactly you can enable your team to achieve common business goals.
    </p>
    
    <p class="counrse-contn-1">
      Putting this idea into practice, the Artificial Intelligence for Managers and Leaders course at AnalytixLabs has five distinct modules: 
    </p>
    
    `,
    CourseCurriculums: [
      {
        courseID: 73,
        courseCurriculumID: 1228,
        name: "Introduction to AI Revolution",
        sortOrder: 0,
        CourseCurriculumDetails: [
          {
            courseCurriculumDetailsID: 5518,
            courseCurriculumID: 1228,
            title: "What is Artificial Intelligence (AI)?",
            description: `
              <ul class="cours-ovr-ul ct-cours-ovr-ul">
               <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left  mb-2">
                    Introduction to Course Objective - Logistics - Structure of the course
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Demystifying AI and its evolution
                  </p>
                </li>
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Data Science vs. Machine Learning vs. Artificial Intelligence
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    How AI is transforming businesses
                  </p>
                </li>
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Trends, Tools and Applications
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Use cases related to different functions across industries (Retail/ e-commerce, BFSI, Pharma, Manufacturing, Auto, etc.)
                  </p>
                </li>
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Key Areas of AI (Computer Vision, Language Models, Reinforcement Learning, etc.)
                  </p>
                </li>
              </ul>
              `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T05:55:57.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          },
          {
            courseCurriculumDetailsID: 5518,
            courseCurriculumID: 1228,
            title: "What is Generative AI?",
            description: `
                  <ul class="cours-ovr-ul ct-cours-ovr-ul">
                    <li class="drc-blcr m-0" style="list-style-type: none;">
                      <p class="counrse-contn-1 ct-sm-left mb-2">
                        Understand how Generative AI produces new data, text, or images based on training datasets.
                      </p>
                    </li>
                  </ul>
              `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T05:55:57.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          }
        ]
      },
      {
        courseID: 73,
        courseCurriculumID: 1240,
        name: "Generative AI for Everyone I",
        sortOrder: 2,
        CourseCurriculumDetails: [
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Introduction to AI & Generative AI",
            description: `
                <ul class="cours-ovr-ul ct-cours-ovr-ul">
                  <li class="drc-blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Introduction to LLMs and evolution
                    </p>
                  </li>
                  <li class="blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Basics of LLMs and Key Terminologies
                    </p>
                  </li>
                  <li class="drc-blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Working of LLMs
                    </p>
                  </li>
                  <li class="blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Applications of LLMs
                    </p>
                  </li>
                  <li class="drc-blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Introduction to Prompt Engineering
                    </p>
                  </li>
                </ul>
            `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          },
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Introduction to ChatGPT interface",
            description: `
              <ul class="cours-ovr-ul ct-cours-ovr-ul">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Creating an account
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Overview of the website and the services
                  </p>
                </li>
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Versions - 3.5 v/s 4 v/s turbo
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Tokens
                  </p>
                </li>
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    OpenAI playground
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Pricing etc.
                  </p>
                </li>
              </ul>

            `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          },
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Introduction to Prompting Techniques",
            description: `
                <ul class="cours-ovr-ul ct-cours-ovr-ul">
                  <li class="drc-blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Specifying output formats (table, bullets, comparison, code format, mark down format)
                    </p>
                  </li>
                  <li class="blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Assigning a role/character (setting comprehension level, tone)
                    </p>
                  </li>
                  <li class="drc-blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Reverse prompting
                    </p>
                  </li>
                  <li class="blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Chain of thought
                    </p>
                  </li>
                  <li class="drc-blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Ask before you answer/ Tell me what I don't know
                    </p>
                  </li>
                </ul>

            `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          },
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "ChatGPT Use Cases",
            description: `
                <ul class="cours-ovr-ul ct-cours-ovr-ul">
                  <li class="drc-blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Learning and Development
                    </p>
                  </li>
                  <li class="blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Making presentations using Google Slides
                    </p>
                  </li>
                  <li class="drc-blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Making Presentations using PowerPoint, Word, and ChatGPT
                    </p>
                  </li>
                  <li class="blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Making Presentations using online tools like tome.app, gamma.app
                    </p>
                  </li>
                  <li class="drc-blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Creating Webpages (generating HTML code using ChatGPT)
                    </p>
                  </li>
                  <li class="blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Usage of ChatGPT in Google Sheets
                    </p>
                  </li>
                  <li class="drc-blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Automating tasks in Google Sheets
                    </p>
                  </li>
                  <li class="blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Integrating ChatGPT in MS Word and Google Docs
                    </p>
                  </li>
                  <li class="drc-blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                      Using ChatGPT for writing better code
                    </p>
                  </li>
                </ul>
            `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          }
        ]
      },
      {
        courseID: 73,
        courseCurriculumID: 1240,
        name: "GenAI for Business Applications",
        sortOrder: 2,
        CourseCurriculumDetails: [
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title:
              "Module 1: Unveiling AI & Generative AI – Trends, Tools and Applications",
            description: `

            <p class='mt-4 mb-1 ct-p-text-gen-ai'><b>What is Generative AI?</b> </p>
            <p class="counrse-contn-1 ct-sm-left">Understand how <b>Generative AI</b> produces new data, text, or images based on training datasets.</p>
            
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Applications of Generative AI in Business:</b> </h6>

              <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Healthcare:</b> Personalized medicine, AI-based drug discovery (Examples: Disease diagnosis, drug discovery, and personalized medicine.)
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Banking & Finance:</b> Automating financial reports and customer service with chatbots. (Examples: Fraud detection, risk assessment, and algorithmic trading.)
                  </p>
                </li>
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Retail & Ecommerce:</b> Inventory management, dynamic pricing, and product recommendations (Examples: Visual search, AI-assisted shopping, and demand forecasting.)
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Manufacturing and Industry 4.0:</b> Predictive maintenance, defect detection, and process automation (Examples: Quality control systems, robotics, and smart factories.)
                  </p>
                </li>
              </ul>

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Generative AI Tools for Productivity:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  <b>Jasper AI:</b> Content creation
                </p>
              </li>
              <li class="blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  <b>Synthesia AI:</b> Video generation
                </p>
              </li>
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  <b>Grammarly:</b> Text enhancement
                </p>
              </li>
            </ul>

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Real-World Case Studies:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  Moderna’s use of Generative AI for COVID-19 vaccine research.
                </p>
              </li>
              <li class="blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  The New York Times automates article creation with AI.
                </p>
              </li>
            </ul>

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Hands-On Exercise:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  Use <b>Jasper AI</b> to generate a blog post or marketing content.
                </p>
              </li>
              <li class="blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  Create a corporate video presentation with <b>Synthesia AI</b>.
                </p>
              </li>
            </ul>

            `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          },
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Module 2: Machine Learning for Business Problem Solving",
            description: `

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>What is Machine Learning (ML)?</b> </h6>
            <p class="counrse-contn-1 ct-sm-left">Understand ML, its approaches (supervised, unsupervised, reinforcement), and how it differs from traditional AI.</p>
            
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Applications of ML in Business:</b> </h6>

              <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2 ">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Retail:</b> Personalized customer recommendations based on purchase history.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Manufacturing:</b> Predictive maintenance to reduce machine downtime.
                  </p>
                </li>
              </ul>

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>ML Tools for Business:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  <b>DataRobot:</b> No-code ML tool for creating ML models.
                </p>
              </li>
              <li class="blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  <b>Teachable Machines by Google:</b> Simple ML model training.
                </p>
              </li>
            </ul>
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Real-World Case Studies:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  Target uses ML to predict consumer behavior and optimize product recommendations.
                </p>
              </li>
              <li class="blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  Siemens uses ML to monitor factory machinery and prevent failures.
                </p>
              </li>
            </ul>
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Hands-On Exercise:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  Use <b>DataRobot</b> to create an ML model for customer segmentation.
                </p>
              </li>
              <li class="blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  Use <b>Teachable Machines</b> to build a basic image recognition model.
                </p>
              </li>
            </ul>
            `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          },
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Module 3: Ethical and Responsible AI",
            description: `

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>What is Ethical AI?</b> </h6>
            <p class="counrse-contn-1 ct-sm-left">Explore the challenges of <b>AI ethics</b>, including bias, fairness, and transparency in AI systems.</p>
            
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Applications of Responsible AI:</b> </h6>
              <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0 m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>HR:</b> Implementing AI-based hiring tools while avoiding bias.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Healthcare:</b> Ensuring fairness in AI-driven medical diagnostics.
                  </p>
                </li>
              </ul>

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Ethical AI Tools:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  <b>AI Fairness 360:</b> IBM’s toolkit to detect and mitigate bias in AI models.
                </p>
              </li>
              <li class="blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  <b>Watson OpenScale:</b> Ensuring transparency in AI decision-making.
                </p>
              </li>
            </ul>

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Real-World Case Studies:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  Amazon’s AI hiring tool and its bias against female candidates.
                </p>
              </li>
              <li class="blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                   IBM Watson’s struggle with cancer diagnosis due to biased data sets.
                </p>
              </li>
            </ul>

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Hands-On Exercise:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  Use <b>AI Fairness 360</b> to assess an AI model for bias.
                </p>
              </li>
              <li class="blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  Explore <b>Watson OpenScale</b> to understand how to track AI decision transparency.
                </p>
              </li>
            </ul>
            `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          },
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title:
              "Module 4: Mastering Prompt Engineering for Business Applications",
            description: `

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>What is Prompt Engineering?</b> </h6>
            <p class="counrse-contn-1 ct-sm-left">Learn how to structure prompts to maximize the quality and relevance of AI responses.</p>
            
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Applications of Prompt Engineering:</b> </h6>

              <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Legal Services:</b> Automating document review and summarization.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Finance:</b> Using AI-driven chatbots to provide financial advice.
                  </p>
                </li>
              </ul>

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Prompt Engineering Tools:</b> </h6>





            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  <b>OpenAI Playground:</b> Experiment with GPT models.
                </p>
              </li>
              <li class="blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  <b>Cohere AI:</b> NLP model that allows customized text generation.
                </p>
              </li>
            </ul>
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Real-World Case Studies:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  <b>Luminance AI:</b> Legal firms using AI to review thousands of documents quickly.
                </p>
              </li>
              <li class="blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>JPMorgan’s AI chatbot:</b> Providing instant, accurate financial advice.
                </p>
              </li>
            </ul>
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Hands-On Exercise:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  Design a set of prompts to automate customer service responses using <b>OpenAI Playground.</b>
                </p>
              </li>
              <li class="blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  Use <b>Cohere AI</b> to summarize legal documents for a mock case.
                </p>
              </li>
            </ul>
            `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          },
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title:
              "Module 5: Driving AI Success: Strategic Adoption & Team Empowerment",
            description: `

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>AI Project Lifecycle: Understand the stages of AI project management.</b> </h6>
              <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    AI Capability Maturity Model
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    How to design the optimal AI organization, and the roles and skills needed in AI teams.
                  </p>
                </li>
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    How to adapt agile methodologies for AI projects, the principles of responsible AI, and ways to govern AI projects to drive responsible and ethical outcomes for your business 
                  </p>
                </li>
              </ul>
            
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>How to Manage AI Projects?</b> </h6>
            <p class="counrse-contn-1 ct-sm-left">Learn the critical stages of AI projects: ideation, development, deployment, and scaling.</p>
            
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Applications of AI Project Management:</b> </h6>
              <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Retail:</b> Managing AI-driven inventory optimization projects.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Energy:</b> Leading AI projects for efficient energy exploration and management.
                  </p>
                </li>
              </ul>

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>AI Management Tools:</b> </h6>





            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  <b>Asana:</b> Project management tool with AI integrations.
                </p>
              </li>
              <li class="blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  <b>Trello:</b> Organize and track AI project deliverables.
                </p>
              </li>
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  <b>Scaling AI Initiatives:</b> Learn strategies for scaling and maintaining AI projects.
                </p>
              </li>
              <li class="blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  <b>Evaluating AI Impact:</b> Measure the ROI and impact of AI projects.
                </p>
              </li>
            </ul>
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Real-World Case Studies:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  Walmart’s AI team leading efforts in supply chain optimization.

                </p>
              </li>
              <li class="blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                    BP’s AI projects for improving energy exploration efficiency.
                </p>
              </li>
            </ul>
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Hands-On Exercise:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
              <li class="drc-blcr m-0" style="list-style-type: none;">
                <p class="counrse-contn-1 ct-sm-left mb-2">
                  Build an AI project roadmap using <b>Asana</b> or <b>Trello</b>, defining milestones and team roles.
                </p>
              </li>

              </ul>
            `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          },
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Module 6: Harnessing Cloud AI Solutions",
            description: `

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>What are Cloud AI Solutions?</b> </h6>
            <p class="counrse-contn-1 ct-sm-left">Explore how <b>cloud platforms</b> like AWS, Azure, and Google Cloud provide the infrastructure to build and scale AI solutions.</p>
            
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Applications of Cloud AI:</b> </h6>
            
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                        <b>Healthcare:</b> Cloud AI solutions for drug discovery and patient data analysis.
                    </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                        <b>Retail:</b> Personalized marketing and customer journey optimization.
                    </p>
                </li>
            </ul>

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Cloud AI Tools:</b> </h6>

            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                        <b>AWS SageMaker:</b> Train and deploy ML models in the cloud.
                    </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                        <b>Azure AI:</b> Ready-to-use AI services for NLP, image recognition, and more.
                    </p>
                </li>
            </ul>

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Real-World Case Studies:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                        Moderna’s use of AWS AI for accelerated vaccine development.
                    </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                        Coca-Cola using Azure AI for personalized marketing campaigns.
                    </p>
                </li>
            </ul>

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Hands-On Exercise:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                        Explore <b>AWS SageMaker</b> to deploy a machine learning model.
                    </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                    <p class="counrse-contn-1 ct-sm-left mb-2">
                        Use <b>Azure AI services</b> to build a simple chatbot for customer service.
                    </p>
                </li>
            </ul>
            `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          },
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title:
              "Module 7: Leveraging Open-Source AI for Enterprise Solutions",
            description: `

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>What are Open-Source AI Solutions?</b> </h6>
            <p class="counrse-contn-1 ct-sm-left">Understand the advantages of using <b>open-source AI </b>tools for flexibility and cost-effectiveness.</p>

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Applications of Open-Source AI:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Education:</b> Personalized learning and tutoring systems.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Automotive:</b> Enhancing autonomous vehicle capabilities.
                  </p>
                </li>
            </ul>
            
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Open-Source AI Tools:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Hugging Face:</b> Pre-trained NLP models.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>TensorFlow:</b> Open-source platform for ML and AI development.
                  </p>
                </li>
            </ul>

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Real-World Case Studies:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Tesla’s use of open-source AI for autonomous driving innovations.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    GPT-based adaptive learning platforms used by online education providers.
                  </p>
                </li>
            </ul>

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Hands-On Exercise:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Use <b>Hugging Face</b> to explore pre-trained NLP models.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Experiment with <b>TensorFlow</b> for developing basic AI models.
                  </p>
                </li>
            </ul>


            `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          },
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title:
              "Module 8: Building Chatbots and Retrieval-Augmented Generation (RAG)",
            description: `

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>What is RAG and Chatbots?</b> </h6>
            <p class="counrse-contn-1 ct-sm-left">Learn how <b>Retrieval-Augmented Generation (RAG)</b> enhances chatbots by fetching and integrating external knowledge.</p>
            
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Applications of Chatbots and RAG:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Banking:</b> Automated customer support for account inquiries.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>IT Operations:</b> Virtual agents handling service tickets and queries.
                  </p>
                </li>
            </ul>
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Chatbot Tools:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Dialogflow:</b> Build AI-powered chatbots with Google’s Dialogflow.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Rasa:</b> Open-source chatbot framework.
                  </p>
                </li>
            </ul>
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Real-World Case Studies:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Bank of America’s Erica chatbot for handling customer service requests.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    ServiceNow’s AI virtual agent for improving IT service management.
                  </p>
                </li>
            </ul>
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Hands-On Exercise:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Build a simple chatbot for industry-specific queries using <b>Dialogflow</b> or <b>Rasa.</b>
                  </p>
                </li>
            </ul>
            `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          },
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Module 9: AI in IT Operations (AIOps)",
            description: `

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>What is AIOps?</b> </h6>
            <p class="counrse-contn-1 ct-sm-left">Explore how AI is transforming IT operations through predictive analytics, root cause analysis, and automated monitoring.</p>
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Applications of AIOps:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Banking:</b> Monitoring IT infrastructure for preventing downtime.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>IT Services:</b> Automating incident resolution for faster service.
                  </p>
                </li>
            </ul>
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>AIOps Tools:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Moogsoft:</b> AI for IT operations, including incident prediction.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Dynatrace:</b> Full-stack monitoring with AI-driven root cause analysis.
                  </p>
                </li>
            </ul>
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Real-World Case Studies:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Splunk’s AI tools for automating IT operations in large enterprises.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    HSBC’s use of AIOps to ensure minimal system disruptions.
                  </p>
                </li>
            </ul>
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Hands-On Exercise:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Set up an IT monitoring system using <b>Dynatrace</b> to predict and resolve issues.
                  </p>
                </li>
            </ul>
            `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          },
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Module 10: AI in Business Operations",
            description: `

            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>AI for Optimizing Business Operations:</b> </h6>
            <p class="counrse-contn-1 ct-sm-left">Learn how AI is used to <b>automate decision-making, optimize processes,</b> and <b>drive efficiency</b> across different business functions.</p>
            
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Applications of AI in Business Operations:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Supply Chain:</b> Route optimization and demand forecasting.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>HR:</b> AI for talent acquisition and employee engagement.
                  </p>
                </li>
            </ul>
            
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Business Operations Tools:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>UiPath:</b> AI-driven process automation platform.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    <b>Workday:</b> HR management system using AI for recruitment and employee management.
                  </p>
                </li>
            </ul>
            
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Real-World Case Studies:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Amazon’s AI-powered logistics systems for optimizing supply chain operations.
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Unilever’s AI-powered hiring platform that screens and recruits candidates at scale.
                  </p>
                </li>
            </ul>
            
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Hands-On Exercise:</b> </h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Use <b>UiPath</b> to automate a routine business process (e.g., invoice processing).
                  </p>
                </li>
            </ul>
            `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          }
        ]
      },
      {
        courseID: 73,
        courseCurriculumID: 1240,
        name: "Capstone Project",
        sortOrder: 2,
        CourseCurriculumDetails: [
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Apply Everything You've Learned:",
            // <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Apply Everything You've Learned:</b></h6>
            description: `

            <p class="counrse-contn-1 ct-sm-left">Develop a <b>comprehensive AI strategy</b> for your organization, integrating AI applications, ethical considerations, cloud solutions, and project management principles.</p>
            
            <h6 class='mt-4 mb-1 ct-p-text-gen-ai'><b>Presentation:</b></h6>
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Present your AI strategy to the cohort and receive feedback on feasibility, scalability, and implementation.
                  </p>
                </li>
            </ul>

             `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          }
        ]
      },
      {
        courseID: 73,
        courseCurriculumID: 1240,
        name: "Generative AI for Everyone II",
        sortOrder: 2,
        CourseCurriculumDetails: [
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Introduction to Google Gemini",
            description: `
            
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Introduction and Usage
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Integration with Google workspace and Youtube
                  </p>
                </li>
            </ul>

             `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          },
          {
            courseCurriculumDetailsID: 5602,
            courseCurriculumID: 1240,
            title: "Introduction to Image Generation Using AI",
            description: `
            
            <ul class="cours-ovr-ul ct-cours-ovr-ul mt-2">
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Introduction, applications in different industries, and ethical responsibilities
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Introduction to Midjourney and Basics of image generation
                  </p>
                </li>
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Deep-dive into image-generation techniques
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Midjourney Working with images
                  </p>
                </li>
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Understanding Image aesthetics
                  </p>
                </li>
                <li class="blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    DALL-E: Generating e-commerce photos and promotional banners for products
                  </p>
                </li>
                <li class="drc-blcr m-0" style="list-style-type: none;">
                  <p class="counrse-contn-1 ct-sm-left mb-2">
                    Introduction to BING Images
                  </p>
                </li>
            </ul>
             `,
            sortOrder: 0,
            isActive: true,
            isDeleted: false,
            createdBy: null,
            updatedBy: null,
            createdAt: "2020-10-01T12:14:54.000Z",
            updatedAt: "2023-10-31T06:49:55.000Z"
          }
        ]
      }
    ]
  },
  whoShouldDoRecord: {
    whoShouldDo: `<p class="counrse-contn-1 ct-sm-left">This AI management course is designed for professionals and requires a minimum of years of experience in any domain or industry. It covers implementing AI to handle business risks, create solutions to complex business challenges, and drive AI and data science projects.</p>
    <p class="counrse-contn-1 ct-sm-left">
    If this interests you, then this course is for you. </p>
    <p class="counrse-contn-1 ct-sm-left">
    Explore the fascinating synergy between data science and AI with our executive AI for Data Science Program, powered in collaboration with E&ICT Academy, IIT Guwahati. </p>
    <p class="counrse-contn-1 ct-sm-left">
    We also have subject-specific certification courses that will teach you the technical aspects of data science and AI. Explore relevant courses - 
    </p>
      <ul class="cours-ovr-ul">
        <li class="drc-blcr" style='list-style:none; text-align:left;'>
          <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
            <strong>
              <a href="/artificial-intelligence-certification-courses-online" class="ct-lg-linkUpdate ct-font-link-16" target="_blank">
                Full-Stack Applied AI Course
              </a>
            </strong>
          </p>
        </li>
        <li class="blcr" style='list-style:none; text-align:left;'>
          <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
            <strong>
              <a href="/executive-certification-in-data-science-with-ai-specialization" class="ct-lg-linkUpdate ct-font-link-16" target="_blank">
                Executive Certification in Data Science with AI Specialization test
              </a>
            </strong>
          </p>
        </li>
        
      </ul>
      `,
    CourseWhoShouldDos: []
  },
  jobRolesAndSkills: {
    keyDescription:
      "This course empowers managers and leaders with technical expertise and strategic acumen. By mastering technical and soft skills, you will learn to navigate the AI landscape and drive business transformation.",
    jobRoles: [
      {
        jobRoleID: 21,
        JobRole: {
          jobRoleID: 21,
          name: "AI Engineer"
        }
      },
      {
        jobRoleID: 22,
        JobRole: {
          jobRoleID: 22,
          name: "Machine Learning Engineer"
        }
      },
      {
        jobRoleID: 23,
        JobRole: {
          jobRoleID: 23,
          name: "Robotics Specialist"
        }
      },
      {
        jobRoleID: 24,
        JobRole: {
          jobRoleID: 24,
          name: "Computer Vision Engineer"
        }
      },
      {
        jobRoleID: 25,
        JobRole: {
          jobRoleID: 25,
          name: "Data Scientist"
        }
      },
      {
        jobRoleID: 26,
        JobRole: {
          jobRoleID: 26,
          name: "AI-ML Specialist"
        }
      },
      {
        jobRoleID: 27,
        JobRole: {
          jobRoleID: 27,
          name: "Data Science Consultant"
        }
      },
      {
        jobRoleID: 28,
        JobRole: {
          jobRoleID: 28,
          name: "Analytics Consultant"
        }
      }
    ],
    keySkills: [
      {
        keySkillID: 115,
        KeySkill: {
          keySkillID: 115,
          name: "Large-Scale Analytics on Cloud",
          image: null
        }
      },
      {
        keySkillID: 116,
        KeySkill: {
          keySkillID: 116,
          name: "Predictive Modelling using Neural Network",
          image: null
        }
      },
      {
        keySkillID: 117,
        KeySkill: {
          keySkillID: 117,
          name: "Image Processing Using Deep Learning Techniques",
          image: null
        }
      },
      {
        keySkillID: 118,
        KeySkill: {
          keySkillID: 118,
          name: "Natural Language Processing & Generation (Chatbots)",
          image: null
        }
      },
      {
        keySkillID: 119,
        KeySkill: {
          keySkillID: 119,
          name: "Deep Learning Modelling for AI Applications",
          image: null
        }
      },
      {
        keySkillID: 120,
        KeySkill: {
          keySkillID: 120,
          name: "Data Science Methods",
          image: null
        }
      },
      {
        keySkillID: 121,
        KeySkill: {
          keySkillID: 121,
          name: "Supervised Learning Models",
          image: null
        }
      },
      {
        keySkillID: 122,
        KeySkill: {
          keySkillID: 122,
          name: "Unsupervised Learning Models",
          image: null
        }
      }
    ],
    keySkillsHTMLTable: `
    <div class='counrse-contn'>
        <p class="counrse-contn-1 ct-sm-left mt-2 mb-3">
          This curriculum positions you for leadership roles that demand a strategic, data-driven approach. You'll be well-prepared for positions like:
        </p>
        <ul class="cours-ovr-ul">
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Strategy Lead: 
                </span>
              </b>
              Develop and implement AI strategies across the organization.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Project Manager: 
                </span>
              </b>
              Oversee AI initiatives from conception to deployment.
            </p>
          </li>

          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Head of Data and Analytics: 
                </span>
              </b>
              Lead data-driven decision-making and AI initiatives.
            </p>
          </li>

          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Digital Transformation Officer: 
                </span>
              </b>
              Drive business transformation leveraging AI and technology.
            </p>
          </li>

          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Business Unit Head: 
                </span>
              </b>
              Lead business units with a focus on AI-driven innovation.
            </p>
          </li>

          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Chief AI Officer (CAIO): 
                </span>
              </b>
              Oversee an organization's AI strategy and implementation.
            </p>
          </li>

          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Ethics Officer: 
                </span>
              </b>
              Ensure ethical and responsible use of AI in the organization.
            </p>
          </li>

          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Product Manager: 
                </span>
              </b>
              Look into developing and launching AI-powered products and services.
            </p>
          </li>

        </ul>
        </div>

    `,
    keySkillsHTML: `
     <div class='counrse-contn'>
        <p class="counrse-contn-1 ct-sm-left mt-2 mb-3">
          This curriculum positions you for leadership roles that demand a strategic, data-driven approach. You'll be well-prepared for positions like:
        </p>
        <ul class="cours-ovr-ul ct-cours-ovr-ul">
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Strategy Lead: 
                </span>
              </b>
              Develop and implement AI strategies across the organization.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Project Manager: 
                </span>
              </b>
              Oversee AI initiatives from conception to deployment.
            </p>
          </li>

          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Head of Data and Analytics: 
                </span>
              </b>
              Lead data-driven decision-making and AI initiatives.
            </p>
          </li>

          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Digital Transformation Officer: 
                </span>
              </b>
              Drive business transformation leveraging AI and technology.
            </p>
          </li>

          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Business Unit Head: 
                </span>
              </b>
              Lead business units with a focus on AI-driven innovation.
            </p>
          </li>

          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Chief AI Officer (CAIO): 
                </span>
              </b>
              Oversee an organization's AI strategy and implementation.
            </p>
          </li>

          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Ethics Officer: 
                </span>
              </b>
              Ensure ethical and responsible use of AI in the organization.
            </p>
          </li>

          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Product Manager: 
                </span>
              </b>
              Look into developing and launching AI-powered products and services.
            </p>
          </li>

        </ul>
    </div>
    `,
    jobRolesAndSkillsHTML: `
     <div class='counrse-contn'>

      <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
        This course empowers managers and leaders with technical expertise and strategic acumen. By mastering technical and soft skills, you will learn to navigate the AI landscape and drive business transformation.
      </p>
      <h3 style='color: #013368 ;'>Technical Skills</h3>
        <ul class="cours-ovr-ul ct-cours-ovr-ul">
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Fundamentals: 
                </span>
              </b>
              Understanding of AI concepts, types, and applications.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Data Science: 
                </span>
              </b>
              Proficiency in data collection, processing, and analysis.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Tools: 
                </span>
              </b>
              Familiarity with AI software, platforms, and cloud services.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Model Development: 
                </span>
              </b>
              Knowledge of AI model frameworks and development processes.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  AI Engineering: 
                </span>
              </b>
              Understanding DevOps, MLOps, and DataOps for AI implementation.
            </p>
          </li>

        </ul>
      

      <h3 style='color: #013368 ;'>Soft Skills</h3>

      <ul class="cours-ovr-ul ct-cours-ovr-ul">
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Strategic Thinking: 
                </span>
              </b>
              Ability to develop AI strategies aligned with business goals.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Leadership: 
                </span>
              </b>
              Skills in building and managing AI teams and centers of excellence.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Problem Solving: 
                </span>
              </b>
              Capacity to identify and solve complex business problems using AI.
            </p>
          </li>
          <li class="blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Decision Making: 
                </span>
              </b>
              Ability to make data-driven decisions and evaluate AI initiatives.
            </p>
          </li>
          <li class="drc-blcr" style="list-style:none; text-align:left;">
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4">
              <b>
                <span style='color: #013368 ;'>
                  Communication: 
                </span>
              </b>
              Effective communication of AI concepts and value to stakeholders.
            </p>
          </li>
      </ul>
    </div>
    `,
    allScreenSizeSameKeySkill: true
  },
  deliveryFormatRecord: [
    {
      learningModeID: 1,
      LearningMode: {
        learningModeID: 1,
        name: "Classroom & Bootcamps",
        image: "image/learningMode/img-1.png",
        description: "Experiential learning with in-person mentorship!\r\n\r\n"
      }
    },
    {
      learningModeID: 2,
      LearningMode: {
        learningModeID: 2,
        name: "Classroom with ILT Blended",
        image: "image/learningMode/img-2.png",
        description:
          "Learn from the best with unrestricted support, wherever you are!\r\n\r\n"
      }
    },
    {
      learningModeID: 3,
      LearningMode: {
        learningModeID: 3,
        name: "Blended eLearning",
        image: "image/learningMode/img-3.png",
        description: "Learn at your pace with on-demand lectures & Support!"
      }
    }
  ],
  courseCertificationRecord: {
    CourseCertifications: [
      {
        courseID: 73,
        certificationID: 231,
        Certification: {
          certificationID: 231,
          name:
            "AnalytixLabs certifications are highly regarded in the industry due to our extensive domain expertise. As India’s leading Data Science institute, we must maintain the integrity of our certification process.",
          description: null,
          image: null,
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-09-26T18:26:07.000Z",
          updatedAt: "2023-10-14T08:40:30.000Z"
        }
      },
      {
        courseID: 74,
        certificationID: 232,
        Certification: {
          certificationID: 232,
          name:
            "Certification will be granted only upon meeting specified requirements within the course timelines, which include case studies, multiple-choice questions (MCQs), vivas, etc. Each candidate will have two attempts to pass each assessment.",
          description: null,
          image: null,
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-09-26T18:26:07.000Z",
          updatedAt: "2023-10-14T08:40:30.000Z"
        }
      },
      {
        courseID: 75,
        certificationID: 233,
        Certification: {
          certificationID: 233,
          name:
            "We aim to provide trainees with essential hands-on experience to prepare them for industry challenges.",
          description: null,
          image: null,
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-09-26T18:26:07.000Z",
          updatedAt: "2023-10-14T08:40:30.000Z"
        }
      },
      {
        courseID: 76,
        certificationID: 234,
        Certification: {
          certificationID: 234,
          name:
            "Certification must be secured within one year of course registration.",
          description: null,
          image: null,
          sortOrder: 0,
          isActive: true,
          isDeleted: false,
          createdBy: null,
          updatedBy: null,
          createdAt: "2020-09-26T18:26:07.000Z",
          updatedAt: "2023-10-14T08:40:30.000Z"
        }
      }
    ],
    CourseCertificationImages: [
      {
        courseID: 73,
        certificationImage:
          "image/certificateImage/AI_for_Managers_and_Leaders_240827.jpg"
      }
      // {
      //   courseID: 73,
      //   certificationImage:
      //     "image/certificateImage/Advanced_Certification_in_Full_Stack_Applied_AI_1.jpg"
      // }
    ]
  },
  feesRecord: [
    // {
    //   extraText: "(50% Pay after placement)",
    //   courseLearningModeID: 4199,
    //   courseID: 73,
    //   learningModeID: 1,
    //   offerName: null,
    //   offerDescription: null,
    //   offerImage: null,
    //   amountINR: 30000,
    //   amountUSD: 950,
    //   isInstallment: false,
    //   amountFirstInstallmentINR: null,
    //   amountSecondInstallmentINR: null,
    //   amountFirstInstallmentUSD: null,
    //   amountSecondInstallmentUSD: null,
    //   discountType: 1,
    //   isRecommended: false,
    //   discountAmount: 0,
    //   discountStartDate: null,
    //   discountEndDate: null,
    //   sortOrder: 0,
    //   isActive: true,
    //   isDeleted: false,
    //   createdBy: null,
    //   updatedBy: null,
    //   createdAt: "2023-10-31T06:49:55.000Z",
    //   updatedAt: "2023-10-31T06:49:55.000Z",
    //   LearningMode: {
    //     name: "Classroom & Bootcamps"
    //   }
    // },
    {
      extraText: "(50% Pay after placement)",
      courseLearningModeID: 4197,
      courseID: 73,
      learningModeID: 2,
      offerName: null,
      offerDescription: null,
      offerImage: null,
      amountINR: 28000,
      amountUSD: 950,
      isInstallment: false,
      amountFirstInstallmentINR: null,
      amountSecondInstallmentINR: null,
      amountFirstInstallmentUSD: null,
      amountSecondInstallmentUSD: null,
      discountType: 1,
      isRecommended: false,
      discountAmount: 0,
      discountStartDate: null,
      discountEndDate: null,
      sortOrder: 0,
      isActive: true,
      isDeleted: false,
      createdBy: null,
      updatedBy: null,
      createdAt: "2023-10-31T06:49:55.000Z",
      updatedAt: "2023-10-31T06:49:55.000Z",
      LearningMode: {
        name: "Classroom with ILT Blended"
      }
    }
    // {
    //   extraText: "(50% Pay after placement)",
    //   courseLearningModeID: 4198,
    //   courseID: 73,
    //   learningModeID: 3,
    //   offerName: null,
    //   offerDescription: null,
    //   offerImage: null,
    //   amountINR: 30000,
    //   amountUSD: 800,
    //   isInstallment: false,
    //   amountFirstInstallmentINR: null,
    //   amountSecondInstallmentINR: null,
    //   amountFirstInstallmentUSD: null,
    //   amountSecondInstallmentUSD: null,
    //   discountType: 1,
    //   isRecommended: false,
    //   discountAmount: 0,
    //   discountStartDate: null,
    //   discountEndDate: null,
    //   sortOrder: 0,
    //   isActive: true,
    //   isDeleted: false,
    //   createdBy: null,
    //   updatedBy: null,
    //   createdAt: "2023-10-31T06:49:55.000Z",
    //   updatedAt: "2023-10-31T06:49:55.000Z",
    //   LearningMode: {
    //     name: "Blended eLearning"
    //   }
    // }
  ],
  downloadBrochureResult: {
    courseID: 73,
    name: "Artificial Intelligence Engineering",
    brochureFile:
      "image/brochure/AnalytixLabs - AI Engineering Specialization-1626437470670.pdf",
    demoBrochureHTML: `
      <p class="counrse-contn-1 ct-sm-left" ><b>Uncertain about the best course?</b> Experience our teaching methodology firsthand with a free demo account. Gain exclusive access to discounted specialization tracks and job-oriented programs curated by industry experts.</p>
      `
  },
  courseFaqRecord: {
    faqRecord: [
      {
        FaqID: 1016,
        description: `<p>Yes, the course will cover technical aspects of AI, but to a certain extent.</p><p>
          While the primary focus is on the strategic and business applications of AI, the curriculum includes modules that touch upon:
          </p>
          <ul class='pg-lg-ul-r-20'>
            <li><p class="counrse-contn-1 ct-sm-left"><b>AI fundamentals:</b> Different types of AI, how AI works, and its capabilities.</p>
            </li>
            <li><p class="counrse-contn-1 ct-sm-left"><b>AI tools and platforms:</b> Exposure to various software tools used in AI development.</p></li>
            <li><p class="counrse-contn-1 ct-sm-left"><b>Data science:</b> Understanding the role of data in AI and basic data science techniques.</p></li>
            <li><p class="counrse-contn-1 ct-sm-left"><b>AI model frameworks:</b> Overview of popular AI model frameworks.</p></li>
          </ul>
          <p>However, the course is <b>not designed</b> to provide in-depth technical training in AI programming or algorithm development. It is more focused on equipping managers and leaders with the knowledge to understand, evaluate, and leverage AI for business advantage.</p>

          `,
        title: "Will the course cover the technical aspects of AI?",
        sortOrder: 0,
        Course: {
          faqDescription: "FAQs - Frequently Asked Questions"
        }
      },
      {
        FaqID: 1018,
        description: `<p>The course provides a strong foundation for becoming an AI manager. It focuses on AI's strategic and business aspects, which are crucial for this role. However, you'll likely need to complement your learning with practical experience and additional skills to transition into an AI management position.</p>
        <p>
        Here's a potential path:
        </p>
          <ul class='pg-lg-ul-r-20'>
            <li><p class="counrse-contn-1 ct-sm-left">Gain Practical Experience</p>
            </li>
            <li><p class="counrse-contn-1 ct-sm-left">Develop Leadership Skills</p></li>
            <li><p class="counrse-contn-1 ct-sm-left">Build a Strong Network</p></li>
            <li><p class="counrse-contn-1 ct-sm-left">Deepen Your Technical Understanding</p></li>
            <li><p class="counrse-contn-1 ct-sm-left">Showcase Your Expertise</p></li>
          </ul>
          <p>
          <b>Remember:</b> Becoming an AI manager is a journey that requires continuous learning and adaptation. The course provides a valuable starting point, but practical experience and leadership development are equally important.
          </p>
          `,
        title: "How do I become an AI Manager?",
        sortOrder: 1,
        Course: {
          faqDescription: "FAQs - Frequently Asked Questions"
        }
      },
      {
        FaqID: 1019,
        description: `<p>Here are some potential job roles you could explore after completing the AI for Managers and Leaders course:</p>
          <ul class='pg-lg-ul-r-20'>
            <li><p class="counrse-contn-1 ct-sm-left"><b>AI Strategy Consultant:</b> Leverage your understanding of AI to advise organizations on developing and implementing AI strategies.</p>
            </li>
            <li><p class="counrse-contn-1 ct-sm-left"><b>AI Project Manager:</b> Oversee AI projects from inception to completion, ensuring alignment with business objectives.</p></li>
            <li><p class="counrse-contn-1 ct-sm-left"><b>Digital Transformation Manager:</b> Drive digital transformation initiatives within an organization, leveraging AI as a key enabler.</p></li>
            <li><p class="counrse-contn-1 ct-sm-left"><b>Business Intelligence Analyst:</b> Utilize AI to analyze data and provide insights to inform business decisions.</p></li>
            <li><p class="counrse-contn-1 ct-sm-left"><b>Data Analytics Manager:</b> Lead data analytics teams and projects, incorporating AI technologies.</p></li>
            <li><p class="counrse-contn-1 ct-sm-left"><b>AI Ethics Officer:</b> Ensure responsible and ethical use of AI within an organization.</p></li>
          </ul>
          <p>Remember, these roles often require additional experience and certifications. However, the course provides a solid foundation for pursuing these career paths.</p>
          `,
        title: "What job roles can I pursue after completing this course?",
        sortOrder: 1,
        Course: {
          faqDescription: "FAQs - Frequently Asked Questions"
        }
      },
      {
        FaqID: 1020,
        description: `<p>Here are some courses you can consider taking after completing the AI for Managers and Leaders course:</p>
        <ul class='pg-lg-ul-r-20'>
            <li><p class="counrse-contn-1 ct-sm-left"><b>Business Analytics Course</b> (475 hours, 6 months): This course will provide a strong data analysis and visualization foundation, essential skills for any AI manager.</p></li>
            <li><p class="counrse-contn-1 ct-sm-left"><b>Data Visualization & Analytics</b> (120 hours, 12 classes): This shorter course can help you develop the skills to communicate AI initiatives' results to stakeholders effectively.</p></li>
        </ul>
        <p>
          Our other ongoing courses, such as the Data Science Course, Full Stack Applied AI Course, and Python Machine Learning Course, are more technical and may be more suitable for those who want to become AI engineers or data scientists.</p><p>
          If you want to focus on the business side of AI, then the Business Analytics Course or Data Visualization & Analytics course would be a good option. </p><p>
          Talk to our learning expert for assistance choosing the right course based on your interests, background, location, professional experience, and budget.

        </p>
        
        `,
        title: "What courses can I do after completing this course?",
        sortOrder: 1,
        Course: {
          faqDescription: "FAQs - Frequently Asked Questions"
        }
      },
      {
        FaqID: 1021,
        description: `<p><b>NO.</b> Coding is optional for the AI for Managers and Leaders course. The curriculum equips managers and leaders with a strategic understanding of AI rather than diving deep into technical implementation. </p><p>
        While the course touches on AI tools and platforms, the emphasis is on understanding how AI can solve business problems, drive innovation, and create value. </p><p>
        You will learn to think critically about AI's potential, develop AI strategies, and lead AI initiatives without writing code. The goal is to empower managers and leaders in making informed decisions about AI investments and effectively collaborate with technical teams.
          </p>
          `,
        title: "Do I need to know coding for this course?",
        sortOrder: 1,
        Course: {
          faqDescription: "FAQs - Frequently Asked Questions"
        }
      },
      {
        FaqID: 1022,
        description: `<p>Market studies show a need for more skilled AI managers capable of steering evolving AI initiatives. Our artificial intelligence for managers course is uniquely positioned to bridge this gap and is one of the best AI courses for managers and leaders.</p>
        <ul class='pg-lg-ul-r-20'>
            <li><p class="counrse-contn-1 ct-sm-left">The course emphasizes practical application. Focusing on problem-solving, case studies, and industry-specific examples ensures learners can directly apply course knowledge to real-world challenges. This is crucial for Indian businesses facing rapid changes.</p></li>
            <li><p class="counrse-contn-1 ct-sm-left">It focuses on AI strategy and leadership and is particularly relevant to the Indian context. With increasing competition, Indian organizations need leaders who can navigate the complexities of AI, build AI-driven cultures, and achieve sustainable competitive advantage. This course empowers managers to take on such roles.</p></li>
        </u>
        
        `,
        title: "Which AI course is best for managers?",
        sortOrder: 1,
        Course: {
          faqDescription: "FAQs - Frequently Asked Questions"
        }
      }
    ],
    courseID: 73,
    description: "FAQs - Frequently Asked Questions"
  },
  successStories: [
    {
      userReviewsId: 102,
      courseID: 73,
      reviewText:
        "I am ineffable to express my journey through AnalytixLabs, Gurgaon .  Since we all know the faculties are the backbone of any institute to make it reach to world class level and AnalytixLabs has very experienced faculties who not only teach you but also supervise you in your further academic career . The institute has best infrastructure in terms of study material , faculties , classrooms etc . Student will get chance to work on live projects . You will get excellent training in analytic field . The whole training period is just wonderful . To be precise , AnalytixLabs is one of the INDIA's best analytics  institute .",
      User: {
        userID: 107,
        email: "gmohit280@gmail.com",
        Student: {
          userID: 107,
          studentID: 87,
          name: "Mohit Jain",
          profileImage: "image/studentImage/Mohit_Jain.jpg",
          designation: "Data Scientist",
          organization: "J.P. Morgan Chase & Co.",
          organizationImage: null,
          linkedinUrl: "https://in.linkedin.com/pub/mohit-jain/3a/b83/973"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 92,
      courseID: 73,
      reviewText:
        "I went for a combo program @ AnalytixLabs - Business Analytics (SAS+BA) + Big Data Analytics (R+Hadoop). The program was superbly well structured and faculty had great command on their subject. While I had opted for classroom training, but the online facility came in as a boon and I attended all classes from home without feeling any difference. The assignments for each module ensured that we worked hands on and gained quality training. The practical examples and Q&amp;A ensured the classes were not just interactive but also lively. I had a great experience @ Analytixlabs and would recommend everyone who is looking for professional training in analytics.",
      User: {
        userID: 97,
        email: "deepika.goel@hotmail.com",
        Student: {
          userID: 97,
          studentID: 77,
          name: "Deepika Goel, VP - Genpact Bangalore",
          profileImage: "image/studentImage/Deepika_Goel.jpg",
          designation: "Director",
          organization: "Marsh & McLennan Companies",
          organizationImage: null,
          linkedinUrl: "https://www.linkedin.com/in/deepikagoel9811347436/"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 138,
      courseID: 73,
      reviewText:
        "The rapid pace at which the institutes are mushrooming all over India and Facebook newsfeed being inundated with thousands of options for analytics training, there are only handful of training institutes or rather say only couple of places where they ???prepare??? you to foray into rapidly growing analytics vertical. I have been working into legacy systems for past 5 years and was quite apprehensive if I will ever break into data science successfully. While I was working on my options of training institutes, a brief ???counseling??? by Mouli sir and Sumeet sir obviated the further process of evaluating my options. And I can safely say that settling in for AnalytixLabs was a safe and wise decision. The reason they have been bellwether among all training institutes is due to the fact that they have been emphasizing more on algorithms and underlying mathematical and statistical concepts which is bedrock for this track and not just trammeling their trainees with list of syntax of statistical tools. Fortunately with proper guidance and encouragement from faculty, I managed to switch over to analytics from traditional IT background. BIG thanks!",
      User: {
        userID: 143,
        email: "sumitasthana14@gmail.com ",
        Student: {
          userID: 143,
          studentID: 123,
          name: "Sumit Asthana",
          profileImage: "image/studentImage/2d50efc.jpg",
          designation: "Assistant Manager",
          organization: "Senior Data Scientist",
          organizationImage: null,
          linkedinUrl: "https://in.linkedin.com/in/thatsumit"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 165,
      courseID: 73,
      reviewText:
        "First of all Big Thanks to Analytix Labs. Its an Awesome institute to the one who wants to shine up his/her career in Data Science &amp; Analytics. As the name suggest's, its not just a learning institute but also a play ground to play with Case study based modules crafted by experts based on real life business scenarios. Though I discontinued from the Sessions due to personal reasons, The Team helped me like an AnalytiX Labs Alumni when I came back to Institute after two long years. The esteemed help from the Mentors varied from providing Mock Exams, Doubts Clarification sessions, Case Studies, Resume Building and Mock Interview Sessions. I am really grateful to the Analytix Labs Team for providing all the help in an need hour. The extended support and Mock exams helped me to achieve Base SAS Certification (BP064209V9) with 97%. I'll definitely highly recommend this institute to everyone without hesitation.",
      User: {
        userID: 170,
        email: "viswanadhsogana@gmail.com",
        Student: {
          userID: 170,
          studentID: 150,
          name: "Viswanadh Sogana",
          profileImage: "image/studentImage/Sogna.jpg",
          designation: "Accenture",
          organization: "Senior Analyst",
          organizationImage: null,
          linkedinUrl: "https://in.linkedin.com/in/viswanadhsogana"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 130,
      courseID: 73,
      reviewText:
        "AnalytixLabs stands out from the crowd of vast analytics courses mainly because of its practical and conceptual approach. The 'intent' and the 'content' was way too awesome, to say the least. Excellent course, brilliant co-founders with immense knowledge on the subject matter. Just The Best Learning Centre till date.",
      User: {
        userID: 135,
        email: "atul_kapur@outlook.com",
        Student: {
          userID: 135,
          studentID: 115,
          name: "Atul Kapur",
          profileImage: "image/studentImage/Atul_Kapur1.jpg",
          designation: "",
          organization: "RBS Chennai",
          organizationImage: null,
          linkedinUrl: "linkedin.com/in/atulkap"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 112,
      courseID: 73,
      reviewText:
        "I underwent 2 trainings at AnalytixLabs: SAS+Business Analytics and Big Data Analyst. After these 2 big courses, I am confident enough to never go wrong when I say that the quality of training here is truly tough to find anywhere else. When you are really concerned about taking the next big career step, you undoubtedly need - not just a trainer, not just a mentor and not just a career counsellor. You need it all, and that's what AnalytixLabs has given me. During these times, when institutes bring forward low-experienced trainers to make money out of the &quot;Analytics&quot; buzzword, the highly-experienced faculty members at AnalytixLabs provided such an individual attention that I felt like going nowhere else for any career advice or technical help at different points of time during my stint to switch job to analytics. And yes, finally I have succeeded to get a great one for myself! Thanks AnalytixLabs! You are a big hope for all such people like me who are looking for that one hand to take us along the right career path. Keep doing this great work and I am sure you will grow high with these endeavours!!",
      User: {
        userID: 117,
        email: "neha.analytics303@gmail.com",
        Student: {
          userID: 117,
          studentID: 97,
          name: "Neha Luthra",
          profileImage: "image/studentImage/Neha_Luthra.jpg",
          designation: "Assistant Manager",
          organization: "The Smartcube Pvt ltd",
          organizationImage: null,
          linkedinUrl: "https://in.linkedin.com/in/neha-luthra-4406a497"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 105,
      courseID: 73,
      reviewText:
        "AnalytixLab is one of the best institute we can prefer, especially for big data analytics perspective which i am familiar. ALabs has dedicated, and real time experienced faculty in the industry on various analytics domains, they are also available at any time if we need any clarification on career or any technical quires. One thing which i observed on my association with them was they would complete the full syllabus irrespective of the time constraint, other institutes are bounded to their predefined time, and try to rush up the syllabus to catch up the next batches",
      User: {
        userID: 110,
        email: "sumendar@gmail.com",
        Student: {
          userID: 110,
          studentID: 90,
          name: "Sumendar Karupakala",
          profileImage: "image/studentImage/Sumendar_Karupakala.jpg",
          designation: "Certified Big Data Analyst",
          organization: "TrueBees Technologies",
          organizationImage: null,
          linkedinUrl: "https://in.linkedin.com/in/sumendar"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 117,
      courseID: 73,
      reviewText:
        "I took three months Big Data training  from Analytix labs. Before joining this course, I had so many questions and doubts that will this course be worthy enough but later I found myself lucky to join this program. All trainers are amazing and always ready to help. Live examples and Case Studies are given which helps in understanding the concept and hands on practice.Not only they help in quality in depth knowledge transfer but also tend to give right direction to your career.",
      User: {
        userID: 122,
        email: "surbhi.sultania@gmail.com",
        Student: {
          userID: 122,
          studentID: 102,
          name: "Surbhi Sultania",
          profileImage: "image/studentImage/Surbhi_Sultania.jpg",
          designation:
            "Assistant Manager - Consumer Research and Insights Specialist, Primary Quantitative Research",
          organization: "The Smartcube Pvt ltd",
          organizationImage: null,
          linkedinUrl: "https://in.linkedin.com/in/surbhi-sultania-0486b24a"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 125,
      courseID: 73,
      reviewText:
        "I sincerely appreciate the learning I got at Analytixlabs. Faculties here are very experienced and their dedication towards teaching is commendable. Sumeet and Chandra, both are very cooperative and provide all the counseling and support a person could ask for. My internship at the Analytixlabs has given me professional experience also helped me in achieving my goals. I would recommend Analytixlabs to everyone as they are THE BEST!! I wish AnalytixLabs all the best and a BIG THANK YOU.",
      User: {
        userID: 130,
        email: "kirti14dayal@gmail.com",
        Student: {
          userID: 130,
          studentID: 110,
          name: "Kirti Dayal",
          profileImage: "image/studentImage/3a0462f.jpg",
          designation: "Sr. Analyst",
          organization: "AbsolutData Analytics",
          organizationImage: null,
          linkedinUrl: "https://in.linkedin.com/in/kirtidayal"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    },
    {
      userReviewsId: 120,
      courseID: 73,
      reviewText:
        "AnalytixLabs is the best place for all sorts of Analytics training be it R, Hadoop, SAS, Excel or others. They have a very meticulous approach towards the curriculum development, training, peer-faculty interaction, placements as well as career guidance. I myself have had an awesome experience with AnalytixLabs and would rate them the best in the field.",
      User: {
        userID: 125,
        email: "tarun.bhavnani@gmail.com",
        Student: {
          userID: 125,
          studentID: 105,
          name: "Tarun Bhavnani",
          profileImage: "image/studentImage/default.png",
          designation: "AVP, Data Science",
          organization: "SMECorner",
          organizationImage: null,
          linkedinUrl: "https://www.linkedin.com/in/tarunbhavnani/"
        }
      },
      Course: {
        courseID: 73,
        CourseTags: [
          {
            courseTagID: 354,
            Tag: {
              name: "DS360"
            }
          }
        ]
      }
    }
  ],
  careerSupport: [
    {
      courseCareerSupportID: 611,
      courseID: 73,
      careerSupportID: 615,
      sortOrder: 1,
      createdAt: "2020-09-28T09:27:47.000Z",
      updatedAt: "2020-10-01T10:34:24.000Z",
      CareerSupport: {
        careerSupportID: 615,
        title: "Profile Building",
        image: "image/course/Analytixlabs-icons-svg-36.svg",
        description:
          "Leveraging your educational background and experience, our seasoned professionals will provide personalized assistance in crafting your CV and optimizing your online profiles.",
        isActive: true,
        isDeleted: false,
        createdBy: null,
        updatedBy: null,
        createdAt: "2020-09-28T09:27:47.000Z",
        updatedAt: "2023-10-31T06:49:55.000Z"
      }
    },
    {
      courseCareerSupportID: 613,
      courseID: 73,
      careerSupportID: 617,
      sortOrder: 2,
      createdAt: "2020-09-28T09:27:47.000Z",
      updatedAt: "2020-10-01T10:34:24.000Z",
      CareerSupport: {
        careerSupportID: 617,
        title: "Interview Preparation",
        image: "image/course/Analytixlabs-icons-svg-34.svg",
        description:
          "If needed, we offer one-on-one interview preparation sessions and practice interviews to help you feel confident and prepared.",
        isActive: true,
        isDeleted: false,
        createdBy: null,
        updatedBy: null,
        createdAt: "2020-09-28T09:27:47.000Z",
        updatedAt: "2023-10-31T06:49:55.000Z"
      }
    },
    {
      courseCareerSupportID: 614,
      courseID: 73,
      careerSupportID: 618,
      sortOrder: 3,
      createdAt: "2020-09-28T09:27:47.000Z",
      updatedAt: "2020-10-01T10:34:24.000Z",
      CareerSupport: {
        careerSupportID: 618,
        title: "Job Referrals",
        image: "image/course/Analytixlabs-icons-svg-33.svg",
        description:
          "We regularly receive job openings from organizations, clients, HR consultants, and our extensive network of AnalytixLabs alumni across diverse industries, providing ample job referral opportunities.",
        isActive: true,
        isDeleted: false,
        createdBy: null,
        updatedBy: null,
        createdAt: "2020-09-28T09:27:47.000Z",
        updatedAt: "2023-10-31T06:49:55.000Z"
      }
    },
    {
      courseCareerSupportID: 612,
      courseID: 73,
      careerSupportID: 616,
      sortOrder: 4,
      createdAt: "2020-09-28T09:27:47.000Z",
      updatedAt: "2020-10-01T10:34:24.000Z",
      CareerSupport: {
        careerSupportID: 616,
        title: "Continuous Support",
        image: "image/course/Analytixlabs-icons-svg-35.svg",
        description:
          "After you complete training, we will continue offering ongoing support as long as you need it. Many of our students benefit from numerous interview calls and rewarding career prospects attributed to the skills acquired during the course.",
        isActive: true,
        isDeleted: false,
        createdBy: null,
        updatedBy: null,
        createdAt: "2020-09-28T09:27:47.000Z",
        updatedAt: "2023-10-31T06:49:55.000Z"
      }
    }
  ],
  courseDetails: {
    courseID: 73,
    courseFullName: "AI for Managers and Leaders Certification Course",
    name: "AI for Managers and Leaders Certification Course",
    slug: "artificial-intelligence-for-managers-leaders",
    shortDescription: "AI for Managers: Transform Decision-Making and Strategy",
    longDescription: `
      <p class="counrse-contn-1 ct-sm-left">
        AI is becoming a core skill for managerial roles. The top orders in any organization are looking to leverage the power of AI and accelerate work-pace. Managers are turning to AI to organize, streamline, and automate multiple program management tasks and leveraging AI copilots to strategies and accurate business decision-making. </p><p class="counrse-contn-1 ct-sm-left">

        AI is slated to experience a <a href="https://www.forbes.com/advisor/in/business/ai-statistics/" target="_blank">37% annual growth between 2023 and 2030</a>. Are you ready to seize this opportunity? Our Artificial Intelligence for Managers and Leaders certification course is designed to empower you with the practical skills to navigate this transformative era. Gain the competitive edge and position yourself at the forefront of innovation.  </p><p class="counrse-contn-1 ct-sm-left">

        The Artificial Intelligence for Managers and Leaders certification course will equip you with practical AI skills. It's high time we recognized the increasing relevance of artificial intelligence for managers and leaders in today's business landscape. This course will ensure you are well-informed and equipped to lead a team that is ready for the future. </p><p class="counrse-contn-1 ct-sm-left">

        Our AI for Leaders and Managers course is a comprehensive program built on core AI management concepts and their diverse applications across multiple industries and domains. You will learn to put AI in the center to identify and implement business opportunities with maximum optimization. </p><p class="counrse-contn-1 ct-sm-left">

        From building agile strategies to orchestrating AI business models and improved decision-making, this course is a have-it-all for existing and aspiring leaders and managers. Learn to lead and communicate with AI and deliver results that become milestones.</p>



        <h3 class="date-ttl mb-2 ct-capitalize mt-4 ">About Artificial Intelligence for Managers and Leaders</h3>
        <p class="counrse-contn-1 ct-sm-left">Artificial Intelligence is taking center stage for managers and leaders, and how. With most professionals opting for remote or hybrid work modes, AI has become an aid for leaders and managers to optimize and streamline work that defies geographical barriers. </p><p class="counrse-contn-1 ct-sm-left">

        As a manager or leader, your job will need you to step up for your team, know what they are doing, enable them to perform better, handle roadblocks head-on, and be the face of your team for stakeholders and profit shareholders. </p><p class="counrse-contn-1 ct-sm-left">

        As a result, you must have managerial skills that are agile, smart, and relevant. Our artificial intelligence for managers and leaders course accommodates all these requirements. Leverage AI to be the leader everyone wants to work with.
        </p>



        <h3 class="date-ttl mb-2 ct-capitalize mt-4 ct-sm-left">Course Objectives: </h3>
        <ul class="cours-ovr-ul">
          <li class="drc-blcr" style='list-style:none; text-align:left;'>
          <p class="counrse-contn-1 ct-sm-left ct-font-style-normal"> <b
          >Master Global AI Trends and Applications:</b>  Understand the evolution of AI and Generative AI, exploring their trends, industry applications, and emerging opportunities to drive business innovation. </p>
          </li>
          <li class="blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal"><b>Harness AI for Strategic Decision-Making:</b> Equip participants with the skills to leverage AI tools and frameworks for data-driven decision-making, transforming strategies and influencing enterprise-level outcomes.  </p>
          </li>
          <li class="drc-blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal"><b>Develop AI-Driven Solutions for Business Challenges:</b> Learn structured approaches to solve complex business problems using AI and machine learning, with a focus on ethical practices and industry use cases.  </p>
          </li>
          <li class="blcr" style='list-style:none; text-align:left;'>
          <p class="counrse-contn-1 ct-sm-left ct-font-style-normal"><b>Adopt Best Practices in AI Implementation and Tools:</b> Gain hands-on experience with leading AI tools, Generative AI techniques, and cloud solutions to optimize productivity, automate operations, and enhance innovation.  </p>
          </li>
          <li class="drc-blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal"><b>Design and Execute Comprehensive AI Strategies:</b> Apply AI concepts and methodologies to create actionable strategies, conduct AI maturity assessments, and deliver scalable, impactful solutions for organizational growth.</p>
          </li>
        </ul>


        <h3 class="date-ttl mb-2 ct-capitalize mt-4 ct-sm-left">Why Learn with AnalytixLabs: </h3>
        <p class="counrse-contn-1 ct-sm-left">The Indian Government's initiatives, such as Digital India and Make in India, emphasize technology adoption, and AI is a key component. This AI management course will prepare you to lead this digital journey.</p><p class="counrse-contn-1 ct-sm-left">
        Our dedicated AI for Managers course caters to India's large and diverse business landscape. From IT giants to traditional industries, the course provides a holistic view of AI applications, enabling managers to identify opportunities in their specific domains.</p>
      `,
    hoursCount: "500",
    classCount: "46",
    classHours: "+ 10 hrs e-learning",
    projectCount: "44",
    ratingValue: 4.73,
    bestRating: 5,
    worstRating: 2,
    ratingCount: 2680,
    metaTitle:
      "AI for Managers & Leaders | Drive Growth with AI - AnalytixLabs",
    metaDescription:
      "Launch your AI & ML career: Master in-demand skills with online and offline courses & certifications in India. Compare fees & get future-proof!",
    metaKeywords: "",
    selfStudyHoursCount: "(2-4 hrs/ week)",
    classCountSubline: "",
    hoursPerClassCount: "14 x 3 = 42 hrs",
    readinessProgramClassCount: "14",
    readinessProgramSubline: "",
    theme: "Default",
    isScholarship: 1,
    CourseLearningModes: {
      amountINR: 48000,
      maxAmountINR: 55000
    },
    taxPercentage: "18",
    LMSCourse: "ckeq05bmi00bgtqjl1i4l0h4e"
  },
  courseCapstoneRecord: {
    courseID: 73,
    capstoneProjects: null,
    capstoneProjectsDescription:
      "Our artificial intelligence courses in India include multiple case studies and assignments for self-study and hands-on skills, in addition to multiple case studies.",
    CourseCapstoneProjects: [
      {
        capstoneProjectID: 3070,
        CapstoneProject: {
          capstoneProjectname:
            "Image classification for inventory management (AI DL)"
        }
      },
      {
        capstoneProjectID: 3071,
        CapstoneProject: {
          capstoneProjectname:
            "Machine Language Translation (French to English & Hindi to English)"
        }
      },
      {
        capstoneProjectID: 3072,
        CapstoneProject: {
          capstoneProjectname: "Stock price forecasting (Deep Learning)"
        }
      },
      {
        capstoneProjectID: 3073,
        CapstoneProject: {
          capstoneProjectname: "Image classification project (AI DL)"
        }
      },
      {
        capstoneProjectID: 3074,
        CapstoneProject: {
          capstoneProjectname: "Credit Card fraud detection (Deep Learning)"
        }
      },
      {
        capstoneProjectID: 3075,
        CapstoneProject: {
          capstoneProjectname: "Automated image captioning (AI DL)"
        }
      },
      {
        capstoneProjectID: 3076,
        CapstoneProject: {
          capstoneProjectname: "Age detection using images (AI DL)"
        }
      },
      {
        capstoneProjectID: 3077,
        CapstoneProject: {
          capstoneProjectname: "Toxic comments classification (Deep Learning)"
        }
      },
      {
        capstoneProjectID: 3078,
        CapstoneProject: {
          capstoneProjectname:
            "Image classification & localization (Using YOLO)"
        }
      },
      {
        capstoneProjectID: 3079,
        CapstoneProject: {
          capstoneProjectname:
            "Network intrusion detection (Supervised machine learning)"
        }
      },
      {
        capstoneProjectID: 3080,
        CapstoneProject: {
          capstoneProjectname: "Online job posting analysis (Text mining & NLP)"
        }
      },
      {
        capstoneProjectID: 3081,
        CapstoneProject: {
          capstoneProjectname:
            "Banking customer cluster (Unsupervised machine learning)"
        }
      },
      {
        capstoneProjectID: 3082,
        CapstoneProject: {
          capstoneProjectname:
            "Retail chain sales forecasting (multivariate time series)"
        }
      },
      {
        capstoneProjectID: 3083,
        CapstoneProject: {
          capstoneProjectname:
            "Predicting credit card spend (regression methods)"
        }
      },
      {
        capstoneProjectID: 3084,
        CapstoneProject: {
          capstoneProjectname: "E-commerce recommendation engine (MBA)"
        }
      },
      {
        capstoneProjectID: 3085,
        CapstoneProject: {
          capstoneProjectname: "Chatbot creation (Seq2seq Deep Learning)"
        }
      },
      {
        capstoneProjectID: 3086,
        CapstoneProject: {
          capstoneProjectname: "Bank reviews analysis (Text mining & NLP)"
        }
      }
    ],
    CourseAssignments: [
      {
        courseAssignmentID: 1893,
        Assignment: {
          name: "10 short exercises on NumPy & Pandas"
        }
      },
      {
        courseAssignmentID: 1894,
        Assignment: {
          name:
            "4 case studies on Pandas for data munging, descriptive & visual analysis"
        }
      },
      {
        courseAssignmentID: 1895,
        Assignment: {
          name: "3 Basic exercises (Excel, SQL & Tableau)"
        }
      },
      {
        courseAssignmentID: 1896,
        Assignment: {
          name: "1 Assignment on basic Python"
        }
      },
      {
        courseAssignmentID: 1897,
        Assignment: {
          name: "2 exercises for statistical analysis"
        }
      }
    ]
  },
  similarCourseRecords: [
    {
      courseID: 60,
      name: "Data Science using R",
      shortDescription:
        "Best R training for industry relevant Advanced Analytics and Machine Learning skills!",
      robotImage: "image/course/thumbnail-1616501648.svg",
      courseDetailsImage: "image/course/data-science-using-R-1657623295981.png",
      slug: "data-science-r-analytics-training"
    },
    {
      courseID: 63,
      name: "Data Science using Python",
      shortDescription:
        "Best Python Data Science Course with Industry-Certified Excellence!",
      robotImage: "image/course/thumbnail-1616501891.svg",
      courseDetailsImage:
        "image/course/iitg-web-icon-logo-1714134463298-1714199689299.png",
      slug: "data-science-using-python"
    },
    {
      courseID: 64,
      name: "Business Analytics 360",
      shortDescription:
        "Dynamic Business Analytics Course with Job-Focused Certification!",
      robotImage: "image/course/dva-banners-creatives-alabs-04 copy.svg",
      courseDetailsImage: "image/course/iitg-web-icon-logo-1714199891639.png",
      slug: "business-analytics-data-science-course"
    }
  ],
  paymentKeys: {
    payumoneyKey: "qsxM7i",
    payumoneySalt: "A5v0ZQIJ",
    accountType: "nonLLP"
  },
  callUs: {
    number: "9555219007",
    country_code: "+91",
    start_time: "10:00:00",
    end_time: "19:00:00"
  },
  landingCallUs: {
    number: "9555525908",
    country_code: "+91",
    start_time: "10:00:00",
    end_time: "19:00:00"
  },
  careerPath: {
    CareerPathHtml: `
      <p class="counrse-contn-1 ct-sm-left">Career paths are dynamic and vary based on individual interests, strengths, and opportunities. </p>
      <p class="counrse-contn-1 ct-sm-left"><b>Note:</b> These career paths are not mutually exclusive. Many professionals combine elements from different paths to create their unique career trajectories. </p>
      <h3 class="date-ttl mb-2 ct-capitalize mt-4 ct-sm-left">Career Considerations: </h3>
        <ul class="cours-ovr-ul">
          <li class="drc-blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                <span style='color: #013368 ;'>
                  Industry Specialization:
                </span>
              </b> Consider focusing on a specific industry (e.g., healthcare, finance, retail) to deepen your expertise.
            </p>
          </li>
          <li class="blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                <span style='color: #013368 ;'>
                  Entrepreneurship:
                </span>
              </b> Leverage your AI knowledge to start your AI-focused venture.
            </p>
          </li>
          <li class="drc-blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                  <span style='color: #013368 ;'>
                    Academic Pursuits:
                  </span>
              </b> Pursue advanced degrees or research opportunities in AI to contribute to the field. [Explore our Executive AI in Data Science Course or opt for our Post-Graduate Programs in Data Science and Analytics].
            </p>
          </li>
        </ul>



        <h3 class="date-ttl mb-2 ct-capitalize mt-4 ct-sm-left">Career Path for an AI Strategy Expert</h3>  
        <ul class="cours-ovr-ul">
          <li class="drc-blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                <span style='color: #013368 ;'>
                  AI Strategy Consultant: 
                </span>
              </b>
              Advise organizations on developing and implementing AI strategies.
            </p>
          </li>
          <li class="blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                <span style='color: #013368 ;'>
                  AI Strategy Manager: 
                </span>
              </b>
              Lead AI strategy development within an organization.
            </p>
          </li>
          <li class="drc-blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                <span style='color: #013368 ;'>
                  AI Program Manager: 
                </span>
              </b>
              Oversee the execution of AI initiatives, ensuring alignment with business objectives.
            </p>
          </li>
          <li class="blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                <span style='color: #013368 ;'>
                  AI Center of Excellence (CoE) Leader: 
                </span>
              </b>
              To foster innovation and knowledge sharing, build and lead an AI CoE.
            </p>
          </li>
        </ul>



        <h3 class="date-ttl mb-2 ct-capitalize mt-4 ct-sm-left">Career Path for an AI Specialist</h3>  
        <ul class="cours-ovr-ul">
          <li class="drc-blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                <span style='color: #013368 ;'>
                  Data Analyst/Scientist: 
                </span>
              </b>
              Begin by developing a strong foundation in data analysis and machine learning.
            </p>
          </li>
          <li class="blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                <span style='color: #013368 ;'>
                  AI Engineer: 
                </span>
              </b>
              Transition into building and deploying AI models, focusing on technical implementation.
            </p>
          </li>
          <li class="drc-blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                <span style='color: #013368 ;'>
                  AI Architect: 
                </span>
              </b>
              Design and develop complex AI systems, becoming a technical leader.
            </p>
          </li>
          <li class="blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                <span style='color: #013368 ;'>
                  Chief Technology Officer (CTO): 
                </span>
              </b>
              Lead the overall technology strategy, including AI initiatives.
            </p>
          </li>
        </ul>



        <h3 class="date-ttl mb-2 ct-capitalize mt-4 ct-sm-left">Career Path for a Business Leader</h3>  
        <ul class="cours-ovr-ul">
          <li class="drc-blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                <span style='color: #013368 ;'>
                  Manager/Director of a Business Unit: 
                </span>
              </b>
              Apply AI knowledge to optimize operations and drive growth within your department.
            </p>
          </li>
          <li class="blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                <span style='color: #013368 ;'>
                  Head of Digital Transformation: 
                </span>
              </b>
              Lead enterprise-wide digital transformation initiatives, leveraging AI as a key enabler.
            </p>
          </li>
          <li class="drc-blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                <span style='color: #013368 ;'>
                  Chief Digital Officer (CDO): 
                </span>
              </b>
              Oversee the digital strategy and operations, including AI initiatives.
            </p>
          </li>
          <li class="blcr" style='list-style:none; text-align:left;'>
            <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
              <b>
                <span style='color: #013368 ;'>
                  Chief Executive Officer (CEO): 
                </span>
              </b>
              Ultimately, the CEO will lead the entire organization, using AI to drive innovation and competitive advantage.
            </p>
          </li>
        </ul>

    `
  },
  learningBenefits: {
    learningBenefitsHTML: `
    <div>
    <ol class="ct-number-ol-date">
          <li class='mb-4'>
            <h3 style='font-size:20px; color: #013368; text-align: left;'>
              Empower Strategic Decision-Making with AI:
            </h3>
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4 ct-ft-weight-small">
              Gain the ability to understand and utilize AI concepts without coding, enabling active participation in AI-driven decisions to drive impactful business outcomes. 
            </p>
          </li>
          <li class='mb-4'>
            <h3 style='font-size:20px; color: #013368; text-align: left;'>
              Boost Operational Efficiency and Automation:
            </h3>
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4 ct-ft-weight-small">
              Master AI tools to streamline workflows, automate repetitive tasks, and enhance productivity while optimizing resources across functions.
            </p>
          </li>
          <li class='mb-4'>
            <h3 style='font-size:20px; color: #013368; text-align: left;'>
              Foster a Data-Driven Leadership Culture:
            </h3>
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4 ct-ft-weight-small">
               Learn how to leverage AI insights to promote evidence-based decision-making, identify patterns, and anticipate industry trends, creating a culture of informed leadership.
            </p>
          </li>
          <li class='mb-4'>
            <h3 style='font-size:20px; color: #013368; text-align: left;'>
              Adapt and Innovate with Business Agility: 
            </h3>
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4 ct-ft-weight-small">
              Explore how AI fosters innovation and adaptability by enabling managers to respond effectively to evolving market demands with data-backed strategies.
            </p>
          </li>
          <li class='mb-4'>
            <h3 style='font-size:20px; color: #013368; text-align: left;'>
              Hands-On, No-Code AI Experience for Managers:
            </h3>
            <p class="counrse-contn-1 ct-sm-left mt-2 mb-4 ct-ft-weight-small">
              Benefit from a no-code approach tailored for leaders, focusing on practical AI applications and project-based learning under expert guidance to deliver measurable results.
            </p>
          </li>
    </ol>
    `
  }
};

export const AIEngineeringCoursePlacementSupprot = [
  "The Advanced Certification in Full Stack Applied AI program features comprehensive Placement Assistance, where a dedicated team of experienced professionals customizes support based on your educational background and work experience, if any.",
  "Job opportunities are sourced from various channels, including direct organizations, recruiters, and our extensive AnalytixLabs Alumni network. We are committed to providing sustained assistance for as long as you need it.",
  "While many of our students receive multiple interview calls and improved career prospects, success ultimately depends on the earnest and sufficient efforts of the candidates and the institute's support.",
  "Achieving success in this program requires candidates' dedication to completing the certification and meeting all specified requirements within the designated timeframe, including participation in the Placement Readiness Program (PRP)."
];

export const AIForManagersLeadersCareerStory = `<p class="counrse-contn-1 ct-sm-left">Numerous success stories from our program showcase remarkable achievements by graduates in their respective fields. These individuals have effectively translated their learning into tangible accomplishments, from impactful projects to securing high-level positions in leading organizations.</p>
<p class="counrse-contn-1 ct-sm-left">
The underlying theme in these success stories is the practical application of knowledge acquired through experiential learning, a core focus of our course. Experiential learning prioritizes hands-on experiences, real-world projects, and practical applications.</p>
<p class="counrse-contn-1 ct-sm-left">
Experiential Learning fosters active engagement, critical thinking, and reflection and equips learners with practical skills and real-world insights beyond conventional classroom instruction. Active participation in activities, simulations, projects, or internships enables a deep comprehension of concepts, develops advanced problem-solving abilities, and establishes a solid, applicable knowledge base.</p>
<p class="counrse-contn-1 ct-sm-left">
This approach empowers learners to absorb and engage with information, fostering a profound understanding and setting the stage for meaningful career accomplishments.
</p>`;

export const feeDetailsALEnginneringCoursesData = `AI course fees are often perceived as high, but our artificial intelligence engineering course fees are reasonable and in line with industry norms, ensuring accessibility for all. With AnalytixLabs, you can learn and master the skill set, with the course fee starting from just 60,000 INR + taxes.`;

export const AIEngineeringCoursesUpcomingBatches = `Our artificial intelligence and machine learning courses have multiple slots to select your suitable date. Choose a date and timing of your choice, and get your learning started.
`;

export const feeDetailsALForManagersLeaders =
  "Contrary to popular belief, our Artificial Intelligence for Managers and Leaders course offers exceptional value. Fees are competitively priced and aligned with industry standards, making AI education accessible to all.";

export const feeDetailsALForManagersLeadersSecondLine =
  "Invest in your future with AnalytixLabs, where mastery begins at just INR 28,000 + taxes.";

export const EMITextALForManagersLeaders = "INR 28,000/- plus taxes*";

export const CareerSupportAIForManagesAndLeadersCourseHTML = `
  <p class="counrse-contn-1 ct-sm-left">
    AnalytixLabs is committed to helping you launch your career successfully. Our default career guidance program focuses on developing job-relevant skills. You'll receive expert guidance on resume building, interview preparation, and how to apply for relevant job roles.
  </p>
  <ul class="cours-ovr-ul">
    <li class="drc-blcr" style='list-style:none; text-align:left;'>
      <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
        <strong>Profile Optimization: </strong>Tailored guidance to update your professional profile, aligning it with industry expectations and showcasing your strengths.
      </p>
    </li>
    <li class="drc-blcr" style='list-style:none; text-align:left;'>
      <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
        <strong>Role Targeting: </strong>Personalized advice on identifying suitable job roles based on your background, skills, and career aspirations.
      </p>
    </li>
    <li class="drc-blcr" style='list-style:none; text-align:left;'>
      <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
        <strong>Skill Development: </strong>Building job-relevant skills that employers value through hands-on training.
      </p>
    </li>
    <li class="drc-blcr" style='list-style:none; text-align:left;'>
      <p class="counrse-contn-1 ct-sm-left ct-font-style-normal">
        <strong>Expert Assistance: </strong>Insights and tips for crafting impactful resumes, acing interviews, and applying for the right opportunities.
      </p>
    </li>
  </ul>
`;
