import React, { memo } from 'react'
import { Helmet } from 'react-helmet-async'
import { canonicalTag, windowsLocationOrigin } from '../shared/utills'
import { Logo_URL } from '../shared/settings'

const HtmlHead = memo((props) => {
  return (
    <Helmet>
      {/* <meta content={props.keywords || ''} name="keywords" /> */}
      {window?.location?.origin &&
        window?.location?.pathname &&
        window.location.pathname !== '/404' && (
          <link
            rel="canonical"
            href={`${windowsLocationOrigin(window.location.origin)}/${canonicalTag(
              window.location.pathname,
            )}`}
          />
        )}
      <meta content={props.description || ''} name="description" />
      <title>{props.title || 'AnalytixLabs'}</title>
      {props?.schema ? { ...props.schema } : null}
      {props?.schema2 ? { ...props.schema2 } : null}
      {props?.schema3 ? { ...props.schema3 } : null}
      {props?.schema4 ? { ...props.schema4 } : null}
      {props?.schema5 ? { ...props.schema5 } : null}
      {props?.schema6 ? { ...props.schema6 } : null}
      {props?.schema7 ? { ...props.schema7 } : null}
      {props.path === '/checkout' ? (
        <script
          src="https://checkout.razorpay.com/v1/checkout.js"
          async
        ></script>
      ) : null}
      {props.path === '/checkout' ? (
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
      ) : null}
      {props.path === '/checkout' ? (
        <script
          id="bolt"
          src="https://checkout-static.citruspay.com/bolt/run/bolt.min.js"
          bolt-
          color="#007bff"
          bolt-logo={Logo_URL}
          type="text/javascript"
        ></script>
      ) : null}
      {/*
            Live: "https://checkout-static.citruspay.com/bolt/run/bolt.min.js"
            Test: "https://sboxcheckout-static.citruspay.com/bolt/run/bolt.min.js"
            */}
    </Helmet>
  )
})

export default HtmlHead
